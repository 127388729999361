import { Component, OnInit } from "@angular/core";
import { PennService } from "../common/penn.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Constant } from "src/app/common/constants/constant";
import { HttpService } from "../common/services/http.service";
import { RestApi } from "../common/constants/RestAPI";
import { SessionVariable } from "../common/class/storageLabel";

@Component({
  selector: "app-commissioning",
  templateUrl: "./commissioning.component.html",
  styleUrls: ["./commissioning.component.scss"],
})
export class CommissioningComponent implements OnInit {
  Title: string;
  siteID: number;
  premiseID: number;
  siteName: string;
  status: string;
  commisionedDeviceList;
  signalStrength: number;
  TemperatureType: any;
  show: boolean;
  gatewayKey: string;
  sensorKey: string;
  sensorGatewayKey: string;
  replaceGatewayKey: boolean = false;
  replaceSensorKey: boolean = false;
  dialogType: string = "";
  cabinetName: string = "";
  deviceDetails: any;
  gatewayID: string;
  cabinetId: number;
  sensorTemplateID: string;
  replaceSensorType: string;
  userProfile;
  disabledeleteBtnFlag = false;

  sensorType = {
    Temperature: "Temperature",
    TemperatureWithHumidity: "Temperature & Humidity",
    DoorContact: "Door Contact",
    Water: "Water Detection",
    CO2: "CO2",
    Pipe: "Pipe Monitoring",
  };

  constructor(
    private pennService: PennService,
    private router: Router,
    private httpClient: HttpClient,
    private httpService: HttpService
  ) {}

  ngOnInit(): void {
    this.siteID = this.pennService.getStoredData("siteID");
    this.premiseID = this.pennService.getStoredData("premiseID");
    this.siteName = this.pennService.getStoredData("siteName");
    this.status = this.pennService.getStoredData("status");
    this.pennService.saveDataToStorage("replaceGatewayKey", null);
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    if (this.siteID) {
      this.getSitePreferenceID();
      this.getCommissionDeviceBySiteID();
    }
  }

  getColorClass(signalStrength: number): string {
    if (signalStrength === null) {
      return "gray_signal";
    } else if (signalStrength >= 25) {
      return "green_signal";
    } else if (signalStrength < 25 && signalStrength > 0) {
      return "orange_signal";
    } else {
      return "gray_signal";
    }
  }

  getSensorColor(signalStrength: number): string {
    if (signalStrength === null) {
      return "gray_signal";
    } else if (signalStrength > -65) {
      return "green_signal";
    } else if (signalStrength > -75 && signalStrength <= -65) {
      return "orange_signal";
    } else if (signalStrength <= -75) {
      return "red_signal";
    } else {
      return "gray_signal";
    }
  }

  getBatteryStatusClass(batteryStrength: number): string {
    if (batteryStrength === null) {
      return "battery_na";
    } else if (batteryStrength >= 70 && batteryStrength <= 100) {
      return "battery_full";
    } else if (batteryStrength >= 30 && batteryStrength <= 69) {
      return "battery_medium";
    } else if (batteryStrength >= 0 && batteryStrength <= 29) {
      return "battery_low";
    } else {
      return "battery_na";
    }
  }

  getSitePreferenceID() {
    this.httpService
      .get<any>(RestApi.site_preference + "/" + this.siteID)
      .subscribe((res: any) => {
        if (res.Data) {
          this.TemperatureType = res.Data.TemperatureType;
          this.pennService.saveDataToStorage(
            "temperatureType",
            this.TemperatureType
          );
        }
      });
  }

  openReplaceDialog(details, deviceType) {
    if (deviceType === "gateway") {
      this.dialogType = "gateway";
      this.deviceDetails = details;
    }
    if (deviceType === "sensor") {
      this.dialogType = "sensor";
      this.deviceDetails = details;
      this.cabinetName = details.CabinetName;
    }
  }

  convertTemperature(value: number): number {
    if (this.TemperatureType === Constant.TemperatureType.Fahrenheit) {
      return Math.round(((value * 9) / 5 + 32) * 10) / 10;
    } else if (this.TemperatureType === Constant.TemperatureType.Celsius) {
      return Math.round(value * 10) / 10;
    }
    return value;
  }

  roundHumidity(value: number): number {
    return Math.round(value * 10) / 10;
  }

  redirect(deviceType) {
    this.pennService.saveDataToStorage("siteID", this.siteID);
    this.pennService.saveDataToStorage("premiseID", this.premiseID);
    this.pennService.saveDataToStorage("siteName", this.siteName);
    this.pennService.saveDataToStorage("status", this.status);
    if (this.gatewayKey !== null) {
      this.pennService.saveDataToStorage("gatewayKey", this.gatewayKey);
      this.pennService.saveDataToStorage(
        "replaceGatewayKey",
        this.replaceGatewayKey
      );
    }

    if (this.sensorKey !== null) {
      this.pennService.saveDataToStorage("sensorKey", this.sensorKey);
      this.pennService.saveDataToStorage(
        "replaceSensorKey",
        this.replaceSensorKey
      );
      this.pennService.saveDataToStorage(
        "sensorGatewayKey",
        this.sensorGatewayKey
      );
      this.pennService.saveDataToStorage("gatewayID", this.gatewayID);
      this.pennService.saveDataToStorage("cabinetId", this.cabinetId);
      this.pennService.saveDataToStorage("cabinetName", this.cabinetName);
      this.pennService.saveDataToStorage(
        "sensorTemplateID",
        this.sensorTemplateID
      );
      this.pennService.saveDataToStorage(
        "replaceSensorType",
        this.replaceSensorType
      );
    }

    const routeInfo = {
      state: { devicetype: deviceType },
    };
    this.router.navigate(["/commissioning/addserialNo"], routeInfo);
  }

  getCommissionDeviceBySiteID(): void {
    this.httpService
      .get<any>(RestApi.GetSiteCommissionDevices + "/" + this.siteID)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.commisionedDeviceList = res.Data;
          this.show = res.IsSuccess;
        }
      });
  }

  getSensorType(selectedSensorType: string): string {
    if (selectedSensorType === this.sensorType.DoorContact) {
      return "door";
    } else if (selectedSensorType === this.sensorType.Water) {
      return "water";
    } else if (selectedSensorType === this.sensorType.Temperature) {
      return "temp";
    } else if (selectedSensorType === this.sensorType.TemperatureWithHumidity) {
      return "temperature_humidity";
    } else if (selectedSensorType === this.sensorType.CO2) {
      return "CO2";
    } else if (selectedSensorType === this.sensorType.Pipe) {
      return "Pipe";
    }
  }

  getGatewayDetails(gateway: any, deviceType): void {
    this.gatewayKey = gateway.GatewayKey;
    this.replaceGatewayKey = true;
    this.redirect(deviceType);
  }

  getSensorDetails(sensor: any, deviceType): void {
    this.sensorKey = sensor.SensorKey;
    this.sensorGatewayKey = sensor.GatewayKey;
    this.gatewayID = sensor.GatewayID;
    this.replaceSensorKey = true;
    this.cabinetId = sensor.CabinetID;
    this.cabinetName = sensor.CabinetName;
    this.sensorTemplateID = sensor.SensorTemplateID;
    this.replaceSensorType = sensor.SensorType;
    this.redirect(deviceType);
  }

  deleteButtonStatus(selectedGateway) {
    const sensordevicecount = this.commisionedDeviceList.CommissionSensors.filter(
      (item) => item.GatewayKey === selectedGateway.GatewayKey
    );

    if (selectedGateway.GatewayType === "DT") {
      const DTgatewaydevicecountexits = this.commisionedDeviceList.CommissionGateways.filter(
        (gateway) => {
          return (
            gateway.GatewayKey !== selectedGateway.GatewayKey &&
            gateway.GatewayType === selectedGateway.GatewayType
          );
        }
      );

      if (sensordevicecount.length > 0) {
        if (DTgatewaydevicecountexits.length > 0) {
          this.disabledeleteBtnFlag = false;
        } else {
          this.disabledeleteBtnFlag = true;
        }
      } else {
        this.disabledeleteBtnFlag = false;
      }
    } else {
      if (sensordevicecount.length > 0) {
        this.disabledeleteBtnFlag = true;
      } else {
        this.disabledeleteBtnFlag = false;
      }
    }
  }

  navigateToDeleteGatewayPage(gatewayInfo) {
    const body = {
      GatewayKey: gatewayInfo.GatewayKey,
      ClientID: gatewayInfo.ClientID,
      SiteID: gatewayInfo.SiteID,
      PremiseID: gatewayInfo.PremiseID,
      modifiedBy: this.userProfile.userName,
      modifiedByID: this.userProfile.userID,
    };
    this.deleteButtonStatus(gatewayInfo);
    const routedata = {
      state: {
        bodyParams: body,
        gatewayData: gatewayInfo,
        deleteFlag: this.disabledeleteBtnFlag,
      },
    };
    this.router.navigate(["/commissioning/deleteGateway"], routedata);
  }

  getSensor(sensor: any) {
    const sensorData = {
      sensorKey: sensor.SensorKey,
      cabinetName: sensor.CabinetName,
      batteryLevel: sensor.BatteryLevel,
      signalStrength: sensor.SignalStrength,
      temperature: sensor.Temperature,
      humidity: sensor.Humidity,
      pressure: sensor.Pressure,
      CO2: sensor.CO2,
      door: sensor.Door,
      waterDetection: sensor.WaterDetection,
      sensorType: sensor.SensorType,
      sensorTemplateName: sensor.SensorTemplateName,
    };
    const routedata = {
      state: {
        sensorData: sensorData,
      },
    };
    this.router.navigate(["/commissioning/deleteSensor"], routedata);
  }
}
