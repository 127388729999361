export class TemperatureUtil {
  static regex = /^-?\d*(\.\d{0,1})?$/;
  static validateNumber(event) {
    const inputValue = event.target.value;
    if (inputValue && inputValue.length >= 10) {
      event.preventDefault();
    }
  }

  static removeExtraDecimals(value) {
    const num = value.toString().split(".");
    if (num.length === 2 && num[1].length > 1) {
      return parseFloat(num[0] + "." + num[1].charAt(0));
    }
    return value;
  }
}
