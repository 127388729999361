import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

import { PennService } from "src/app/common/penn.service";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import $ from "jquery";
declare var $: $;

@Component({
  selector: "app-my-dashboard",
  templateUrl: "./my-dashboard.component.html",
  styleUrls: ["./my-dashboard.component.scss"],
})
export class MyDashboardComponent implements OnInit, OnChanges {
  @Input() mydashboardWidgetsConfig;
  @Input() sites;
  @Input() selectedmodules;
  @Input() modules;
  @Output() changedDashboardConfig = new EventEmitter();
  @Output() mydashboardOrderConfig = new EventEmitter();
  dashboard = [];
  userProfile;
  pageTitle = "mydashboard";
  @Input() islastDelete;
  currentSelectedWidget;
  fullwidthWidget = ["Compliance Score"];

  constructor(
    public pennService: PennService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["mydashboardWidgetsConfig"]) {
      this.addtoDashboard();
      this.saveOrderToLocalStorage();
    }
  }

  addtoDashboard() {
    this.dashboard = this.mydashboardWidgetsConfig.widgetInorderArr;
    //console.log("dashboard", this.dashboard);
  }

  saveOrderToLocalStorage() {}

  addToOriginalDashboardMenuTab(selectedwidget, callfromPopup?) {
    this.currentSelectedWidget = selectedwidget;
    const currentSelectedWidgetKey = Object.keys(selectedwidget)[0];
    let mydashboardConfig = Object.keys(this.mydashboardWidgetsConfig);
    if (this.getMyDashboardWidgetsCount() && !callfromPopup) {
      this.showMyDashboardWarning();
      return;
    }
    this.removewidgetFromMyDashboardPage(
      currentSelectedWidgetKey,
      mydashboardConfig,
      selectedwidget
    );
  }

  removewidgetFromMyDashboardPage(
    currentSelectedWidgetKey,
    mydashboardConfig,
    selectedwidget
  ) {
    if (mydashboardConfig.includes(currentSelectedWidgetKey)) {
      delete this.mydashboardWidgetsConfig[currentSelectedWidgetKey];
      let deleteIndex = this.dashboard.indexOf(currentSelectedWidgetKey);
      this.dashboard.splice(deleteIndex, 1);
    }
    this.changedDashboardConfig.emit(selectedwidget);
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.dashboard, event.previousIndex, event.currentIndex);
      this.updateWidgetSortingToLocalStorage(this.dashboard);
    }
  }

  updateWidgetSortingToLocalStorage(dashboardArray) {
    this.mydashboardWidgetsConfig.widgetInorderArr = dashboardArray;
    this.mydashboardOrderConfig.emit(
      this.mydashboardWidgetsConfig.widgetInorderArr
    );
  }

  getMyDashboardWidgetsCount() {
    return this.dashboard.length <= 1;
  }

  showMyDashboardWarning() {
    $("#mydashboardWidgetWarning").modal();
  }

  onNoConfirm() {}

  onConfirm() {
    this.addToOriginalDashboardMenuTab(this.currentSelectedWidget, true);
  }

  hideDashboardSection(item) {
    return this.fullwidthWidget.includes(item);
  }
}
