import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
} from "@angular/core";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { MessageService } from "primeng/api";

import { PennService } from "../common/penn.service";
import { ExcelService } from "../common/services/excel.service";
import { HttpService } from "../common/services/http.service";
import { TranslateService } from "../common/services/translate.service";
import { AddSite, SitePreference } from "../common/models/client.model";
import { RestApi } from "../common/constants/RestAPI";
import { ToastMsg } from "../common/constants/toastmsg.constant";

import { getStatusList } from "../common/data/status-filter";
import { Constant } from "../common/constants/constant";
import {
  ApiListResponse,
  ProcessedRowError,
  SiteNotificationCount,
} from "../common/models/configuration.model";
import { Roles } from "../common/constants/enums/instructionEnums";
import { SessionVariable } from "../common/class/storageLabel";
import { UserProfile } from "../common/models/user.model";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import moment from "moment";

@Component({
  selector: "app-site",
  templateUrl: "./site.component.html",
  styleUrls: ["./site.component.scss"],
})
export class SiteComponent implements OnInit {
  @ViewChild("addcloseBtn", { static: true }) addcloseBtn: ElementRef;
  @ViewChild("inputFileUpload", { static: false }) inputFileUpload: ElementRef;
  @ViewChild("statusChangecloseBtn", { static: true })
  statusChangecloseBtn: ElementRef;
  clientID: number;
  Title: string;
  siteList: AddSite[];
  totalsiteList: number;
  siteID: number;
  premiseID: number;
  siteName: string;
  p: number = 1;
  clientName: string;
  importErrors: ProcessedRowError[] = [];
  showImportErrors: boolean = false;
  searchText;
  userProfile: UserProfile;
  editAccess: boolean = true;
  deleteAccess: boolean = true;
  addSite: AddSite;
  sitePreference: SitePreference;
  createdDate: string;
  statusText: string;
  statusFlag: boolean;
  addSiteFlag;
  selectedStatus: any;
  statusList: any = [];
  cloneAccess: boolean = false;
  disableAccess: boolean = false;
  sitePrefAccess: boolean = false;
  commissioningAccess: boolean = false;
  UserTypeEnum = Roles;
  userType: number;

  constructor(
    public pennService: PennService,
    private messageService: MessageService,
    private translate: TranslateService,
    public httpService: HttpService,
    public excelService: ExcelService,
    private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _date: DatePipe
  ) {}

  ngOnInit() {
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.userType = this.userProfile.roleID;
    this.pennService.showSubNav();
    this.pennService.hideSiteSubNav();

    let routeURL = window.location.pathname.split("/")[1];
    this.storage.set("routename", routeURL);
    this.Title = this.translate.data.Site.site;

    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.addSite = <AddSite>{};
    this.sitePreference = <SitePreference>{};

    this.clientID = this.pennService.getStoredData("clientID");
    this.clientName = this.pennService.getStoredData("clientName");
    var pagenumber = this.pennService.getStoredData("SitepageNumber");

    if (pagenumber) {
      this.p = pagenumber;
      this.storage.remove("SitepageNumber");
    }

    this.siteList = [];
    if (this.clientID) {
      this.getSiteData();
    }

    this.addSiteFlag =
      this.userProfile.roleName == Constant.Roles.areaManager ||
      this.userProfile.roleName == Constant.Roles.siteManager ||
      this.userProfile.roleName == Constant.Roles.fieldEngineer
        ? false
        : true;
    this.editAccess =
      this.userProfile.roleName == Constant.Roles.siteManager ||
      this.userProfile.roleName == Constant.Roles.areaManager ||
      this.userProfile.roleName == Constant.Roles.fieldEngineer
        ? false
        : true;
    this.deleteAccess =
      this.userProfile.roleName == Constant.Roles.adminReseller ||
      this.userProfile.roleName == Constant.Roles.superAdmin;
    this.cloneAccess = false; // Ticket ZP-5527: Removed the site clone option for all users.
    this.disableAccess =
      this.userProfile.roleName == Constant.Roles.areaManager ||
      this.userProfile.roleName == Constant.Roles.fieldEngineer ||
      this.userProfile.roleName == Constant.Roles.siteManager
        ? false
        : true;
    this.commissioningAccess =
      this.userProfile.roleName == Constant.Roles.adminReseller ||
      this.userProfile.roleName == Constant.Roles.fieldEngineer ||
      this.userProfile.roleName == Constant.Roles.superAdmin;
    this.sitePrefAccess =
      this.userProfile.roleName == Constant.Roles.fieldEngineer ? false : true;

    if (this.userProfile.roleName == Constant.Roles.siteManager) {
      this.pennService.saveDataToStorage("siteID", this.userProfile.siteID);
    }

    this.statusList = getStatusList();
  }

  getSiteData() {
    this.httpService
      .get<any>(RestApi.client_site_list + "/" + this.clientID)
      .subscribe((res: ApiListResponse<AddSite>) => {
        let SiteIDs: number[] = [];
        if (res.IsSuccess) {
          let siteListing = res.Data || [];
          this.storage.set("allSitesByClientId", res);
          if (this.userProfile.roleName == Constant.Roles.siteManager) {
            this.siteList = siteListing.filter((ele) => {
              return ele.SiteID == this.userProfile.siteID;
            });
            this.totalsiteList = siteListing.length;
          } else {
            if (this.selectedStatus && this.selectedStatus.id != 0) {
              this.siteList = siteListing.filter((ele) => {
                return ele.Status == this.selectedStatus.name;
              });
              this.totalsiteList = this.siteList.length;
            } else {
              this.siteList = siteListing.sort(
                (first: AddSite, next: AddSite) =>
                  first.SiteName > next.SiteName ? 1 : -1
              );
              this.totalsiteList = siteListing.length;
            }
          }

          this.siteList.map((val: AddSite, index: number) => {
            if (
              val.GatewaysLastCommunicationDetails &&
              val.GatewaysLastCommunicationDetails[0].lastCommunicationTimestamp
            ) {
              val["lastcommunicationtimestamp"] = `${moment(
                val.GatewaysLastCommunicationDetails[0]
                  .lastCommunicationTimestamp
              ).format("YYYY-MM-DD HH:mm:ss")} (UTC)`;
            } else {
              val["lastcommunicationtimestamp"] = "NA";
            }

            SiteIDs.push(val.SiteID);
          });

          this.httpService
            .get(RestApi.GetNotificationCount + "/" + SiteIDs.join(","))
            .subscribe(
              (res: ApiListResponse<SiteNotificationCount>) => {
                if (res.IsSuccess && res.Data && res.Data != null) {
                  res.Data.forEach(
                    (val: SiteNotificationCount, index: number) => {
                      this.siteList.forEach(
                        (SiteData: AddSite, idx: number) => {
                          if (SiteData.SiteID == val.SiteID)
                            SiteData.NotificationCount = val.NotificationCount;
                        }
                      );
                    }
                  );
                }
              },
              (error: any) => {}
            );
        }
      });
  }

  filterStatus() {
    this.getSiteData();
  }

  async onImportFileChange(items: Blob[]) {
    const clientName = {
      ClientName: this.clientName,
    };
    return this.excelService
      .sendExcelFile(
        items[0],
        RestApi.import_site,
        this.translate.data.Popuop_Msg.sitesimportedsuccessfully,
        clientName
      )
      .then(() => {
        this.getSiteData();
      })
      .catch((result: ApiListResponse<ProcessedRowError> | Error) => {
        if (!(result instanceof Error)) {
          this.importErrors = result.Data;
          this.showImportErrors = true;
        }
      })
      .finally(() => {
        this.inputFileUpload.nativeElement.value = null;
      });
  }

  importExcel() {
    document.getElementById("inputFileUpload").click();
  }

  getSiteID(data) {
    this.siteID = data.SiteID;
    this.siteName = data.SiteName;
    this.premiseID = data.PremiseID;
  }

  deleteSite() {
    this.httpService
      .post(RestApi.delete_site, {
        SiteID: this.siteID,
        PremiseID: this.premiseID,
      })
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.sitedeletedsuccessfully,
          });
          this.addcloseBtn.nativeElement.click();
          this.getSiteData();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  redirectToSite(val, item) {
    let flag = val == "SitePref" ? "true" : "false";
    this.storage.set("sitePreferenceFlag", flag);

    let siteID = val == "Add" ? 0 : item.SiteID;
    this.router.navigate(["/addSite", siteID]);
    this.pennService.saveDataToStorage("SitepageNumber", this.p);

    if (val == "SitePref" || val == "Edit") {
      this.pennService.saveDataToStorage("siteName", item.SiteName);
      this.pennService.saveDataToStorage("siteID", siteID);
    } else {
      this.storage.remove("siteName");
    }
  }

  redirectToCommissioning(item) {
    this.router.navigate(["/commissioning/listsensorgateway"]);
    this.pennService.saveDataToStorage("siteID", item.SiteID);
    this.pennService.saveDataToStorage("premiseID", item.PremiseID);
    this.pennService.saveDataToStorage("siteName", item.SiteName);
    this.pennService.saveDataToStorage("status", item.Status);
  }

  redirectToSiteConfig(item) {
    this.pennService.saveDataToStorage("SitepageNumber", this.p);
    this.router.navigate(["/configuration/details"]);
    this.pennService.saveDataToStorage("siteID", item.SiteID);
    this.pennService.saveDataToStorage("premiseID", item.PremiseID);
    this.pennService.saveDataToStorage("siteName", item.SiteName);
  }

  cloneSite(data) {
    this.addSite = data;
    this.addSite.CreatedOn = this.createdDate;
    this.addSite.CreatedBy = this.userProfile.userName;
    this.addSite.CreatedByID = this.userProfile.userID;
    this.addSite.ModifiedOn = this.createdDate;
    this.addSite.ModifiedBy = this.userProfile.userName;
    this.addSite.ModifiedByID = this.userProfile.userID;

    this.httpService
      .post(RestApi.clone_site_data, this.addSite)
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.siteclonedsuccessfully,
          });
          this.getSiteData();
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  siteStatus(data) {
    this.siteID = data.SiteID;
    this.statusText = data.Status == "Active" ? "disable" : "enable";
    this.statusFlag = data.Status == "Active" ? false : true;
  }

  changeSiteStatus() {
    this.httpService
      .get(
        RestApi.update_site_status + "/" + this.siteID + "/" + this.statusFlag
      )
      .subscribe((res: any) => {
        if (res.IsSuccess) {
          this.statusChangecloseBtn.nativeElement.click();
          this.getSiteData();
        }
      });
  }

  ngOnDestroy() {
    this.pennService.displayNotification = true;
    this.pennService.hideSubNav();
  }

  setSiteValues(item) {
    this.pennService.saveDataToStorage("siteName", item.SiteName);
    this.pennService.saveDataToStorage("siteID", item.SiteID);
    this.pennService.saveDataToStorage("stateName", item.State);
    this.pennService.saveDataToStorage("SitepageNumber", this.p);
    this.pennService.saveDataToStorage("premiseID", item.PremiseID);
    this.pennService.saveDataToStorage("City", item.City);
    console.log("timestamp", item.lastcommunicationtimestamp);
    this.pennService.saveDataToStorage(
      "gatewaytime",
      item.lastcommunicationtimestamp
    );
  }
}
