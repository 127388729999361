<app-header [pageTitle]="Title"></app-header>
<div class="wrapper">
  <div class="col-sm-12 breadcrumbSection">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active">
          <a routerLink="/user">{{ "users" | translate: "User" }}</a>
        </li>
        <li class="breadcrumb-item" aria-current="page">{{ Title }}</li>
      </ol>
    </nav>
  </div>

  <div class="row config maincontainer">
    <div class="col-sm-12">
      <div class="page-title m-24" style="padding-left: 12px">
        {{ this.Title }}
      </div>
      <div class="content">
        <div class="content-body configTable">
          <form #userForm="ngForm">
            <div class="addUserForm">
              <div class="flex">
                <div class="addUserForm_header">
                  {{ "userDetails" | translate: "Common" }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="{{ 'fullname' | translate: 'Common' }}">
                      {{ "fullname" | translate: "Common" }}
                      <span class="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="fullname"
                      [(ngModel)]="addUser.FullName"
                      maxlength="200"
                      autocomplete="off"
                      placeholder="eg: Jhon Doe"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group" style="flex: 1">
                    <label for="{{ 'username' | translate: 'Common' }}">
                      {{ "username" | translate: "Common" }}
                      <span class="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="username"
                      [(ngModel)]="addUser.UserName"
                      [disabled]="editUserName"
                      autocomplete="off"
                      placeholder="eg: jhon_doe"
                    />
                    <!-- <div *ngIf="userID != 0" class="s15 username_label">
                      {{ addUser.UserName }}
                    </div> -->
                  </div>
                </div>
                <div class="col-md-4">
                  <div>
                    <label for="{{ 'email' | translate: 'Common' }}">
                      {{ "email" | translate: "Common" }}
                      <span class="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="email"
                      pattern=""
                      [(ngModel)]="addUser.EmailID"
                      autocomplete="off"
                      (change)="addUser.EmailID = addUser.EmailID.trim()"
                      placeholder="eg: jhondoe@jci.com"
                      [disabled]="isDisabled || pageFlag === 'Edit'"
                    />
                    <div *ngIf="userID == 0 && isEmailInvalid()" class="error">
                      {{ "invalidEmailId" | translate: "User" }}
                    </div>
                    <div *ngIf="checkifEmailIdHaveValidDomain()" class="error">
                      {{ "invalidEmailIdForSuperadmin" | translate: "User" }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="{{ 'phoneNumber' | translate: 'Common' }}">
                      {{ "phoneNumber" | translate: "Common" }}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="Mobile"
                      pattern=""
                      [(ngModel)]="addUser.Mobile"
                      autocomplete="off"
                      placeholder="eg: +191234567"
                    />
                    <div *ngIf="isMobileInvalid()" class="error">
                      {{ "invalidPhoneNumber" | translate: "Common" }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group" style="flex: 1">
                    <label for="{{ 'username' | translate: 'Common' }}">
                      {{ "language" | translate: "Common" }}
                      <span class="mandatory">*</span>
                    </label>
                    <p-dropdown
                      class="dropdown"
                      name="language"
                      [options]="languageList"
                      [(ngModel)]="selectedLanguage"
                      placeholder="{{
                        'select' | translate: 'moduleBuilder'
                      }} {{ 'language' | translate: 'Common' }}"
                      optionLabel="key"
                      (onChange)="onSelectLanguage()"
                    ></p-dropdown>
                  </div>
                </div>
                <div class="col-md-4">
                  <div
                    *ngIf="
                      (isSecurePasswordCreationEnable === false &&
                        userID == 0) ||
                      changePassword == true
                    "
                  >
                    <div class="form-group">
                      <label for="Password">
                        {{ "password" | translate: "User" }}
                        <span class="mandatory">*</span>
                      </label>
                      <input
                        type="password"
                        class="form-control"
                        name="Password"
                        [(ngModel)]="addUser.Password"
                        autocomplete="off"
                      />
                      <div *ngIf="isPasswordInvalid()" class="error">
                        {{ "invalidPassword" | translate: "User" }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div
                    *ngIf="
                      (isSecurePasswordCreationEnable === false &&
                        userID == 0) ||
                      changePassword == true
                    "
                  >
                    <div class="form-group cnfPassword">
                      <label for="ConfirmPassword">
                        {{ "cnfPassword" | translate: "User" }}
                        <span class="mandatory">*</span>
                      </label>
                      <input
                        type="password"
                        class="form-control"
                        name="ConfirmPassword"
                        [(ngModel)]="confirmPassword"
                        autocomplete="off"
                        #passwordB="ngModel"
                        (input)="checkPassword()"
                      />
                    </div>
                    <div
                      *ngIf="
                        (passwordB.dirty || passwordB.touched) && wrongPassword
                      "
                      class="text-danger"
                    >
                      {{ "passwordCheck" | translate: "User" }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" style="margin-top: 1rem">
                <div class="col-md-12 aic">
                  <div
                    class="addUserForm_content-secure_password font-weight700 text-uppercase"
                    *ngIf="
                      userID > 0 && isSecurePasswordCreationEnable === false
                    "
                  >
                    <div class="change_password">
                      <p-checkbox
                        name="changePassword"
                        [(ngModel)]="changePassword"
                        binary="true"
                        label="{{ 'changePassword' | translate: 'User' }}"
                        (click)="togglePasswordChange()"
                      ></p-checkbox>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex">
                <div class="addUserForm_otherSettings">
                  {{ "otherSettings" | translate: "Common" }}
                </div>
              </div>
              <div>
                <div class="addUserForm_content">
                  <div class="form-group">
                    <label for="{{ 'role' | translate: 'Common' }}">
                      {{ "role" | translate: "Common" }}
                      <span class="mandatory">*</span>
                    </label>
                    <p-dropdown
                      class="dropdown"
                      name="role"
                      [options]="roleList"
                      [(ngModel)]="selectedRole"
                      placeholder="{{
                        'select' | translate: 'moduleBuilder'
                      }} {{ 'role' | translate: 'Common' }}"
                      optionLabel="name"
                      (onChange)="onSelectRole($event)"
                      [disabled]="isDisabled"
                    ></p-dropdown>
                  </div>

                  <div
                    *ngIf="
                      roleName == 'Client Admin' ||
                      roleName == 'Regional Manager' ||
                      roleName == 'Site Manager' ||
                      roleName == 'Site As User'
                    "
                  >
                    <div class="form-group">
                      <label for="{{ 'roles' | translate: 'Common' }}">
                        {{ "clients" | translate: "Client" }}
                        <span class="mandatory">*</span>
                      </label>
                      <p-dropdown
                        class="dropdown"
                        name="client"
                        [options]="clientList"
                        [(ngModel)]="selectedClient"
                        optionLabel="ClientName"
                        placeholder="{{
                          'select' | translate: 'moduleBuilder'
                        }} {{ 'client' | translate: 'Client' }}"
                        filter="true"
                        (onChange)="onSelectClient()"
                        [disabled]="isDisabled || editUserFlag"
                      ></p-dropdown>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      roleName == 'Admin Reseller' ||
                      roleName == 'Field Engineer'
                    "
                  >
                    <div class="form-group">
                      <label for="{{ 'roles' | translate: 'Common' }}">
                        {{ "clients" | translate: "Client" }}
                        <span class="mandatory">*</span>
                      </label>
                      <p-multiSelect
                        class="multipleClients"
                        name="multipleClientsSelection"
                        [options]="clientList"
                        [(ngModel)]="selectedClients"
                        optionLabel="ClientName"
                        optionValue="ClientID"
                        defaultLabel="{{
                          'select' | translate: 'moduleBuilder'
                        }} {{ 'client' | translate: 'Client' }}"
                        (onChange)="onSelectClients()"
                      ></p-multiSelect>
                    </div>
                  </div>
                  <div *ngIf="roleName == 'Regional Manager'">
                    <div class="form-group">
                      <label for="{{ 'roles' | translate: 'Common' }}">
                        {{ "areas" | translate: "Area" }}
                        <span class="mandatory">*</span>
                      </label>
                      <p-dropdown
                        class="dropdown"
                        name="area"
                        [options]="areaList"
                        [(ngModel)]="selectedArea"
                        optionLabel="AreaName"
                        placeholder="{{
                          'select' | translate: 'moduleBuilder'
                        }} {{ 'area' | translate: 'Area' }}"
                        filter="true"
                        (onChange)="onSelectArea()"
                        [disabled]="!selectedClient || isDisabled"
                      ></p-dropdown>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      roleName == 'Site Manager' || roleName == 'Site As User'
                    "
                  >
                    <div class="form-group">
                      <label for="{{ 'roles' | translate: 'Common' }}">
                        {{ "sites" | translate: "Site" }}
                        <span class="mandatory">*</span>
                      </label>
                      <p-dropdown
                        class="dropdown"
                        name="site"
                        [options]="siteList"
                        [(ngModel)]="selectedSite"
                        optionLabel="siteName"
                        placeholder="{{
                          'select' | translate: 'moduleBuilder'
                        }} {{ 'site' | translate: 'Site' }}"
                        filter="true"
                        (onChange)="onSelectSite()"
                        [disabled]="!selectedClient || isDisabled"
                      ></p-dropdown>
                    </div>
                  </div>
                </div>
                <div
                  class="enable_subscription"
                  *ngIf="
                    selectedRole &&
                    (selectedRole.id === 3 ||
                      selectedRole.id === 4 ||
                      selectedRole.id === 5 ||
                      selectedRole.id === 6)
                  "
                >
                  <div>
                    <div>
                      <div class="escalation_section">
                        {{ "notificationMethods" | translate: "Notification" }}
                      </div>
                    </div>

                    <div class="form-group1">
                      <span class="email_image"></span>
                      <span>
                        <p-checkbox
                          name="emailNotification"
                          [(ngModel)]="emailNotification"
                          [style]="{ 'font-weight': 400 }"
                          binary="true"
                          label="{{ 'email' | translate: 'User' }}"
                          [disabled]="
                            addUser.EmailID === null ||
                            addUser.EmailID === undefined ||
                            addUser.EmailID === '' ||
                            this.warnMsg?.length > 0
                          "
                          (onChange)="
                            checkEnableSubscriptionOfflineNotificationState()
                          "
                        ></p-checkbox>
                      </span>
                      <span style="margin-left: 16px">
                        <span class="sms_image"></span>
                        <p-checkbox
                          name="smsNotification"
                          [style]="{ 'font-weight': 400 }"
                          [(ngModel)]="smsNotification"
                          binary="true"
                          label="{{ 'sms' | translate: 'User' }}"
                          [disabled]="
                            addUser.Mobile === null ||
                            addUser.Mobile === undefined ||
                            addUser.Mobile === '' ||
                            this.warnMsg?.length > 0
                          "
                          (onChange)="
                            checkEnableSubscriptionOfflineNotificationState()
                          "
                        ></p-checkbox>
                      </span>
                    </div>
                    <div>
                      <div class="escalation_section">
                        {{
                          "alarmNotificationandEscalation" | translate: "User"
                        }}
                      </div>
                    </div>
                    <div class="form-group">
                      <p-checkbox
                        name="isAlarmSubEnable"
                        [(ngModel)]="isAlarmSubEnable"
                        binary="true"
                        label="{{
                          'enableAlarmSubscription' | translate: 'User'
                        }}"
                        [disabled]="
                          !emailNotification &&
                          !smsNotification &&
                          warnMsg?.length > 0
                        "
                      ></p-checkbox>
                      <p class="font-weight600" *ngIf="warnMsg?.length > 0">
                        ({{ warnMsg | translate: "Common" }})
                      </p>
                    </div>
                    <div>
                      <div class="form-group">
                        <p-checkbox
                          name="offlineNotification"
                          [(ngModel)]="offlineNotification"
                          binary="true"
                          label="{{
                            'enableSiteOfflineNotification' | translate: 'User'
                          }}"
                          [disabled]="
                            !emailNotification &&
                            !smsNotification &&
                            warnMsg?.length > 0
                          "
                        ></p-checkbox>
                        <p class="font-weight600" *ngIf="warnMsg?.length > 0">
                          ({{ warnMsg | translate: "Common" }})
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="escalation_section_level_background">
                    <div class="form-group2">
                      <p-checkbox
                        name="enableAlarmEscalation"
                        [(ngModel)]="enableAlarmEscalation"
                        binary="true"
                        label="{{
                          'enableAlarmEscalations' | translate: 'User'
                        }}"
                        (onChange)="toggleEnableAlarmEscalation()"
                      ></p-checkbox>
                    </div>
                    <div *ngIf="showEscalationLevelMenu">
                      <div class="form-group2_text">
                        {{ "selectReqdEscalation" | translate: "User" }}
                      </div>
                      <div>
                        <span>
                          <p-checkbox
                            name="level1"
                            [(ngModel)]="level1"
                            binary="true"
                            label="{{ 'level1' | translate: 'User' }}"
                            (onChange)="checkEnableLevelState()"
                          ></p-checkbox>
                        </span>
                        <span style="margin-left: 16px">
                          <p-checkbox
                            name="level2"
                            [(ngModel)]="level2"
                            binary="true"
                            label="{{ 'level2' | translate: 'User' }}"
                            (onChange)="checkEnableLevelState()"
                          ></p-checkbox>
                        </span>
                        <span style="margin-left: 16px">
                          <p-checkbox
                            name="level3"
                            [(ngModel)]="level3"
                            binary="true"
                            label="{{ 'level3' | translate: 'User' }}"
                            (onChange)="checkEnableLevelState()"
                          ></p-checkbox>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="modal-footer" class="flex flex-content-center mt-5">
            <button
              type="button"
              class="btn btn-default"
              (click)="clearModal()"
              routerLink="/user"
            >
              {{ "cancel" | translate: "Common" }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              [disabled]="disabledState()"
              (click)="saveUser()"
            >
              <span *ngIf="userID == 0">
                {{ "save" | translate: "Common" }}
              </span>
              <span *ngIf="userID > 0">
                {{ "update" | translate: "Common" }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="logout-overlay" *ngIf="prefferedlanguageChange">
  <div class="logout-container modal-content">
    <div class="modal-header">
      <h5 class="modal-title">{{ "logout" | translate: "Common" }}</h5>
    </div>
    <div class="modal-body">
      {{ "userPrefrenceChange" | translate: "User" }}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="logout()">
        {{ "logout" | translate: "Common" }}
      </button>
    </div>
  </div>
</div>

<p-toast position="bottom-left"></p-toast>
