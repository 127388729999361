import { DatePipe } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { TemperatureUtil } from "src/app/common/class/temperature-util";
import { RestApi } from "src/app/common/constants/RestAPI";
import {
  Constant,
  categoryType,
  sensorType,
} from "src/app/common/constants/constant";
import { ToastMsg } from "src/app/common/constants/toastmsg.constant";
import { SensorTemplate } from "src/app/common/models/client.model";
import { PennService } from "src/app/common/penn.service";
import { HttpService } from "src/app/common/services/http.service";
import { TranslateService } from "src/app/common/services/translate.service";

enum EsclationLevels {
  Level1 = 1,
  Level2 = 2,
  Level3 = 3,
}

enum PipeType {
  Hot = "hot",
  Cold = "cold",
}

const sensorLabel = {
  Temperature: "Temperature",
  TemperatureWithHumidity: "Temperature & Humidity",
  DoorContact: "Door Contact",
  WaterDetector: "Water Detection",
  CO2: "CO2",
  Pipe: "Pipe Monitoring",
};

@Component({
  selector: "app-sensor-template",
  templateUrl: "./sensor-template.component.html",
  styleUrls: ["./sensor-template.component.scss"],
})
export class SensorTemplateComponent implements OnInit {
  addTemplate: SensorTemplate;
  sensorTypeList = sensorType.slice(1);
  categoryList = categoryType.slice(1);
  selectedSensorType;
  co2SensorErrorMsg = "";
  co2SensorMaxErrorMsg = "";
  pressureSensorErrorMsg = "";
  pressureSensorMaxErrorMsg = "";

  hoursList = [
    { label: "1 Hour", value: 60 },
    { label: "2 Hour", value: 120 },
    { label: "3 Hour", value: 180 },
    { label: "4 Hour", value: 240 },
    { label: "5 Hour", value: 300 },
    { label: "6 Hour", value: 360 },
  ];
  clientId: number;
  currentDate: string;
  userProfile;
  customerSensorTemplateId: number;
  flagTemp = false;
  flagHumidity = false;
  flagdoor = false;
  editFlag = false;
  flagWater = false;
  createdDate;
  @ViewChild("closeBtn", { static: true }) closeBtn: ElementRef;
  humidityErrorMsg = "";
  temperatureErrorMsg = "";
  humidityMaxErrorMsg = "";
  temperatureMaxErrorMsg = "";
  temperatureType: string;
  temperatureUnit: string;
  flagPressure;
  flagCO2;
  flagPipe;

  constructor(
    private translate: TranslateService,
    private httpService: HttpService,
    public pennService: PennService,
    private _date: DatePipe,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public messageService: MessageService
  ) {}

  ngOnInit() {
    this.addTemplate = <SensorTemplate>{};
    this.clientId = this.pennService.getStoredData("clientID");
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.customerSensorTemplateId = parseInt(
      this.activeRoute.snapshot.params["id"]
    );

    if (this.customerSensorTemplateId) {
      this.editFlag = true;
      this.editSensorTemplateFlow();
    }

    this.temperatureType = this.pennService.getStoredData(
      "clientPreferenceTempType"
    );

    if (this.temperatureType === Constant.TemperatureType.Fahrenheit) {
      this.temperatureUnit = Constant.TemperatureUnit.Fahrenheit;
    } else if (this.temperatureType === Constant.TemperatureType.Celsius) {
      this.temperatureUnit = Constant.TemperatureUnit.Celsius;
    }
  }

  editSensorTemplateFlow() {
    this.httpService
      .get(
        RestApi.getCustomerSensorTemplateDetailsID +
          this.customerSensorTemplateId
      )
      .subscribe((res: any) => {
        this.addTemplate = res.Data;
        console.log("this.addtemelate", this.addTemplate);
        const selectedType = this.sensorTypeList.filter(
          (ele) => ele.value === res.Data.SensorType
        );

        const selectedCategory = this.categoryList.filter(
          (ele) => ele.value === res.Data.Category
        );

        this.setEscalationTimeLevels(res);

        setTimeout(() => {
          this.addTemplate.SensorType = selectedType[0];
          /* Ticket ZP-5580: Removed the dropdown and displaying the stored value of 'Category'
             when editing the template. */
          // this.addTemplate.Category = selectedCategory[0];
          this.onSelectSensorType(this.addTemplate.SensorType);
        }, 0);
      });
  }

  setEscalationTimeLevels(response) {
    if (this.addTemplate.EscalationLevel1Interval) {
      const hoursLevel = this.hoursList.filter(
        (ele) => ele.value === response.Data.EscalationLevel1Interval
      );
      this.addTemplate.EscalationLevel1Interval = hoursLevel[0];
    }

    if (this.addTemplate.EscalationLevel2Interval) {
      const hoursLevel = this.hoursList.filter(
        (ele) => ele.value === response.Data.EscalationLevel2Interval
      );
      this.addTemplate.EscalationLevel2Interval = hoursLevel[0];
    }

    if (this.addTemplate.EscalationLevel3Interval) {
      const hoursLevel = this.hoursList.filter(
        (ele) => ele.value === response.Data.EscalationLevel3Interval
      );
      this.addTemplate.EscalationLevel3Interval = hoursLevel[0];
    }
  }

  farenhiteToCelcius(temp) {
    const celcius = (temp - 32) / 1.8;
    return parseFloat(celcius.toFixed(2));
  }

  onSelectSensorType(sensortype) {
    this.resetSensorTemplateSection();
    if (sensortype.label === sensorLabel.Temperature) {
      this.enableFieldBasedOnSensorType(
        true,
        false,
        false,
        false,
        false,
        true,
        false
      );
      if (!this.editFlag) {
        this.resetTemperatureSection();
        this.resetHumiditySection();
        this.resetDoorSection();
        this.resetPressureSection();
        this.resetCO2Section();
      } else {
        this.convertAndSetTemperature(
          this.addTemplate.AlarmMaxTemperature,
          this.addTemplate.AlarmMinTemperature
        );
      }
    } else if (sensortype.label === sensorLabel.TemperatureWithHumidity) {
      this.enableFieldBasedOnSensorType(
        true,
        true,
        false,
        false,
        false,
        true,
        false
      );
      if (!this.editFlag) {
        this.resetTemperatureSection();
        this.resetDoorSection();
        this.resetPressureSection();
        this.resetCO2Section();
      } else {
        this.convertAndSetTemperature(
          this.addTemplate.AlarmMaxTemperature,
          this.addTemplate.AlarmMinTemperature
        );
      }
    } else if (sensortype.label === sensorLabel.DoorContact) {
      this.enableFieldBasedOnSensorType(
        false,
        false,
        false,
        false,
        false,
        true,
        false
      );
      if (!this.editFlag) {
        this.resetTemperatureSection();
        this.resetHumiditySection();
        this.resetPressureSection();
        this.resetCO2Section();
      }
    } else if (sensortype.label === sensorLabel.WaterDetector) {
      this.enableFieldBasedOnSensorType(
        false,
        false,
        false,
        false,
        true,
        false,
        false
      );
      this.addTemplate.DoorAlarmDelay = 0;
      if (!this.editFlag) {
        this.resetTemperatureSection();
        this.resetHumiditySection();
        this.resetPressureSection();
        this.resetCO2Section();
      }
    } else if (sensortype.label === sensorLabel.CO2) {
      this.enableFieldBasedOnSensorType(
        true,
        true,
        true,
        true,
        false,
        false,
        false
      );
      this.resetDoorSection();
      this.resetPipeSection();
    } else if (sensortype.label === sensorLabel.Pipe) {
      this.enableFieldBasedOnSensorType(
        false,
        false,
        false,
        false,
        false,
        false,
        true
      );
      if (!this.editFlag) {
        this.resetTemperatureSection();
        this.resetHumiditySection();
        this.resetPressureSection();
        this.resetCO2Section();
        this.resetDoorSection();
      }
    }
  }

  enableFieldBasedOnSensorType(
    flagTemp,
    flagHumidity,
    flagPressure,
    flagCO2,
    flagWater,
    flagdoor,
    flagPipe
  ) {
    this.flagTemp = flagTemp;
    this.flagHumidity = flagHumidity;
    this.flagPressure = flagPressure;
    this.flagCO2 = flagCO2;
    this.flagWater = flagWater;
    this.flagdoor = flagdoor;
    this.flagPipe = flagPipe;
  }

  private resetSensorTemplateSection() {
    this.flagTemp = false;
    this.flagHumidity = false;
    this.flagdoor = false;
    this.flagWater = false;
  }

  resetHumiditySection() {
    this.addTemplate.HumidityMaxTemperature = null;
    this.addTemplate.HumidityDelay = null;
    this.addTemplate.HumidityMinTemperature = null;
  }

  resetDoorSection() {
    this.addTemplate.DoorAlarmDelay = null;
  }

  resetTemperatureSection() {
    this.addTemplate.AlarmDelay = null;
    this.addTemplate.AlarmMaxTemperature = null;
    this.addTemplate.AlarmMinTemperature = null;
  }

  resetPressureSection() {
    this.addTemplate.PressureDelay = null;
    this.addTemplate.MaxPressure = null;
    this.addTemplate.MinPressure = null;
  }

  resetCO2Section() {
    this.addTemplate.CO2Delay = null;
    this.addTemplate.MaxCO2 = null;
    this.addTemplate.MinCO2 = null;
  }

  resetPipeSection() {
    this.addTemplate.AlarmDelay = null;
    this.addTemplate.AlarmMaxTemperature = null;
    this.addTemplate.FlushFlowMonitorInterval = null;
  }

  onSelectCategory() {}

  toggleEscalationChange(toggleValue) {
    if (!toggleValue) {
      this.resetEscalationSubLevelMenu();
    }
  }

  resetEscalationSubLevelMenu() {
    this.addTemplate.IsEscalationLevel1Enabled = false;
    this.addTemplate.IsEscalationLevel2Enabled = false;
    this.addTemplate.IsEscalationLevel3Enabled = false;
    this.addTemplate.EscalationLevel3Interval = null;
    this.addTemplate.EscalationLevel2Interval = null;
    this.addTemplate.EscalationLevel1Interval = null;
  }

  validateMinMaxValues(data, flag) {
    if (!TemperatureUtil.regex.test(data)) {
      if (data && flag === "max") {
        this.addTemplate.AlarmMaxTemperature = TemperatureUtil.removeExtraDecimals(
          data
        );
      } else if (data && flag === "min") {
        this.addTemplate.AlarmMinTemperature = TemperatureUtil.removeExtraDecimals(
          data
        );
      }
    }
  }

  setAlarmDelayAndFlushInterval() {
    const pipeType = this.addTemplate.PipeTemperatureType;
    this.addTemplate.AlarmDelay = 7;
    this.addTemplate.FlushFlowMonitorInterval = 7;
    this.addTemplate.FlushThresholdTemperatureVariance = 5;
    this.addTemplate.FlushThresholdMonitorWindowInterval = 5;
    if (pipeType === PipeType.Hot) {
      this.addTemplate.AlarmMaxTemperature = 50;

      this.addTemplate.FlushFlowMonitorInterval = 7;
    } else if (pipeType === PipeType.Cold) {
      this.addTemplate.AlarmMaxTemperature = 20;
    }
  }

  validateNumber(event) {
    TemperatureUtil.validateNumber(event);
  }

  checkforDecimal(data, flag, event?) {
    if (flag === "delay") {
      this.addTemplate.AlarmDelay = data ? Math.trunc(data) : null;
      this.negativeInputRemove(event);
    } else if (flag === "humiditydelay") {
      this.addTemplate.HumidityDelay = data ? Math.trunc(data) : null;
      this.negativeInputRemove(event);
    } else if (flag === "dooralarmdelay") {
      this.addTemplate.DoorAlarmDelay = data ? Math.trunc(data) : null;
      this.negativeInputRemove(event);
    } else if (flag === "co2alarmdelay") {
      this.addTemplate.CO2Delay = data ? Math.trunc(data) : null;
      this.negativeInputRemove(event);
    } else if (flag === "pressurealarmdelay") {
      this.addTemplate.DoorAlarmDelay = data ? Math.trunc(data) : null;
      this.negativeInputRemove(event);
    }
  }

  roundTemperatureInCelsius(temp: number): number {
    if (temp) {
      return Math.round(temp * 10) / 10;
    }
    return temp;
  }

  convertTemperatureToFahrenheit(temp: number): number {
    if (temp) {
      return Math.round(((temp * 9) / 5 + 32) * 10) / 10;
    }
    return temp;
  }

  convertAndSetTemperature(maxTemp: number, minTemp: number) {
    if (this.temperatureType === Constant.TemperatureType.Fahrenheit) {
      // Converting Celsius value from backend to Fahrenheit for temperature
      this.addTemplate.AlarmMaxTemperature = this.convertTemperatureToFahrenheit(
        maxTemp
      );
      this.addTemplate.AlarmMinTemperature = this.convertTemperatureToFahrenheit(
        minTemp
      );
    } else if (this.temperatureType === Constant.TemperatureType.Celsius) {
      this.addTemplate.AlarmMaxTemperature = this.roundTemperatureInCelsius(
        maxTemp
      );
      this.addTemplate.AlarmMinTemperature = this.roundTemperatureInCelsius(
        minTemp
      );
    }
  }

  saveTemplate() {
    if (this.temperatureType === Constant.TemperatureType.Fahrenheit) {
      // Converting Fahrenheit value to Celsius for temperature and sending to backend
      this.addTemplate.AlarmMaxTemperature = this.farenhiteToCelcius(
        this.addTemplate.AlarmMaxTemperature
      );
      this.addTemplate.AlarmMinTemperature = this.farenhiteToCelcius(
        this.addTemplate.AlarmMinTemperature
      );
    }
    const createSensorPayload = { ...this.addTemplate };

    createSensorPayload["SensorType"] = createSensorPayload.SensorType["value"];
    /* Ticket ZP-5580: This is setting undefined value in edit mode when category value is not matched */
    if (!this.editFlag) {
      createSensorPayload["Category"] = createSensorPayload.Category["value"];
    }
    createSensorPayload[
      "EscalationLevel1Interval"
    ] = createSensorPayload.IsEscalationLevel1Enabled
      ? createSensorPayload.EscalationLevel1Interval["value"]
      : null;
    createSensorPayload[
      "EscalationLevel2Interval"
    ] = createSensorPayload.IsEscalationLevel2Enabled
      ? createSensorPayload.EscalationLevel2Interval["value"]
      : null;
    createSensorPayload[
      "EscalationLevel3Interval"
    ] = createSensorPayload.IsEscalationLevel3Enabled
      ? createSensorPayload.EscalationLevel3Interval["value"]
      : null;

    createSensorPayload["ClientID"] = this.clientId;
    createSensorPayload["IsCustomSensorTemplate"] = true;
    createSensorPayload["CreatedOn"] = this.editFlag
      ? new Date(createSensorPayload.CreatedOn)
      : this.createdDate;
    createSensorPayload["CreatedBy"] = this.userProfile.userName;
    createSensorPayload["CreatedByID"] = this.userProfile.userID;
    createSensorPayload["ModifiedOn"] = this.createdDate;
    createSensorPayload["ModifiedBy"] = this.userProfile.userName;
    createSensorPayload["ModifiedByID"] = this.userProfile.userID;

    this.httpService
      .post(RestApi.saveCustomerTemplate, createSensorPayload)
      .subscribe((res: any) => {
        this.closeBtn.nativeElement.click();
        if (res.IsSuccess) {
          this.router.navigate(["./configuration/details"]);
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: this.translate.data.Error_Msg.unableToApplyConfig,
          });
        }
      });

    console.log("createSensorPayload", createSensorPayload);
  }

  toggleLevelWiseEscalation(levelValue, toggleValue) {
    if (EsclationLevels.Level1 === levelValue && !toggleValue) {
      this.addTemplate.IsEscalationLevel3Enabled = false;
      this.addTemplate.IsEscalationLevel2Enabled = false;
    } else if (EsclationLevels.Level2 === levelValue && !toggleValue) {
      this.addTemplate.IsEscalationLevel3Enabled = false;
    }
  }

  inputValidator(event: any) {
    const pattern = /^[a-zA-Z0-9' .-]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
    }
  }

  validate() {
    return (
      !this.addTemplate.Name ||
      !this.addTemplate.Category ||
      !this.addTemplate.SensorType
    );
  }

  validateSensorDataFields() {
    if (this.flagHumidity) {
      return (
        (!this.addTemplate.HumidityDelay &&
          this.addTemplate.HumidityDelay !== 0) ||
        (!this.addTemplate.HumidityMaxTemperature &&
          this.addTemplate.HumidityMaxTemperature !== 0) ||
        (!this.addTemplate.HumidityMinTemperature &&
          this.addTemplate.HumidityMinTemperature !== 0)
      );
    }

    if (this.flagTemp) {
      return (
        (!this.addTemplate.AlarmDelay && this.addTemplate.AlarmDelay !== 0) ||
        (!this.addTemplate.AlarmMaxTemperature &&
          this.addTemplate.AlarmMaxTemperature !== 0) ||
        (!this.addTemplate.AlarmMinTemperature &&
          this.addTemplate.AlarmMinTemperature !== 0)
      );
    }

    if (this.flagCO2 || this.flagPressure) {
      return (
        (!this.addTemplate.PressureDelay &&
          this.addTemplate.PressureDelay !== 0) ||
        (!this.addTemplate.MaxPressure && this.addTemplate.MaxPressure !== 0) ||
        (!this.addTemplate.MinPressure && this.addTemplate.MinPressure !== 0) ||
        (!this.addTemplate.CO2Delay && this.addTemplate.CO2Delay !== 0) ||
        (!this.addTemplate.MaxCO2 && this.addTemplate.MaxCO2 !== 0) ||
        (!this.addTemplate.MinCO2 && this.addTemplate.MinCO2 !== 0)
      );
    }

    if (this.flagdoor) {
      return (
        !this.addTemplate.DoorAlarmDelay &&
        this.addTemplate.DoorAlarmDelay !== 0
      );
    }
  }

  validateHumidity() {
    this.humidityErrorMsg = "";
    this.humidityMaxErrorMsg = "";
    if (
      this.addTemplate.HumidityMaxTemperature < 0 ||
      this.addTemplate.HumidityMaxTemperature > 100 ||
      this.addTemplate.HumidityMinTemperature < 0 ||
      this.addTemplate.HumidityMinTemperature > 100
    ) {
      this.humidityErrorMsg = this.translate.data.Common.humidityError;
      this.humidityMaxErrorMsg = this.translate.data.Common.humidityError;
      return true;
    }

    if (
      this.addTemplate.HumidityMaxTemperature &&
      this.addTemplate.HumidityMinTemperature &&
      this.addTemplate.HumidityMaxTemperature <
        this.addTemplate.HumidityMinTemperature
    ) {
      this.humidityErrorMsg = this.translate.data.Common.humidityMinMaxError;
      this.humidityMaxErrorMsg = this.translate.data.Common.humidityMaxError;
      return true;
    }
  }

  validateTemperature() {
    this.temperatureErrorMsg = "";
    this.temperatureMaxErrorMsg = "";

    if (
      this.addTemplate.AlarmMaxTemperature &&
      this.addTemplate.AlarmMinTemperature
    ) {
      if (
        this.addTemplate.AlarmMaxTemperature <
        this.addTemplate.AlarmMinTemperature
      ) {
        this.temperatureErrorMsg = this.translate.data.Common.temperatureError;
        this.temperatureMaxErrorMsg = this.translate.data.Common.temperatureMaxError;
        return true;
      }
    }
  }

  validatePressure() {
    this.pressureSensorErrorMsg = "";
    this.pressureSensorMaxErrorMsg = "";

    if (
      this.addTemplate.MaxPressure < 500 ||
      this.addTemplate.MaxPressure > 1110 ||
      this.addTemplate.MinPressure < 500 ||
      this.addTemplate.MinPressure > 1110
    ) {
      this.pressureSensorErrorMsg = this.translate.data.Common.pressureRangeError;
      this.pressureSensorMaxErrorMsg = this.translate.data.Common.pressureRangeError;
      return true;
    }

    if (this.addTemplate.MaxPressure && this.addTemplate.MinPressure) {
      if (this.addTemplate.MaxPressure < this.addTemplate.MinPressure) {
        this.pressureSensorErrorMsg = this.translate.data.Common.pressureError;
        this.pressureSensorMaxErrorMsg = this.translate.data.Common.pressureMaxError;
        return true;
      }
    }
  }

  validateCO2() {
    this.co2SensorErrorMsg = "";
    this.co2SensorMaxErrorMsg = "";

    if (
      this.addTemplate.MaxCO2 < 0 ||
      this.addTemplate.MaxCO2 > 5000 ||
      this.addTemplate.MinCO2 < 0 ||
      this.addTemplate.MinCO2 > 5000
    ) {
      this.co2SensorErrorMsg = this.translate.data.Common.co2RangeError;
      this.co2SensorMaxErrorMsg = this.translate.data.Common.co2RangeError;
      return true;
    }

    if (this.addTemplate.MaxCO2 && this.addTemplate.MinCO2) {
      if (this.addTemplate.MaxCO2 < this.addTemplate.MinCO2) {
        this.co2SensorErrorMsg = this.translate.data.Common.co2Error;
        this.co2SensorMaxErrorMsg = this.translate.data.Common.co2MaxError;
        return true;
      }
    }
  }

  negativeInputRemove(event) {
    if (["e", "E", "+", "-", "."].includes(event.key)) {
      event.preventDefault();
    }
  }
}
