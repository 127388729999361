import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { ToastMsg } from "../common/constants/toastmsg.constant";
import { TranslateService } from "../common/services/translate.service";
import { HttpService } from "src/app/common/services/http.service";
import { RestApi } from "../common/constants/RestAPI";
import { PennService } from "../common/penn.service";
import { SessionVariable } from "../common/class/storageLabel";
import { DatePipe } from "@angular/common";
import { Constant } from "../common/constants/constant";

enum SensorType {
  Temperature = "Temperature",
  Door = "Door Contact",
  TemperatureHumidity = "Temperature & Humidity",
  Water = "Water Detection",
  CO2 = "CO2",
}

@Component({
  selector: "app-add-sensor",
  templateUrl: "./add-sensor.component.html",
  styleUrls: ["./add-sensor.component.scss"],
})
export class AddSensorComponent implements OnInit {
  ipAddress: string;
  gateway: string;
  sensorDetails;
  userProfile;
  createdDate;
  selectedCabinet;
  enableCabinetTextField = false;
  cabinetName;
  selectedTemplate;
  sensorType = SensorType;
  selectedSensorType;
  TemperatureType;
  siteID;
  oldSensorKey: string;
  replaceSensorKey: boolean = false;
  sensorGatewayKey: string;
  gatewayID: number;
  cabinetId: number;
  sensorTemplateName: string = "";
  sensorTemplateID: number;
  isCustomSensorTemplate: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public messageService: MessageService,
    private translate: TranslateService,
    private httpService: HttpService,
    public pennService: PennService,
    private _date: DatePipe
  ) {
    const routingInfo = this.router.getCurrentNavigation();
    if (routingInfo && routingInfo.extras.state) {
      this.sensorDetails = this.router.getCurrentNavigation().extras.state.sensorDetails;
      this.oldSensorKey = this.router.getCurrentNavigation().extras.state.oldSensorKey;
      this.replaceSensorKey = JSON.parse(
        this.router.getCurrentNavigation().extras.state.replaceSensorKey
      );
      this.sensorGatewayKey = this.router.getCurrentNavigation().extras.state.sensorGatewayKey;
      this.gatewayID = parseInt(
        this.router.getCurrentNavigation().extras.state.gatewayID
      );
      this.cabinetName = this.router.getCurrentNavigation().extras.state.cabinetName;
      this.cabinetId = parseInt(
        this.router.getCurrentNavigation().extras.state.cabinetId
      );
      this.sensorTemplateName = this.router.getCurrentNavigation().extras.state.sensorTemplateName;
      this.sensorTemplateID = parseInt(
        this.router.getCurrentNavigation().extras.state.sensorTemplateID
      );
      this.isCustomSensorTemplate = JSON.parse(
        this.router.getCurrentNavigation().extras.state.isCustomSensorTemplate
      );
      this.setSensorType();
    } else {
      this.router.navigate(["/commissioning/listsensorgateway"]);
    }
  }

  ngOnInit(): void {
    this.TemperatureType = this.pennService.getStoredData("temperatureType");
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.createdDate = this._date.transform(
      new Date(),
      Constant.DateType.longDate
    );
  }

  navigateToSensorGatewayList(): void {
    const sensorDeviceModel = this.sensorDetails.sensorDeviceModel;

    const payload = {
      SensorKey: sensorDeviceModel.SensorKey,
      ClientID: sensorDeviceModel.ClientID,
      ClientName: sensorDeviceModel.ClientName,
      SiteID: sensorDeviceModel.SiteID,
      SiteName: sensorDeviceModel.SiteName,
      PremiseID: sensorDeviceModel.PremiseID,
      Status: "Active",
      Enabled: 1,
      SensorTemplateID: this.selectedTemplate.SensorTemplateID,
      IsCustomSensorTemplate: this.selectedTemplate.IsCustomSensorTemplate
        ? 1
        : 0,
      CabinetName: this.selectedCabinet.CabinetID
        ? this.selectedCabinet.CabinetName
        : this.selectedCabinet,
      CabinetID: this.selectedCabinet.CabinetID
        ? this.selectedCabinet.CabinetID
        : null,
      CreatedOn: this.createdDate,
      CreatedByID: this.userProfile.userID,
      CreatedBy: this.userProfile.userName,
      ModifiedOn: this.createdDate,
      ModifiedByID: this.userProfile.userID,
      ModifiedBy: this.userProfile.userName,
    };

    this.httpService
      .post(RestApi.CommissionSensorDevice, payload)
      .subscribe((res) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg
              .successfullyAddedCloudConnector,
          });
          setTimeout(() => {
            this.router.navigate(["/commissioning/listsensorgateway"]);
          }, 600);
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  replaceSensor(): void {
    const sensorDeviceModel = this.sensorDetails.sensorDeviceModel;

    const payload = {
      OldSensorKey: this.oldSensorKey.toLowerCase(),
      NewSensorKey: sensorDeviceModel.SensorKey,
      ClientID: sensorDeviceModel.ClientID,
      ClientName: sensorDeviceModel.ClientName,
      GatewayKey: this.sensorGatewayKey,
      GatewayID: this.gatewayID,
      SiteID: sensorDeviceModel.SiteID,
      SiteName: sensorDeviceModel.SiteName,
      PremiseID: sensorDeviceModel.PremiseID,
      Status: "Active",
      Enabled: 1,
      SensorTemplateID: this.sensorTemplateID,
      SensorType: sensorDeviceModel.SensorType,
      IsCustomSensorTemplate: this.isCustomSensorTemplate ? 1 : 0,
      CabinetName: this.cabinetName,
      CabinetID: this.cabinetId,
      CreatedOn: this.createdDate,
      CreatedByID: this.userProfile.userID,
      CreatedBy: this.userProfile.userName,
      ModifiedOn: this.createdDate,
      ModifiedByID: this.userProfile.userID,
      ModifiedBy: this.userProfile.userName,
    };

    this.httpService
      .post(RestApi.ReplaceCommissionSensorDevice, payload)
      .subscribe((res) => {
        if (res.IsSuccess) {
          this.messageService.add({
            severity: ToastMsg.severity.success,
            summary: this.translate.data.Popuop_Msg.sensorReplacedSuccess,
          });
          setTimeout(() => {
            this.router.navigate(["/commissioning/listsensorgateway"]);
          }, 600);
        } else {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary: res.ReturnMessage,
          });
        }
      });
  }

  showCabinetNameInput() {
    this.resetCabinetMenu();
  }

  resetCabinetMenu() {
    this.selectedCabinet = "";
  }

  showCabinetMenu() {
    this.cabinetName = "";
  }

  setSensorType() {
    this.selectedSensorType = this.sensorDetails.sensorDeviceModel.SensorType;
  }

  getColorClass(signalStrength: number): string {
    if (signalStrength === null) {
      return "";
    } else if (signalStrength > -65) {
      return "green_signal";
    } else if (signalStrength > -75 && signalStrength <= -65) {
      return "orange_signal";
    } else if (signalStrength <= -75) {
      return "red_signal";
    } else {
      return "";
    }
  }

  getBatteryStatusClass(batteryStrength: number): string {
    if (batteryStrength === null) {
      return "";
    } else if (batteryStrength >= 70 && batteryStrength <= 100) {
      return "battery_full";
    } else if (batteryStrength >= 30 && batteryStrength <= 69) {
      return "battery_medium";
    } else if (batteryStrength >= 0 && batteryStrength <= 29) {
      return "battery_low";
    } else {
      return "";
    }
  }

  validateOk() {
    return !this.selectedCabinet || !this.selectedTemplate;
  }

  convertTemperature(value: number): number {
    if (this.TemperatureType === Constant.TemperatureType.Fahrenheit) {
      return Math.round(((value * 9) / 5 + 32) * 10) / 10;
    } else if (this.TemperatureType === Constant.TemperatureType.Celsius) {
      return Math.round(value * 10) / 10;
    }
    return value;
  }
}
