<div class="wrapper">
  <div class="config">
    <div class="row">
      <div class="col-sm-12 breadcrumbSection ml-1">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item" *ngIf="routeUrl == clientRoute">
              <a routerLink="/client">{{ clientName }}</a>
            </li>
            <li class="breadcrumb-item" *ngIf="routeUrl == siteRoute">
              <a routerLink="/site">{{ siteName }}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ "configuration" | translate: "Common" }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 tabs-custom m-24">
        <p-tabView
          (onChange)="handleChange($event)"
          [activeIndex]="moduleIndex"
        >
          <p-tabPanel header="{{ 'data' | translate: 'Common' }}">
            <ul class="data-tile">
              <li *ngFor="let item of deafultList">
                <div class="data-container cp" routerLink="{{ item.route }}">
                  <div class="img-container">
                    <img src="{{ item.icon }}" alt="" width="38px" />
                  </div>
                  <h4>{{ item.name | translate: "Client" }}</h4>
                  <h2>{{ item.count }}</h2>
                </div>
              </li>
            </ul>
            <hr class="mt-4 mb-3" />
            <h3 *ngIf="routeUrl == clientRoute">
              {{ "customList" | translate: "Configuration" }}
            </h3>
            <ul class="data-tile" *ngIf="routeUrl == clientRoute">
              <li
                class="add-button cp"
                data-toggle="modal"
                data-target="#addMDataModal"
                (click)="addDataPopup('Add')"
              >
                <div class="data-container">
                  <i class="fa fa-plus"></i>
                  <h4>{{ "addNew" | translate: "Common" }}</h4>
                </div>
              </li>
              <li *ngFor="let item of custDataList">
                <div
                  class="data-container cp"
                  data-toggle="modal"
                  data-target="#addMDataModal"
                  (click)="addDataPopup('Edit', item)"
                >
                  <div class="img-container">
                    <img
                      src="../../assets/images/supplier.jpg"
                      alt=""
                      width="38px"
                    />
                  </div>
                  <h4>{{ item.CustomListName }}</h4>
                  <h2>{{ item.ItemCount }}</h2>
                </div>
              </li>
            </ul>
          </p-tabPanel>
          <p-tabPanel header="{{ 'module' | translate: 'Common' }}">
            <ul class="data-tile">
              <li
                *ngIf="routeUrl == clientRoute"
                class="add-button cp"
                data-toggle="modal"
                data-target="#addModuleModal"
                (click)="showModuleModal('Add', '', $event)"
              >
                <div class="data-container">
                  <i class="fa fa-plus"></i>
                  <h4>{{ "addNew" | translate: "Common" }}</h4>
                </div>
              </li>

              <li
                *ngIf="routeUrl == siteRoute && addModuleAccess"
                class="add-button cp"
                data-toggle="modal"
                data-target="#mapClientModuleModal"
                (click)="showClientModules()"
              >
                <div class="data-container">
                  <div>
                    <i class="fa fa-plus"></i>
                  </div>
                  <h4>{{ "addNewModule" | translate: "Configuration" }}</h4>
                </div>
              </li>

              <ng-container *ngIf="routeUrl == siteRoute">
                <li *ngFor="let item of siteModuleList" class="moduleTile">
                  <div
                    class="show-context"
                    data-toggle="modal"
                    #deleteMapModuleModal
                    (click)="removeMappedModule(item, $event)"
                  >
                    <i class="fa fa-times"></i>
                  </div>
                  <div class="data-container">
                    <div class="img-container">
                      <img
                        [src]="getSantizeUrl(item.logo)"
                        alt=""
                        width="38px"
                      />
                    </div>
                    <h4>{{ item.moduleName }}</h4>
                  </div>
                </li>
              </ng-container>

              <ng-container *ngIf="routeUrl == clientRoute">
                <li
                  *ngFor="let item of newworkflowList"
                  class="moduleTile"
                  (click)="editModule(item)"
                >
                  <div class="show-context">
                    <i class="fa fa-ellipsis-v"></i>
                    <div class="context-menu">
                      <ul>
                        <li>
                          <a
                            href="javascript:void(0)"
                            (click)="showModuleModal('Edit', item, $event)"
                          >
                            {{ "edit" | translate: "Common" }}
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0)"
                            (click)="getModuleID(item, $event)"
                          >
                            {{ "delete" | translate: "Common" }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="data-container">
                    <div class="img-container">
                      <img
                        [src]="getSantizeUrl(item.Logo)"
                        alt=""
                        width="38px"
                      />
                    </div>
                    <h4>{{ item.ModuleName }}</h4>
                  </div>
                </li>
              </ng-container>
            </ul>
            <hr class="mt-4 mb-3" />
            <ng-container *ngIf="routeUrl == clientRoute">
              <h3>{{ "templates" | translate: "Common" }}</h3>
              <ul class="data-tile">
                <li
                  *ngFor="let item of mappedClientModule"
                  class="moduleTile"
                  (click)="showTemplate(item.ModuleTemplateID)"
                >
                  <div class="data-container">
                    <div class="img-container">
                      <img
                        [src]="getSantizeUrl(item.Logo)"
                        alt=""
                        width="38px"
                        height="38px"
                      />
                    </div>
                    <h4>{{ item.ModuleTemplateName }}</h4>
                  </div>
                </li>
              </ul>
            </ng-container>
            <!-- Hidden for future response(TEMPLATE MENU) -->
            <!-- <h3>{{'templates' | translate: 'Common'}}</h3>
          <ul class="data-tile">
            <li *ngFor="let item of workflowList">
              <div class="data-container">
                <div class="img-container">
                  <img src="{{item.icon}}" alt="" width="38px">
                </div>
                <h4>{{item.name}}</h4>
                <h2>{{item.count}}</h2>
              </div>
            </li>
          </ul> -->
          </p-tabPanel>

          <p-tabPanel
            header="{{ 'staff' | translate: 'Common' }}"
            *ngIf="routeUrl == siteRoute"
          >
            <div class="row aic">
              <div class="col-md-12 col-lg-12 col-sm-12 m-24">
                <div class="flex flex-end">
                  <div>
                    <a
                      href=""
                      class="btn btn-primary btncust"
                      data-toggle="modal"
                      data-target="#staffModal"
                      (click)="showStaffModal('Add')"
                    >
                      <span class="mr-2">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.66671 3.66683H6.33337V6.3335H3.66671V7.66683H6.33337V10.3335H7.66671V7.66683H10.3334V6.3335H7.66671V3.66683ZM7.00004 0.333496C3.32004 0.333496 0.333374 3.32016 0.333374 7.00016C0.333374 10.6802 3.32004 13.6668 7.00004 13.6668C10.68 13.6668 13.6667 10.6802 13.6667 7.00016C13.6667 3.32016 10.68 0.333496 7.00004 0.333496ZM7.00004 12.3335C4.06004 12.3335 1.66671 9.94016 1.66671 7.00016C1.66671 4.06016 4.06004 1.66683 7.00004 1.66683C9.94004 1.66683 12.3334 4.06016 12.3334 7.00016C12.3334 9.94016 9.94004 12.3335 7.00004 12.3335Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      {{ "addStaff" | translate: "Staff" }}
                    </a>
                  </div>
                  <div>
                    <a
                      href="javascript:void(0)"
                      class="btn btn-primary btncust"
                      (click)="importStaffExcel()"
                    >
                      <svg
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.00004 0L0.333374 2.66H2.33337V7.33333H3.66671V2.66H5.66671L3.00004 0ZM7.66671 9.34V4.66667H6.33337V9.34H4.33337L7.00004 12L9.66671 9.34H7.66671Z"
                          fill="white"
                        />
                      </svg>

                      {{ "import_new" | translate: "Staff" }}
                    </a>
                    <input
                      type="file"
                      id="staffInputFileUpload"
                      #staffInputFileUpload
                      class="customFileUpload mb-1"
                      (change)="onStaffImportFileChange($event.target.files)"
                      accept=".xlsx"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row" style="margin: 0px">
              <div class="col-md-12 col-lg-12 col-xl-12 filter_section">
                <div class="row aic">
                  <div class="col-md-2">
                    <div class="text-uppercase font-weight700 font-size12">
                      {{ "filterBy" | translate: "Common" }}
                    </div>
                  </div>
                  <div
                    class="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 tblsearch listsearch"
                  >
                    <div class="filtertitle">
                      {{ "search" | translate: "Common" }}
                    </div>
                    <input
                      type="text"
                      pInputText
                      (input)="
                        stafftable.filterGlobal($event.target.value, 'contains')
                      "
                      placeholder="{{
                        'SearchAnythingPlaceholder' | translate: 'Site'
                      }}"
                    />
                  </div>
                  <div class="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12">
                    <div class="filtertitle filter2">
                      {{ "staffName" | translate: "Staff" }}
                    </div>
                    <p-multiSelect
                      [options]="Username"
                      appendTo="body"
                      [(ngModel)]="userSelect"
                      [defaultLabel]="selectLabel"
                      (onChange)="
                        stafftable.filter($event.value, 'staffName', 'in')
                      "
                    ></p-multiSelect>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="content">
                  <div class="content-body configTable">
                    <p-table
                      #stafftable
                      [style]="{ overflow: 'auto!important' }"
                      [columns]="stafflistcols"
                      [value]="staffList"
                      [responsive]="true"
                      [rows]="10"
                      [paginator]="true"
                      [rowsPerPageOptions]="[10, 15, 20, 25]"
                    >
                      <ng-template pTemplate="header" let-columns>
                        <tr>
                          <th width="100px">
                            {{ "action" | translate: "Common" }}
                          </th>
                          <th
                            *ngFor="let col of columns"
                            [pSortableColumn]="col.field"
                            pResizableColumn
                          >
                            {{ col.header }}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template
                        pTemplate="body"
                        let-rowData
                        let-item
                        let-i="rowIndex"
                        let-columns="columns"
                      >
                        <tr>
                          <td class="actionBtn text-left">
                            <span
                              class="mr-3 cp"
                              title="{{ 'edit' | translate: 'Common' }}"
                              data-toggle="modal"
                              data-target="#staffModal"
                              (click)="showStaffModal('Edit', item)"
                            >
                              <svg
                                width="20"
                                height="18"
                                viewBox="0 0 20 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.0588 6.02L12.9788 6.94L3.91878 16H2.99878V15.08L12.0588 6.02ZM15.6588 0C15.4088 0 15.1488 0.1 14.9588 0.29L13.1288 2.12L16.8788 5.87L18.7088 4.04C19.0988 3.65 19.0988 3.02 18.7088 2.63L16.3688 0.29C16.1688 0.09 15.9188 0 15.6588 0ZM12.0588 3.19L0.998779 14.25V18H4.74878L15.8088 6.94L12.0588 3.19Z"
                                  fill="#00539E"
                                />
                              </svg>
                            </span>
                            <span
                              class="mr-3 cp"
                              data-toggle="modal"
                              data-target="#deleteStaffModal"
                              title="{{ 'delete' | translate: 'Common' }}"
                              (click)="showStaffModal('Delete', item)"
                            >
                              <svg
                                width="14"
                                height="18"
                                viewBox="0 0 14 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
                                  fill="#00539E"
                                />
                              </svg>
                            </span>
                            <button
                              class="btn btn-primary smallbtn mr-3"
                              data-toggle="modal"
                              data-target="#changePinModal"
                              (click)="showStaffModal('ChangePin', item)"
                              [title]="changePinToolTipTitle"
                            >
                              {{ "changePin" | translate: "Common" }}
                            </button>
                          </td>
                          <td>{{ item.staffName }}</td>
                        </tr>
                      </ng-template>

                      <!-- since teble is half coded in Template and half in TS  REWORK IS NECESSARY-->
                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td [attr.colspan]="9">
                            <span class="noDataText">
                              {{ "noRecord" | translate: "Common" }}
                            </span>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>

          <p-tabPanel
            header="{{ 'refrigConfig' | translate: 'Common' }}"
            *ngIf="routeUrl == clientRoute"
          >
            <ul class="data-tile">
              <li>
                <div
                  class="data-container cp"
                  routerLink="/configuration/reason"
                >
                  <div class="img-container">
                    <i class="fa fa-tasks refrgIcon" aria-hidden="true"></i>
                    <!-- <img src="{{item.icon}}" alt="" width="38px"> -->
                  </div>
                  <h4>{{ "reasons" | translate: "Reasons" }}</h4>
                  <h2>{{ reasonCount }}</h2>
                </div>
              </li>
              <li>
                <div
                  class="data-container cp"
                  routerLink="/configuration/corrective-actions"
                >
                  <div class="img-container">
                    <i class="fa fa-map refrgIcon" aria-hidden="true"></i>
                    <!-- <img src="{{item.icon}}" alt="" width="38px"> -->
                  </div>
                  <h4>
                    {{ "correctiveAction" | translate: "CorrectiveAction" }}
                  </h4>
                  <h2>{{ corrActionCount }}</h2>
                </div>
              </li>
            </ul>
          </p-tabPanel>
          <p-tabPanel
            header="{{ 'Scheduler' | translate: 'Common' }}"
            *ngIf="routeUrl == clientRoute"
          >
            <ul class="data-tile">
              <li>
                <div
                  class="data-container cp"
                  routerLink="/configuration/reminder"
                >
                  <div class="img-container">
                    <i class="fa fa-calendar fa-3x" aria-hidden="true"></i>
                    <!-- <img src="{{item.icon}}" alt="" width="38px"> -->
                  </div>
                  <h4>{{ "reminder" | translate: "Reminder" }}</h4>
                  <h2>{{ SingleReminderCount + RecurringReminder }}</h2>
                </div>
              </li>
            </ul>
          </p-tabPanel>
          <p-tabPanel
            header="{{ 'Scheduler' | translate: 'Common' }}"
            *ngIf="routeUrl == siteRoute"
          >
            <ul class="data-tile">
              <li>
                <div
                  class="data-container cp"
                  routerLink="/configuration/reminder"
                >
                  <div class="img-container">
                    <i class="fa fa-calendar fa-3x" aria-hidden="true"></i>
                    <!-- <img src="{{item.icon}}" alt="" width="38px"> -->
                  </div>
                  <h4>{{ "reminder" | translate: "Reminder" }}</h4>
                  <h2>{{ SingleReminderCount + RecurringReminder }}</h2>
                </div>
              </li>
            </ul>
          </p-tabPanel>
          <p-tabPanel
            header="{{ 'sensorTemplate' | translate: 'Client' }}"
            *ngIf="routeUrl == clientRoute && showTemplateTab"
          >
            <div class="row" *ngIf="sensorList && sensorList.length > 0">
              <div class="col-md-12 flex flex-end">
                <button
                  class="btn btn-primary btncust"
                  routerLink="/configuration/sensorTemplate"
                >
                  {{ "createSensorTemplate" | translate: "Client" }}
                </button>
              </div>
            </div>
            <div *ngIf="sensorList && sensorList.length > 0">
              <div class="row m-24">
                <div class="filter_section col-lg-12 col-xs-12 col-md-12">
                  <div class="row aic">
                    <div class="col-md-1 col-xl-1 col-lg-1 col-sm-12 col-xs-12">
                      <div class="text-uppercase font-weight700 font-size12">
                        {{ "filterBy" | translate: "Common" }}
                      </div>
                    </div>
                    <div
                      class="col-md-11 col-xl-11 col-lg-11 col-sm-12 col-xs-12"
                    >
                      <div class="row flex-space-around">
                        <div
                          class="tblsearch col-md-2 col-lg-2 col-sm-12 col-xs-12 flex flex-col p-0"
                        >
                          <p class="text-uppercase font-weight700 font-size12">
                            {{ "templateName" | translate: "Common" }}
                          </p>
                          <input
                            type="text"
                            pInputText
                            (input)="
                              dataTable.filter(
                                $event.target.value,
                                'Name',
                                'contains'
                              )
                            "
                            class="flex-auto"
                            placeholder="Search Template"
                          />
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 p-0">
                          <p class="text-uppercase font-weight700 font-size12">
                            {{ "sensorType" | translate: "Common" }}
                          </p>
                          <p-dropdown
                            class="dropdown"
                            [options]="sensorTypeList"
                            [(ngModel)]="selectedSensor"
                            optionLabel="label"
                            placeholder="Select Sensor Type"
                            appendTo="body"
                            (onChange)="
                              dataTable.filter(
                                $event.value.value,
                                'SensorType',
                                'equals'
                              )
                            "
                          ></p-dropdown>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                          <p
                            class="text-uppercase font-weight700 font-size12 p-0"
                          >
                            {{ "category" | translate: "Common" }}
                          </p>
                          <p-dropdown
                            class="dropdown"
                            [options]="categoryTypeList"
                            [(ngModel)]="selectedCategory"
                            optionLabel="label"
                            placeholder="Select Category"
                            appendTo="body"
                            (onChange)="
                              dataTable.filter(
                                $event.value.value,
                                'Category',
                                'equals'
                              )
                            "
                          ></p-dropdown>
                        </div>
                        <div
                          class="col-sm-3 col-lg-3 col-sm-12 col-xs-12 font-weight700 text-uppercase p-0 font-size12 flex aic"
                        >
                          <p-checkbox
                            name="defaultSensorToggle"
                            [(ngModel)]="defaultSensorFlag"
                            binary="true"
                            label="{{
                              'showOnlyDefaultTemplates' | translate: 'Common'
                            }}"
                            (click)="toggleSensorList(defaultSensorFlag)"
                          ></p-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row config maincontainer">
                <div class="col-sm-12">
                  <div class="content">
                    <div class="content-body configTable">
                      <p-table
                        #sensorTable
                        [style]="{ overflow: 'auto!important' }"
                        [columns]="sensorlistcols"
                        [value]="filteredSensorList"
                        [responsive]="true"
                        [rows]="10"
                        [paginator]="true"
                        [rowsPerPageOptions]="[10, 15, 20, 25]"
                      >
                        <ng-template pTemplate="header" let-columns>
                          <tr>
                            <th
                              *ngFor="let col of columns"
                              [pSortableColumn]="col.field"
                              pResizableColumn
                            >
                              {{ col.header }}
                              <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                            <th>{{ "actions" | translate: "Common" }}</th>
                          </tr>
                        </ng-template>
                        <ng-template
                          pTemplate="body"
                          let-rowData
                          let-item
                          let-i="rowIndex"
                          let-columns="columns"
                        >
                          <tr>
                            <td>{{ item.Name }}</td>
                            <td>{{ item.SensorType }}</td>
                            <td>{{ item.Category }}</td>
                            <td>
                              {{
                                item.IsEscalationEnabled
                                  ? this.enabledLabel
                                  : "-"
                              }}
                            </td>
                            <td class="actionBtn text-left">
                              <span
                                class="mr-4 pe-auto cp"
                                title="{{ 'edit' | translate: 'Common' }}"
                                [routerLink]="[
                                  '/configuration/sensorTemplate/',
                                  item.CustomerSensorTemplateID
                                ]"
                                [state]="item"
                              >
                                <svg
                                  width="20"
                                  height="18"
                                  viewBox="0 0 20 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.0588 6.02L12.9788 6.94L3.91878 16H2.99878V15.08L12.0588 6.02ZM15.6588 0C15.4088 0 15.1488 0.1 14.9588 0.29L13.1288 2.12L16.8788 5.87L18.7088 4.04C19.0988 3.65 19.0988 3.02 18.7088 2.63L16.3688 0.29C16.1688 0.09 15.9188 0 15.6588 0ZM12.0588 3.19L0.998779 14.25V18H4.74878L15.8088 6.94L12.0588 3.19Z"
                                    fill="#00539E"
                                  />
                                </svg>
                              </span>
                              <span
                                class="mr-4 pe-auto cp"
                                data-toggle="modal"
                                data-target="#deleteUserModal"
                                title="{{ 'delete' | translate: 'Common' }}"
                                (click)="setSensorTemplate(item)"
                              >
                                <svg
                                  width="14"
                                  height="18"
                                  viewBox="0 0 14 18"
                                  data-toggle="modal"
                                  data-target="#deleteTemplateSettings"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
                                    fill="#00539E"
                                  />
                                </svg>
                              </span>
                              <span
                                class="mr-2 pe-auto cp"
                                data-toggle="modal"
                                data-target="#saveTemplateSettings"
                                title="{{ 'reset' | translate: 'Common' }}"
                                (click)="setSensorTemplate(item)"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.5 3C8.53 3 4.5 7.03 4.5 12H1.5L5.39 15.89L5.46 16.03L9.5 12H6.5C6.5 8.13 9.63 5 13.5 5C17.37 5 20.5 8.13 20.5 12C20.5 15.87 17.37 19 13.5 19C11.57 19 9.82 18.21 8.56 16.94L7.14 18.36C8.77 19.99 11.01 21 13.5 21C18.47 21 22.5 16.97 22.5 12C22.5 7.03 18.47 3 13.5 3ZM12.5 8V13L16.78 15.54L17.5 14.33L14 12.25V8H12.5Z"
                                    fill="#00539E"
                                  />
                                </svg>
                              </span>
                            </td>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                          <tr>
                            <td [attr.colspan]="9">
                              <span class="noDataText">
                                {{ "noRecord" | translate: "Common" }}
                              </span>
                            </td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!sensorList">
              <tc-common-error-custom
                [norecordmessage]="noRecordMsg"
                [subinfomessage]="subinfoMsg"
              ></tc-common-error-custom>
              <div class="row">
                <div class="col-md-12 flex flex-content-center">
                  <button
                    class="btn btn-primary btncust"
                    routerLink="/configuration/sensorTemplate"
                  >
                    {{ "createSensorTemplate" | translate: "Client" }}
                  </button>
                </div>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</div>

<!-- Add Data Modal -->
<div
  class="modal fade"
  id="addMDataModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ dataPopupTitle }} {{ "data" | translate: "Common" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body customScroll">
        <form #addDataForm="ngForm">
          <div class="form-group">
            <label for="{{ 'name' | translate: 'Common' }}">
              {{ "name" | translate: "Common" }}
              <span class="mandatory">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              autocomplete="off"
              name="custlistname"
              maxlength="200"
              [(ngModel)]="customList.CustomListName"
              placeholder="{{ 'placeholderCustomname' | translate: 'Common' }}"
            />
          </div>
          <div class="form-group pb-3">
            <label for="{{ 'types' | translate: 'Common' }}">
              {{ "types" | translate: "Common" }}
              <span class="mandatory">*</span>
            </label>
            <p-dropdown
              class="dropdown"
              name="types"
              [options]="types"
              [(ngModel)]="selectedType"
              optionLabel="name"
              (onChange)="resetCustomList()"
            >
              <ng-template pTemplate="item" let-item>
                {{ item.name | translate: "CorrectiveAction" }}
              </ng-template>
              <ng-template pTemplate="selectedItem" let-item>
                {{ item.name | translate: "CorrectiveAction" }}
              </ng-template>
            </p-dropdown>
          </div>
          <hr class="mt-4 mb-4" />
          <div class="custlistTitle">
            {{ "addCustList" | translate: "Configuration" }}
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control custinput"
              maxlength="500"
              autocomplete="off"
              name="name"
              [(ngModel)]="custDataName"
              placeholder="{{ 'placeholderCustomList' | translate: 'Common' }}"
            />
            <button
              type="button"
              class="btn btn-primary custbtn"
              [disabled]="
                !custDataName?.trim() ||
                isEmailInvalid() ||
                this.selectedType.id === 0 ||
                isValueAlreadyExists()
              "
              (click)="addCustListItems()"
            >
              <span>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.66671 3.66683H6.33337V6.3335H3.66671V7.66683H6.33337V10.3335H7.66671V7.66683H10.3334V6.3335H7.66671V3.66683ZM7.00004 0.333496C3.32004 0.333496 0.333374 3.32016 0.333374 7.00016C0.333374 10.6802 3.32004 13.6668 7.00004 13.6668C10.68 13.6668 13.6667 10.6802 13.6667 7.00016C13.6667 3.32016 10.68 0.333496 7.00004 0.333496ZM7.00004 12.3335C4.06004 12.3335 1.66671 9.94016 1.66671 7.00016C1.66671 4.06016 4.06004 1.66683 7.00004 1.66683C9.94004 1.66683 12.3334 4.06016 12.3334 7.00016C12.3334 9.94016 9.94004 12.3335 7.00004 12.3335Z"
                    fill="white"
                  />
                </svg>
              </span>
            </button>
            <p *ngIf="isEmailInvalid()" class="error-message">
              {{ "invalidEmailIdCustomList" | translate: "Common" }}
            </p>
            <p *ngIf="isValueAlreadyExists()" class="error-message">
              {{ "valueAlreadyExists" | translate: "Common" }}
            </p>
          </div>

          <div
            class="form-group custItemListScroll"
            [ngClass]="{ custItemListScroll: custList.length > 9 }"
          >
            <div
              class="list-group-item custItem"
              *ngFor="let item of custList; let i = index"
            >
              <span *ngIf="this.selectedType.name === numberedListMenu">
                {{ i + 1 }}.
                {{
                  item.itemName?.indexOf("-") > 0
                    ? item.itemName.split("-")[1].trim()
                    : item.itemName
                }}
              </span>
              <span *ngIf="this.selectedType.name != numberedListMenu">
                {{ item.itemName }}
              </span>
              <span
                data-toggle="modal"
                data-target="#deleteCustListModal"
                (click)="getCustListItem(item, i)"
              >
                <i class="fa fa-times"></i>
              </span>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          #addcloseBtn
          data-dismiss="modal"
        >
          {{ "close" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-secondary mb-0"
          *ngIf="popupType === 'Edit'"
          data-dismiss="modal"
          data-toggle="modal"
          data-target="#deleteDataModal"
        >
          {{ "delete" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary mb-0"
          [disabled]="
            !customList.CustomListName ||
            selectedType.id == 0 ||
            custList.length == 0
          "
          (click)="saveCustomList()"
        >
          <span *ngIf="popupType === 'Add'">
            {{ "add" | translate: "Common" }}
          </span>
          <span *ngIf="popupType === 'Edit'">
            {{ "update" | translate: "Common" }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Add Module Modal -->
<div
  class="modal fade"
  id="addModuleModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <span *ngIf="isAdd">
            {{ "addNewModule" | translate: "Configuration" }}
          </span>
          <span *ngIf="!isAdd">
            {{ "editModule" | translate: "Configuration" }}
          </span>
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #addModuleForm="ngForm">
          <div class="form-group">
            <label for="{{ 'name' | translate: 'Common' }}">
              {{ "name" | translate: "Common" }}
              <span class="mandatory">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              autocomplete="off"
              name="moduleName"
              ngModel="moduleName"
              [(ngModel)]="addModule.moduleName"
            />
          </div>
          <div class="form-group">
            <label class="block" for="{{ 'logo' | translate: 'Common' }}">
              {{ "logo" | translate: "Common" }}
              <span class="mandatory">*</span>
            </label>
            <button
              type="button"
              class="btn btn-primary selLogoBtn"
              (click)="showDialog()"
            >
              {{ "selectLogo" | translate: "Common" }}
            </button>

            <div class="showSelLogo inline-block" *ngIf="moduleLogo">
              <img
                [src]="getSantizeUrl(moduleLogo)"
                width="40"
                height="40"
                alt=""
              />
            </div>
          </div>
          <div class="form-group" *ngIf="iconDivFlag">
            <div class="overlayDiv">
              <div class="row m-0">
                <div
                  class="col-md-2 iconSection"
                  *ngFor="let item of iconList"
                  (click)="iconDivFlag = false"
                >
                  <img
                    [src]="getSantizeUrl(item.graphicData)"
                    alt=""
                    width="35"
                    height="35"
                    (click)="getLogoName(item)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="{{ 'description' | translate: 'Common' }}">
              {{ "description" | translate: "Common" }}
            </label>
            <textarea
              class="form-control txtarea"
              name="moduleDescription"
              [(ngModel)]="addModule.description"
              cols="10"
              rows="4"
            ></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          #addmodulecloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary mb-0"
          [disabled]="ValidateModule()"
          (click)="saveModule()"
        >
          <span *ngIf="isAdd">{{ "add" | translate: "Common" }}</span>
          <span *ngIf="!isAdd">{{ "update" | translate: "Common" }}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<p-dialog
  class="logoSelection"
  header="{{ 'logoSelection' | translate: 'Common' }}"
  [modal]="true"
  [(visible)]="display"
  [responsive]="true"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [contentStyle]="{ 'max-height': '380px' }"
  [style]="{ width: '350px', minWidth: '200px' }"
>
  <div class="row m-0">
    <div
      class="col-md-2 iconSection"
      *ngFor="let item of iconList"
      (click)="display = false"
    >
      <img
        [src]="getSantizeUrl(item.graphicData)"
        alt=""
        width="35"
        height="35"
        (click)="getLogoName(item)"
      />
    </div>
  </div>
</p-dialog>

<!-- Delete Data Modal -->
<div
  class="modal fade"
  id="deleteDataModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "deleteData" | translate: "Configuration" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "deleteData" | translate: "Popuop_Msg" }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          #dataDeleteBtn
          data-dismiss="modal"
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary mb-0"
          (click)="deleteCustomList()"
        >
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Delete Module Modal -->
<div
  class="modal fade"
  id="deleteModuleModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "deleteModule" | translate: "Configuration" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "deleteModule" | translate: "Popuop_Msg" }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          data-dismiss="modal"
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary mb-0"
          data-dismiss="modal"
          (click)="deleteModule()"
        >
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="deleteCustListModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "delete" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "deleteCustList" | translate: "Popuop_Msg" }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          #deletecustlistcloseBtn
          data-dismiss="modal"
          (click)="refreshCustListData()"
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary mb-0"
          data-dismiss="modal"
          (click)="removeCustListItems()"
        >
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="deleteMapModuleModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "deleteModule" | translate: "Configuration" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "deleteModule" | translate: "Popuop_Msg" }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          #unmapcloseBtn
          data-dismiss="modal"
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary mb-0"
          data-dismiss="modal"
          (click)="deleteMappedModule()"
        >
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="mapClientModuleModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "addNewModule" | translate: "Configuration" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="row m-0 pt-3 pb-3">
        <div class="col-md-12">
          <div
            class="nodatamsg"
            *ngIf="allClientModuleList && allClientModuleList.length == 0"
          >
            {{ "nodata" | translate: "Common" }}
          </div>
          <div *ngIf="allClientModuleList && allClientModuleList.length > 0">
            <p-listbox
              class="custom-listbox"
              [options]="allClientModuleList"
              [(ngModel)]="selectedMapModule"
              multiple="multiple"
              checkbox="checkbox"
              filter="filter"
              optionLabel="ModuleName"
            ></p-listbox>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mb-0"
          #addsitemodulecloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary mb-0"
          [disabled]="!selectedMapModule || selectedMapModule.length == 0"
          (click)="mapModuleToSite()"
        >
          {{ "add" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="staffModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ modalTitle }} {{ "staff" | translate: "Staff" }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #staffForm="ngForm">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="{{ 'username' | translate: 'Common' }}">
                  {{ "username" | translate: "Common" }}
                  <span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="username"
                  [(ngModel)]="staff.staffName"
                  maxlength="200"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-md-12" *ngIf="staffID == 0">
              <div class="form-group">
                <label for="{{ 'pin' | translate: 'Common' }}">
                  {{ "pin" | translate: "Common" }}
                  <span class="mandatory">*</span>
                </label>
                <input
                  type="password"
                  class="form-control"
                  name="pin"
                  [(ngModel)]="staff.pin"
                  autocomplete="off"
                  OnlyNumber="true"
                  maxlength="4"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #staffaddcloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          *ngIf="staffID == 0"
          [disabled]="!staff.staffName || !staff.pin"
          (click)="saveStaff()"
        >
          <span *ngIf="staffID == 0">{{ "add" | translate: "Common" }}</span>
          <span *ngIf="staffID > 0">{{ "update" | translate: "Common" }}</span>
        </button>

        <button
          type="button"
          class="btn btn-primary"
          *ngIf="staffID > 0"
          [disabled]="!staff.staffName"
          (click)="saveStaff()"
        >
          <span *ngIf="staffID == 0">{{ "add" | translate: "Common" }}</span>
          <span *ngIf="staffID > 0">{{ "update" | translate: "Common" }}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<p-dialog
  class="staffImportErrors"
  header="{{ 'importerrors' | translate: 'Staff' }}"
  [modal]="true"
  [(visible)]="showStaffImportErrors"
>
  <table>
    <tr>
      <th>{{ "importerrors_row" | translate: "Staff" }}</th>
      <th>{{ "importerrors_error" | translate: "Staff" }}</th>
    </tr>
    <tr *ngFor="let error of staffImportErrors">
      <td>{{ error.row }}</td>
      <td>{{ error.error | translate: "Staff" }}</td>
    </tr>
  </table>
</p-dialog>

<div
  class="modal fade"
  id="changePinModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "changePin" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #changePinForm="ngForm">
          <div class="row">
            <div class="col-md-12">
              <div
                ngModelGroup="passwords"
                #passwords="ngModelGroup"
                appCheckPassword
              >
                <div class="form-group">
                  <label for="exampleInputPassword1">
                    {{ "enterpin" | translate: "Common" }}
                    <span class="mandatory">*</span>
                  </label>
                  <input
                    type="{{ pwdVisibility ? 'text' : 'password' }}"
                    OnlyNumber="true"
                    class="form-control"
                    id="exampleInputPassword1"
                    #passwordA="ngModel"
                    name="passwordA"
                    required
                    id="passwordA"
                    maxlength="4"
                    [(ngModel)]="staff.pin"
                  />
                  <span class="showPwd" (click)="togglePasswordVisibility()">
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-eye-slash': !pwdVisibility,
                        'fa-eye': pwdVisibility
                      }"
                      title="{{ pwdTitle }}"
                    ></i>
                  </span>
                </div>
                <div class="form-group mt-1">
                  <label for="exampleInputPassword2">
                    {{ "reenterpin" | translate: "Common" }}
                    <span class="mandatory">*</span>
                  </label>
                  <input
                    type="password"
                    OnlyNumber="true"
                    class="form-control"
                    id="exampleInputPassword2"
                    #passwordB="ngModel"
                    name="passwordB"
                    required
                    id="passwordB"
                    maxlength="4"
                    [(ngModel)]="staff.confirmPassword"
                  />
                </div>
              </div>
              <!-- <div *ngIf="passwordB.invalid && (passwordA.dirty || passwordA.touched)" class="alert alert-danger">
                  Please confirm your password.
                </div> -->
              <div
                *ngIf="
                  passwords.errors?.passwordCheck &&
                  (passwordB.dirty || passwordB.touched)
                "
                class="alert alert-danger"
              >
                Passwords do not match.
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #pinchangeaddcloseBtn
          data-dismiss="modal"
        >
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="checkStaffPin(staff)"
          (click)="saveStaff()"
        >
          <span>{{ "update" | translate: "Common" }}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="deleteStaffModal"
  data-backdrop="true"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ "delete" | translate: "Common" }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="warningMsg">
          {{ "deleteStaff" | translate: "Popuop_Msg" }} {{ staffName }} ?
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #staffdeletecloseBtn
          data-dismiss="modal"
        >
          {{ "no" | translate: "Common" }}
        </button>
        <button type="button" class="btn btn-primary" (click)="deleteStaff()">
          {{ "yes" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  data-backdrop="static"
  data-keyboard="false"
  id="saveTemplateSettings"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "confirmation" | translate: "TaskManagement" }}
        </h5>
        <button
          type="button"
          class="close modal-close"
          data-dismiss="modal"
          #closeBtn
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="font-14 font-weight600 text-nowrap">
          {{ "updateSensorTemplateSubInfo" | translate: "Common" }}
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">
          {{ "cancel" | translate: "Common" }}
        </button>
        <button type="button" class="btn btn-primary" (click)="applyTemplate()">
          {{ "save" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  data-backdrop="static"
  data-keyboard="false"
  id="deleteTemplateSettings"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ "confirmation" | translate: "TaskManagement" }}
        </h5>
        <button
          type="button"
          class="close modal-close"
          data-dismiss="modal"
          #closeBtn
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="font-14 font-weight600">
          <span
            *ngIf="
              this.selectedrowSensorData?.TemplateAssociatedSensorCount > 0
            "
          >
            {{ "sensorAssociatedToTemplate" | translate: "Refrigeration" }}
            {{ this.selectedrowSensorData?.TemplateAssociatedSensorCount }}
            {{ "Sensors" | translate: "Refrigeration" }}
          </span>
          <span
            *ngIf="
              this.selectedrowSensorData?.TemplateAssociatedSensorCount === 0
            "
          >
            {{ "areYouSureDelete" | translate: "Refrigeration" }}
          </span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">
          {{ "cancel" | translate: "Common" }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="deleteTemplate()"
          data-dismiss="modal"
          *ngIf="
            this.selectedrowSensorData?.TemplateAssociatedSensorCount === 0
          "
        >
          {{ "delete" | translate: "Common" }}
        </button>
      </div>
    </div>
  </div>
</div>

<p-toast position="bottom-left"></p-toast>
<button
  data-toggle="modal"
  data-target="#deleteModuleModal"
  #viewDeleteModal
  style="display: none"
>
  {{ "delete" | translate: "Common" }}
</button>
<button
  data-toggle="modal"
  data-target="#addModuleModal"
  #viewAddModuleModal
  style="display: none"
>
  {{ "Add Module" | translate: "Common" }}
</button>
<button
  data-toggle="modal"
  data-target="#deleteMapModuleModal"
  #viewdeleteMapModuleModal
  style="display: none"
>
  {{ "delete" | translate: "Common" }}
</button>
