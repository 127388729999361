import {
  Component,
  OnInit,
  ViewChildren,
  ElementRef,
  QueryList,
  ViewChild,
} from "@angular/core";
import { PennService } from "src/app/common/penn.service";
import { HttpService } from "src/app/common/services/http.service";
import { RestApi } from "src/app/common/constants/RestAPI";
import { ActivatedRoute } from "@angular/router";
import * as XLSX from "xlsx";
import { formatDate } from "src/app/common/class/date-filter";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { Constant } from "src/app/common/constants/constant";
import moment from "moment";
import { NotificationTypes } from "src/app/common/constants/enums/instructionEnums";
import {
  ApiListResponse,
  NotificationData,
} from "src/app/common/models/configuration.model";
import {
  DoorGraphData,
  DoorGraphDataProperties,
  DoorGraphResponse,
  DoorPlotBand,
} from "./refrigeration-data.interface";
import { chartOption } from "./refrigiration-chart.defaults";
import { DoorSensorGetDuration } from "./doorSensor-duration-setting";
import { TemperatureSensorGetDuration } from "./temperatureSensor-duration-setting";
import { TranslateService } from "src/app/common/services/translate.service";
import { HttpClient } from "@angular/common/http";
import * as FileSaver from "file-saver";
import { getCurrentYearWeek, Week } from "src/app/common/week";
import {
  DeviceProperty,
  DeviceType,
} from "../refrigeration-list/refrigeration-list.constant";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "src/app/common/services/authentication.service";
import { TemperatureUtil } from "src/app/common/class/temperature-util";
import $ from "jquery";
declare var $: $;

declare var Highcharts: any;

enum SensorKey {
  Water = "3000",
  CO2 = "4000",
  Pressure = "5000",
  Humidity = "1102",
  Door = "0",
  DtTemperature = "0",
}
@Component({
  selector: "app-refrigeration-data",
  templateUrl: "./refrigeration-data.component.html",
  styleUrls: ["./refrigeration-data.component.scss"],
  providers: [DoorSensorGetDuration, TemperatureSensorGetDuration],
})
export class RefrigerationDataComponent implements OnInit {
  Title = this.translate.data.Refrigeration.Refrigeration;
  SiteID: number;
  UnitID: string;
  GraphData = [];
  sensorData = [];
  seriesDataFirstSensor = [];
  seriesDataSecondSensor = [];
  doorGraphChartData = [];
  isVisisble: boolean;
  FromDate: Date = new Date();
  ToDate: Date = new Date();
  ExcelData = [];
  FileName: string = "";
  maxDateValue = new Date();
  yAxisLabel: string;
  chartTitle: string;
  networkIcon: string;
  batteryIcon: string;
  networkStrengthValue: string;
  batteryStrengthValue: string;
  roleAccess = false;
  alarmConfiguration = [];
  notificationListofCabinet;
  doorPlotBand: DoorPlotBand[] = [];
  chartDoorRef = [];
  deviceList;
  cabinetName;
  showEditableField = false;
  toDate;
  toMinDate: Date;
  errorMsg: string = "";
  userProfile;
  isAlarmMuteEnabled = false;
  alarmMuteEndTime;
  isCalibrated = false;
  toolTipMessage = this.translate.data.Refrigeration.noCalibrationCerts;
  @ViewChildren("closeBtn", { read: ElementRef }) closeBtn: QueryList<
    ElementRef
  >;

  delay;
  alarmid;
  showEditOption = false;
  @ViewChild("editConfig", { static: true })
  editUnitCofig: any;
  temperatureGraphChart;
  viewdatepicker = false;
  firstloadingHideDatePicker = false;
  sensorUnitofmeasurementTempCelcius = "°C";
  sensorUnitofmeasurementTempFarenhite = "°F";
  sensorUnitofmeasurementDoor = "On/Off";
  sensorUnitofmeasurementHumidity = "%";
  sensorUnitofmeasurementCO2 = "ppm";
  sensorUnitofmeasurementPressure = "hpa";
  morethanoneYaxisPresentFlag = false;
  colorMap = {};
  temperatureGraphcolorsList = [
    "#00C6E2",
    "#E25353",
    "#D6D525",
    "#FA5EE0",
    "#F47721",
  ];
  alarmBandColorShade = ["#e6fcff", "#fce9e9", "#D6D525", "#fdcef5", "#F47721"];
  unitOfMesurement = [];
  WeekNumbers: Week;
  lastweek: any;
  clientID: any;
  cabinetdata: any;
  assetScore: any;
  @ViewChild("toggleRefrigeration", { static: true })
  toggleRefrigerationInput: ElementRef;
  premiseName;
  tokenValid = false;
  showOrHideSubscriptionMenu;
  flagDoorandWaterSensor = false;
  waterSensorAlarmId;
  selectedTemplate;
  templates;
  isTemplateChanged = false;
  sensorKey;
  changecabinetAllowedRoles;
  confirmation;
  currentSensorTemplateName;
  @ViewChild("changetemplateModal", { static: true }) changetemplateModal;
  oldSelectedTemplate;
  currentSensorTemplate;
  disabledConfig = false;
  selectedUnitID;
  CO2SensorFlag = false;
  CO2chartData;
  maximumSensorReadingValue;
  minimumSensorReadingValue;
  selectedSensorEditConfig;
  showMinMax = true;
  currentSensorType;
  sensorlastcommtimestamp;
  pipeSensorFlag;
  pipethreshold;

  constructor(
    private httpService: HttpService,
    public pennService: PennService,
    private activeRoute: ActivatedRoute,
    private doorSensorDuration: DoorSensorGetDuration,
    private translate: TranslateService,
    private temperatureSensorGetDuration: TemperatureSensorGetDuration,
    private httpClient: HttpClient,
    public auth: AuthenticationService
  ) {}

  ngOnInit() {
    this.WeekNumbers = getCurrentYearWeek(true);
    this.lastweek = this.WeekNumbers.userSelectedWeek;
    this.clientID = this.pennService.getStoredData("clientID");
    this.UnitID = this.activeRoute.snapshot.params["id"];
    localStorage.setItem("cabinetId", this.UnitID);
    this.deviceList = this.pennService.getSensorInfo();
    this.navigateToCabinetGraphFromEmail();
    this.getSiteIdFromUnitID();
    this.getTemplatesDatafortheCabinet(this.UnitID);
    this.pennService.showSubNav();
    this.pennService.showSiteSubNav();
    this.userProfile = this.pennService.getStoredObj(
      SessionVariable.userProfile
    );
    this.sensorlastcommtimestamp = this.deviceList[0]?.timestamp;

    this.showEditOption =
      this.userProfile.roleName === "Super Admin" ||
      this.userProfile.roleName === "Client Admin" ||
      this.userProfile.roleName === "Admin Reseller" ||
      this.userProfile.roleName === "Field Engineer";

    if (this.pennService.getStoredData("subscriptionsMenu")) {
      this.showOrHideSubscriptionMenu = JSON.parse(
        this.pennService.getStoredData("subscriptionsMenu")
      );
    }
  }

  selectedItem;
  groups = ["1 Day", "1 Week", "1 Month", "3 Month", "Custom"];
  showCustom = false;

  getSiteIdFromUnitID() {
    this.httpService
      .get(RestApi.SiteDetailsFromCabinetID + "/" + this.UnitID)
      .subscribe(
        (res: any) => {
          this.SiteID = res.Data.SiteID;
          this.loadChart4hrsGraphData();
          this.dtDeviceViewAccess();
          this.getAlarms();
          this.getMuteAlarmSettings();
          this.getCalibrationStatus();

          if (this.showOrHideSubscriptionMenu.IsRefrigerationWidgetEnabled) {
            this.getAssetScore(this.lastweek, this.clientID);
          }
        },
        (error: any) => {}
      );
  }

  getCalibrationStatus() {
    this.httpService
      .get(RestApi.GetCalibrationStatus + "/" + this.UnitID)
      .subscribe(
        (res: any) => {
          if (res.calibrationInfo !== null) {
            this.isCalibrated = res.calibrationInfo[0].isCalibrated;
          }
        },
        (error: any) => {}
      );
  }

  listClick(newValue) {
    this.selectedItem = newValue; // don't forget to update the model here
    this.showCustom = newValue == "Custom" ? !this.showCustom : false;
    this.showGraphForSensors(newValue);
  }

  loadChart4hrsGraphData() {
    this.RenderChartData(
      new Date(
        this.FromDate.setHours(this.FromDate.getHours() - 4)
      ).toUTCString(),
      this.ToDate.toUTCString(),
      0
    );
  }

  RenderChartData(FromDate, ToDate, index) {
    this.httpService
      .get<any>(
        RestApi.get_refrigeration_data +
          "/" +
          this.SiteID +
          "/" +
          this.UnitID +
          "/" +
          FromDate +
          "/" +
          ToDate
      )
      .subscribe((res: any) => {
        if (res.IsSuccess && res.Data) {
          this.sensorData = [];
          this.GraphData = [];
          this.seriesDataFirstSensor = [];
          this.seriesDataSecondSensor = [];
          this.ExcelData = [];
          this.chartTitle = res.Data.description;
          this.cabinetName = this.chartTitle;
          this.premiseName = res.Data.gateway?.premises?.name;
          this.FileName = res.Data.description + ".xlsx";
          this.alarmConfiguration = [];
          this.createAlarmconfigurationSectionData(res, FromDate, ToDate);
          this.sortGraphData(res);
          this.getAllAlarmForUnit(FromDate, ToDate);
        }
      });
  }

  createAlarmconfigurationSectionData(response, fromdate, todate) {
    this.unitOfMesurement = [];
    const sensortData = response.Data.sensors;
    const childSensorData = response.Data.child
      ? response.Data.child.sensors
      : null;
    this.addConfig(sensortData);
    if (childSensorData) {
      this.addConfig(childSensorData, true);
    }

    this.setDeviceList(response.Data);
    this.getAllSensorChartData(fromdate, todate);
    setTimeout(() => {
      if (!this.CO2SensorFlag) {
        this.highChartOptionFormat();
      }
    }, 0);
  }

  addConfig(sensordata, childFlag?) {
    sensordata.forEach((item) => {
      let id = String(item["unit_id"]) + "-" + String(item["key"]);
      this.sensorData[id] = {
        key: item["key"],
        description: this.getUnitDescriptionName(
          item.unit_of_measurement.um_abbreviated,
          item["key"]
        ),
        unit_of_measurement: item.unit_of_measurement.um_abbreviated,
        isChild: childFlag,
      };

      if (
        item.unit_of_measurement.um_variable === "Temperature" ||
        item.unit_of_measurement.um_abbreviated === "%" ||
        item["key"] === SensorKey.CO2 ||
        item["key"] === SensorKey.Pressure
      ) {
        this.alarmConfiguration.push({
          minSensorValue: item?.min_temp
            ? `${Math.round(item?.min_temp * 10) / 10} ${
                item?.unit_of_measurement?.um_abbreviated
              }`
            : "NA",
          maxSensorValue: item?.max_temp
            ? `${Math.round(item?.max_temp * 10) / 10} ${
                item?.unit_of_measurement?.um_abbreviated
              }`
            : "NA",
          alarmdelay: `${Math.round(
            moment.duration({ seconds: item?.alarm_delay_max }).asMinutes()
          )} ${this.translate.data.Refrigeration.minutes}`,
          description: item.sensor_description,
          alarmid: item.id,
          key: item["key"],
          unit_id: item.unit_id,
          isChild: childFlag,
        });
      } else if (
        item["key"] === SensorKey.Water ||
        item.unit_of_measurement.um_variable === "On/Off"
      ) {
        this.alarmConfiguration.push({
          alarmdelay: `${Math.round(
            moment.duration({ seconds: item?.alarm_delay_max }).asMinutes()
          )} ${this.translate.data.Refrigeration.minutes}`,
          description: item.sensor_description,
          alarmid: item.id,
          key: item["key"],
          unit_id: item.unit_id,
          isChild: childFlag,
        });
        if (item["key"] === SensorKey.Water) {
          this.flagDoorandWaterSensor = true;
        }
      }

      this.unitOfMesurement.push(item.unit_of_measurement.um_abbreviated);
    });
  }

  getUnitDescriptionName(unit, key) {
    if (
      unit === this.sensorUnitofmeasurementTempCelcius ||
      unit === this.sensorUnitofmeasurementTempFarenhite
    ) {
      return "Air Temp";
    } else if (unit === this.sensorUnitofmeasurementHumidity) {
      return "Humidity";
    } else if (unit === this.sensorUnitofmeasurementHumidity) {
      return "Humidity";
    } else if (unit === this.sensorUnitofmeasurementDoor && key === "0") {
      return "Door";
    } else if (unit === this.sensorUnitofmeasurementDoor && key === "3000") {
      return "Water";
    } else if (unit === this.sensorUnitofmeasurementCO2) {
      return "CO2";
    } else if (unit === this.sensorUnitofmeasurementPressure) {
      return "Pressure";
    }
  }

  convertBasedOnUnit(temp, unit) {
    if (unit === "°F") {
      return Math.round(((temp * 9) / 5 + 32) * 10) / 10 + " " + unit;
    } else if (unit === "°C") {
      return Math.round(temp * 10) / 10 + " " + unit;
    }
  }

  getAllSensorChartData(fromTime, toTime) {
    if (this.deviceList.length > 0) {
      this.deviceList.forEach((ele, index) => {
        if (
          ele.devicetype === DeviceType.Door ||
          ele.devicetype === DeviceType.Water
        ) {
          this.renderDoorChart(
            new Date(fromTime).getTime(),
            new Date(toTime).getTime(),
            ele.sensorKey,
            ele.unitId,
            index
          );
        }
      });
    }
  }

  setDeviceList(item) {
    this.deviceList = [];
    const parendDevicePresent = item && item.sensors && item.sensors.length > 0;
    if (parendDevicePresent) {
      this.getAllTheDevicePresentOnCabinet(item.sensors);
    }
    const childDevicePresent = item && item.child;
    if (childDevicePresent) {
      this.getAllTheDevicePresentOnCabinet(
        item.child.sensors,
        childDevicePresent
      );
    }
  }

  getAllTheDevicePresentOnCabinet(item, isChildDevicePresent?) {
    item.forEach((ele, index) => {
      this.deviceList.push({
        devicetype: this.getDeviceType(ele),
        sensorKey: ele.key,
        unitId: ele.unit_id,
        description: ele.sensor_description,
      });
      if (isChildDevicePresent) {
        this.getTemplatesDatafortheCabinet(ele.unit_id);
      }
    });
  }

  getDeviceType(item): string {
    const unitofmeasurement = item.unit_of_measurement.um_variable;

    if (item.key === SensorKey.Water) {
      return DeviceType.Water;
    } else if (unitofmeasurement === "On/Off") {
      return DeviceType.Door;
    } else {
      return DeviceType.Temperature;
    }
  }

  sortGraphData(response) {
    const data = response.Data.unitGraphValues.objects.sort((a, b) => {
      if (a.timestamp === b.timestamp) {
        return 0;
      } else {
        return a.timestamp < b.timestamp ? -1 : 1;
      }
    });

    if (this.CO2SensorFlag) {
      this.CO2SensorChartFlow(data);
    } else {
      data.forEach((item) => {
        for (let key in this.sensorData) {
          if (
            this.sensorData[key].unit_of_measurement ===
              this.sensorUnitofmeasurementTempCelcius ||
            this.sensorData[key].unit_of_measurement ===
              this.sensorUnitofmeasurementTempFarenhite
          ) {
            this.setSeriesData(this.seriesDataFirstSensor, item, key);
            this.setExcelData(item, key);
          } else if (
            this.sensorData[key].unit_of_measurement ===
            this.sensorUnitofmeasurementHumidity
          ) {
            this.setSeriesData(this.seriesDataSecondSensor, item, key);
            this.setExcelData(item, key);
          }
        }
      });
    }
  }

  CO2SensorChartFlow(data) {
    let CO2chartData = new Array([], [], [], []);

    data.forEach((item) => {
      for (let key in item.readings) {
        if (key === SensorKey.DtTemperature) {
          CO2chartData[0].push({
            x: new Date(item.timestamp * 1000),
            y: item.readings[key],
            name: "Temperature",
          });
        } else if (key === SensorKey.Humidity) {
          CO2chartData[1].push({
            x: new Date(item.timestamp * 1000),
            y: item.readings[key],
            name: "Humidity",
          });
        } else if (key === SensorKey.CO2) {
          CO2chartData[2].push({
            x: new Date(item.timestamp * 1000),
            y: item.readings[key],
            name: "CO2",
          });
        } else if (key === SensorKey.Pressure) {
          CO2chartData[3].push({
            x: new Date(item.timestamp * 1000),
            y: item.readings[key] * 0.01,
            name: "Pressure",
          });
        }
      }
    });

    this.CO2chartData = [...CO2chartData];
  }

  setSeriesData(result, item, key) {
    // `${moment(new Date(item.timestamp * 1000)).format(
    //     "YYYY-MM-DD HH:mm:ss"
    //   )} (UTC)`
    result.push({
      x: new Date(item.timestamp * 1000),
      y: Number(item.localized_readings[this.sensorData[key].key]),
      name: this.sensorData[key].description,
    });
  }

  setExcelData(item, key) {
    this.ExcelData.push({
      DateTime: formatDate(new Date(item.timestamp * 1000)),
      Value: Number(item.localized_readings[this.sensorData[key].key]),
      Sensor: this.sensorData[key].description,
      "UOM Type": this.sensorData[key].unit_of_measurement,
    });
  }

  highChartOptionFormat() {
    let count = 0;
    for (let key in this.sensorData) {
      if (
        this.sensorData[key].unit_of_measurement ===
          this.sensorUnitofmeasurementTempCelcius ||
        this.sensorData[key].unit_of_measurement ===
          this.sensorUnitofmeasurementTempFarenhite
      ) {
        this.setGraphData(key, this.seriesDataFirstSensor, count);
      } else if (
        this.sensorData[key].unit_of_measurement ===
        this.sensorUnitofmeasurementHumidity
      ) {
        this.morethanoneYaxisPresentFlag = true;
        this.setGraphData(key, this.seriesDataSecondSensor, count);
      }
    }
  }

  setGraphData(key, data, index) {
    let keydata = this.sensorData[key];
    let obj = data.filter(function (v) {
      return v.name === keydata.description;
    });
    obj.sort();

    this.GraphData.push({
      name: keydata.description,
      type: "line",
      showInLegend: true,
      labels: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: keydata.unit_of_measurement,
      },
      yAxis:
        keydata.description === "Current" ||
        keydata.unit_of_measurement === this.sensorUnitofmeasurementHumidity
          ? 1
          : 0,
      data: obj,
    });

    this.getyaxisUnit();
    this.RenderChart(index);
  }

  getyaxisUnit() {
    let Current = this.sensorData.filter(function (val) {
      return val.description == "Current";
    });
    this.isVisisble = Current.length > 0;
    Current = this.sensorData.filter(function (val) {
      return val.description != "Current";
    });
    this.yAxisLabel = Current.length > 0 ? Current[0].unit_of_measurement : "";
  }

  RenderChart(index) {
    let self = this;

    Highcharts.setOptions({
      global: {
        useUTC: false,
      },
      labels: {
        style: {
          color: "#3E576F",
          position: "absolute",
        },
      },
      chart: {
        borderColor: "#4572A7",
        borderRadius: 0,
        plotBorderColor: "#C0C0C0",
        reflow: true,
        spacing: [10, 10, 15, 10],
        width: null,
        zoomType: "x",
      },
      series: [],
      colors: this.temperatureGraphcolorsList,
      tooltip: {
        animation: true,
        xDateFormat: "%Y-%m-%d",
        backgroundColor: "rgba(249, 249, 249, .85)",
        borderColor: "#00539e",
        borderRadius: 3,
        borderWidth: 1,
        crosshairs: true,
        enabled: true,
        followTouchMove: true,
        formatter: function () {
          return (
            `<span style="color:${this.color}">●</span> ${this.key}: <b>${this.y}</b><br/>` +
            `             ${moment(new Date(this.x)).format(
              "YYYY-MM-DD HH:mm UTC ZZ"
            )} 
            `
          );
        },
        footerFormat: "",
        headerFormat: null, //'<span style="font-size: 10px">{point.key}</span><br/>',
        pointFormat:
          '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>{point.x}',
        shadow: false,
        shared: true,
        snap: 10,
        useHTML: true,
        dateTimeLabelFormats: {
          day: "%A, %b %e, %Y",
          hour: "%A, %b %e, %H:%M",
          millisecond: "%A, %b %e, %H:%M:%S.%L",
          minute: "%A, %b %e, %H:%M",
          month: "%B %Y",
          second: "%A, %b %e, %H:%M:%S",
          week: "Week from %A, %b %e, %Y",
          year: "%Y",
        },
        style: {
          color: "#888",
          cursor: "default",
          fontSize: "9pt",
          fontWeight: "bold",
          padding: "8px",
          pointerEvents: "none",
          whiteSpace: "nowrap",
        },
      },
      plotOptions: {
        animation: true,
        series: {
          connectNulls: true,
          dataGrouping: {
            enabled: false,
          },
          marker: {
            enabled: false,
          },
        },
        line: {
          allowPointSelect: false,
          animation: { duration: 1000 },
          cropThreshold: 300,
          pointRange: 0,
          showCheckbox: false,
          softThreshold: true,
          states: {
            hover: {
              lineWidthPlus: 1,
              halo: {
                opacity: 0.25,
                size: 10,
              },
              marker: {},
            },
            select: { marker: {} },
          },

          turboThreshold: 100000,
        },
      },
      loading: {
        labelStyle: {
          fontWeight: "bold",
          position: "relative",
          top: "45%",
        },
        style: {
          backgroundColor: "white",
          opacity: 0.5,
          position: "absolute",
          textAlign: "center",
        },
      },
      lang: {
        loading: "Loading...",
        months: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      },
    });
    const temperatureGraphYaxis = {
      title: {
        text: this.translate.data.moduleInstructionType.Temperature,
      },
      labels: {
        showLastLabel: false,
        format: "{value}" + this.yAxisLabel,
      },
    };
    const humdityGraphYaxis = {
      title: {
        text: this.translate.data.Refrigeration.humidity,
      },
      labels: {
        format: "{value}" + ` ${this.sensorUnitofmeasurementHumidity}`,
      },
      opposite: true,
    };

    let temperatureChartConfig = Highcharts.chart(`container${index}`, {
      chart: {
        selectionMarkerFill: "none",
      },
      title: {
        text: null,
      },
      legend: {
        layout: "horizontal",
        itemMarginTop: 5,
      },
      exporting: {
        enabled: false,
      },
      xAxis: {
        type: "datetime",
        zoomEnabled: true,
        labels: {
          rotation: -45,
        },
        plotBands: [],
      },
      yAxis: this.morethanoneYaxisPresentFlag
        ? [temperatureGraphYaxis, humdityGraphYaxis]
        : [temperatureGraphYaxis],
      tooltip: {
        enabled: true,
        crosshairs: true,
      },
      credits: {
        enabled: false,
      },
      series: this.GraphData,
    });

    this.temperatureGraphChart = temperatureChartConfig;

    this.clearDiv();
  }
  getAssetScore(week, clientId) {
    let url =
      RestApi.RefrigerationURL +
      "refrigeration/cabinetAssetScore?clientID=" +
      clientId +
      "&siteID=" +
      this.SiteID +
      "&cabinetID=" +
      this.UnitID +
      "&weekNumber=" +
      week;
    this.httpService.get(url).subscribe((data: any) => {
      this.assetScore = data.assetScore;
    });
  }
  clearDiv() {
    let index = 1;
    let airtemperatureGraphElement: any = document.getElementsByClassName(
      "Air Temp"
    );
    while (index <= this.deviceList.length) {
      if (airtemperatureGraphElement[index]) {
        airtemperatureGraphElement[index].style = "display:none";
      }
      index++;
    }
  }

  renderDoorChart(
    fromtime: number,
    totime: number,
    sensorKey: string,
    unitId: string,
    index: number
  ) {
    this.httpService
      .get<any>(
        RestApi.GetDoorGraphData +
          "/" +
          unitId +
          "/" +
          this.getTimeinFormattedUnix(fromtime) +
          "/" +
          this.getTimeinFormattedUnix(totime) +
          "/" +
          sensorKey
      )
      .subscribe((res: DoorGraphResponse) => {
        this.doorGraphChartData = [];
        this.generateDoorGraphXaxis(fromtime, totime, index);
        if (res.Data.count > 0) {
          this.plotDoorOpenBand(res.Data.objects, index);
        }
      });
  }

  generateDoorGraphXaxis(fromtime, totime, index) {
    let doorGraphData: DoorGraphData[] = [];
    this.getTimeStamp(fromtime, totime, doorGraphData);
    this.doorGraphChartData = [
      {
        showInLegend: false,
        labels: {
          enabled: false,
        },
        tooltip: {
          valueSuffix: "On/Off",
        },

        data: doorGraphData,
      },
    ];

    Highcharts.setOptions(chartOption);
    let chartRef = Highcharts.chart(`container${index}`, {
      chart: {
        type: "spline",
        selectionMarkerFill: "none",
        height: 200,
      },
      title: {
        text: null,
      },
      legend: {
        layout: "horizontal",
        align: "left",
        verticalAlign: "top",
        x: 50,
        y: 0,
        floating: true,
        borderWidth: 0,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
      },
      exporting: {
        enabled: false,
        // buttons: {
        //     contextButton: { menuItems: ["printChart", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"] }
        // }
      },
      xAxis: {
        type: "datetime",
        zoomEnabled: true,
        labels: {
          rotation: -45,
        },
      },
      yAxis: [
        {
          title: {
            text: this.flagDoorandWaterSensor
              ? DeviceProperty.Water
              : DeviceProperty.Door,
          },
          labels: {
            showLastLabel: false,
            format: "{value}" + this.yAxisLabel,
          },
        },
        {
          title: {
            text: "Current",
          },
          labels: {
            format: "{value} A",
          },
          opposite: true,
          visible: this.isVisisble,
        },
      ],
      tooltip: {
        enabled: true,
        crosshairs: true,
      },
      credits: {
        enabled: false,
      },
      series: this.doorGraphChartData,
    });
    this.chartDoorRef[index] = chartRef;
  }

  plotDoorOpenBand(doorGraphData?: DoorGraphDataProperties[], index?: number) {
    let doorPlotBand: DoorPlotBand[] = [];
    let self = this;

    doorGraphData.forEach((ele) => {
      doorPlotBand.push({
        color: "#ffc9d0", // Color value
        from: new Date(ele.started + ".000Z"), // Start of the plot band
        to: ele.ended ? new Date(ele.ended + ".000Z") : new Date(), // End of the plot band
        events: {
          mousemove(e) {
            let x = e.layerX;
            let y = e.layerY;
            let endtime = ele.ended
              ? new Date(ele.ended + ".000Z")
              : new Date();

            let duration =
              new Date(endtime).getTime() -
              new Date(ele.started + ".000Z").getTime();

            self.chartDoorRef[index].annotationTooltip
              ? self.chartDoorRef[index].annotationTooltip.destroy()
              : null;
            self.chartDoorRef[index].annotationTooltip = self.chartDoorRef[
              index
            ].renderer
              .label(
                `<span style="z-index:9999999" class="font-weight:600">Open</span> : ${moment(
                  new Date(ele.started + ".000Z")
                ).format("HH:mm")} - ${moment(endtime).format(
                  "HH:mm"
                )}<br/> <span class="font-weight:600">Duration</span> : ${moment
                  .duration(duration)
                  .asMinutes()
                  .toFixed(0)} minutes`,
                x,
                y,
                "callout"
              )
              .css({
                color: "#626262",
                border: "1px solid #00539e",
              })
              .attr({
                fill: "rgba(255, 249, 250, 0.75)",
                padding: 5,
                zIndex: 10,
                r: 5,
              })
              .add();
          },
        },
      });
    });
    this.chartDoorRef[index].xAxis[0].update({
      plotBands: doorPlotBand,
    });
  }

  plotAlarmData(temperatureAlarmGraphData) {
    let temperaturePlotBand: DoorPlotBand[] = [];
    let self = this;
    temperatureAlarmGraphData.forEach((ele) => {
      temperaturePlotBand.push({
        color: this.colorMap[ele.CabinetID], // Color value
        from: new Date(ele.RaisedOn + " UTC"), // Start of the plot band
        to: ele.DismmisedOn ? new Date(ele.DismmisedOn + " UTC") : new Date(), // End of the plot band
        events: {
          mousemove(e) {
            let x = e.layerX;
            let y = e.layerY;
            let endtime = ele.DismmisedOn
              ? new Date(ele.DismmisedOn + " UTC")
              : new Date();

            let duration =
              new Date(endtime).getTime() -
              new Date(ele.RaisedOn + " UTC").getTime();

            self.temperatureGraphChart.annotationTooltip
              ? self.temperatureGraphChart.annotationTooltip.destroy()
              : null;
            self.temperatureGraphChart.annotationTooltip = self.temperatureGraphChart.renderer
              .label(
                `<span class="font-weight:600">Open</span> : ${moment(
                  new Date(ele.RaisedOn + " UTC")
                ).format("HH:mm")} - ${moment(new Date(endtime)).format(
                  "HH:mm"
                )}<br/> <span class="font-weight:600">Duration</span> : ${moment
                  .duration(duration)
                  .asMinutes()
                  .toFixed(0)} minutes`,
                x,
                y,
                "callout"
              )
              .css({
                color: "#626262",
                border: "1px solid #00539e",
              })
              .attr({
                fill: "rgba(255, 249, 250, 0.75)",
                padding: 5,
                zIndex: 10,
                r: 5,
              })
              .add();
          },
        },
      });
    });

    if (this.temperatureGraphChart) {
      this.temperatureGraphChart.xAxis[0].update({
        plotBands: temperaturePlotBand,
      });
    }
  }

  getTimeStamp(fromtime, totime, doorGraphData): void {
    const days = (totime - fromtime) / (24 * 60 * 60 * 1000);
    const step = this.getDurationStep(days);
    while (fromtime < totime) {
      fromtime = fromtime + step * 60000;
      doorGraphData.push({ x: new Date(fromtime), y: null });
    }
  }

  getDurationStep(days: number): number {
    let step = 15;
    if (days >= 31) {
      step = 150;
    } else if (days >= 21 && days < 31) {
      step = 75;
    } else if (days >= 7 && days < 21) {
      step = 25;
    } else {
      step = 15;
    }
    return step;
  }

  updateCabinetName() {
    const data = {
      UnitId: this.UnitID,
      UnitDesc: this.cabinetName,
    };
    this.httpService
      .post(RestApi.RenameCabinet, data)
      .subscribe((res: DoorGraphResponse) => {
        this.showEditableField = false;
        this.chartTitle = this.cabinetName;
      });
  }

  CustomChartData() {
    const childdeviceList = this.pennService.getSensorInfo();
    const fromDate = this.formatDateWithDelimeter(this.FromDate.toDateString());
    const toDate = this.formatDateWithDelimeter(this.ToDate.toDateString());
    this.getAllAlarmForUnit(fromDate, toDate);
    if (childdeviceList.length > 0) {
      let airTempGraphFlag = false;
      childdeviceList.forEach((ele, index) => {
        if (ele.devicetype === DeviceType.Door) {
          this.renderDoorChart(
            new Date(fromDate).getTime(),
            new Date(toDate).getTime(),
            ele.sensorKey,
            ele.unitId,
            index
          );
        } else if (
          ele.devicetype === DeviceType.Temperature &&
          !airTempGraphFlag
        ) {
          this.RenderChartData(
            fromDate + " 00:00:00 GMT",
            toDate + " 23:59:59 GMT",
            index
          );
          airTempGraphFlag = true;
        }
      });
    }
  }

  ExportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.ExcelData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, this.FileName);
  }

  downloadCalibrationCert() {
    this.httpClient
      .get(RestApi.GetCalibrationStatusReport + "/" + this.UnitID, {
        responseType: "arraybuffer",
      })
      .subscribe((response: any) => {
        var file = new Blob([response], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        let fileName = `${this.UnitID}-CalibrationCert.pdf`;
        FileSaver.saveAs(file, fileName);
      });
  }

  ngOnDestroy() {
    this.pennService.hideSubNav();
    this.pennService.hideSiteSubNav();
  }

  formatDateWithDelimeter(dateString: string) {
    let formatedCopy = dateString.split(" ");
    formatedCopy[0] = formatedCopy[0] + ",";
    return formatedCopy.join(" ");
  }

  showBatteryNetworkValue(batteryValue, networkValue) {
    if (networkValue !== "NA") {
      const networkStrength = parseInt(networkValue);
      if (networkStrength > -65) {
        this.networkStrengthValue = `Good: ${networkStrength} dbm`;
        this.networkIcon = "../../../assets/images/Good signal.png";
      } else if (networkStrength > -75 && networkStrength <= -65) {
        this.networkStrengthValue = `Fair: ${networkStrength} dbm`;
        this.networkIcon = "../../../assets/images/Medium_signal.png";
      } else if (networkStrength <= -75) {
        this.networkStrengthValue = `Bad: ${networkStrength} dbm`;
        this.networkIcon = "../../../assets/images/Bad-Offline signal.png";
      }
    }
    if (batteryValue !== "NA") {
      const batteryStrength = parseInt(batteryValue);
      if (batteryStrength >= 70 && batteryStrength <= 100) {
        this.batteryStrengthValue = `Good: ${batteryStrength}%`;
        this.batteryIcon = "../../../assets/images/high.png";
      } else if (batteryStrength >= 30 && batteryStrength <= 69) {
        this.batteryStrengthValue = `Fair: ${batteryStrength}%`;
        this.batteryIcon = "../../../assets/images/medium.png";
      } else if (batteryStrength >= 0 && batteryStrength <= 29) {
        this.batteryStrengthValue = `Bad: ${batteryStrength}%`;
        this.batteryIcon = "../../../assets/images/low.png";
      }
    }
  }

  dtDeviceViewAccess() {
    const userRole = this.pennService.getStoredObj(SessionVariable.userProfile);
    this.roleAccess =
      userRole.roleName === Constant.Roles.superAdmin ||
      userRole.roleName === Constant.Roles.adminReseller;
    if (this.roleAccess) {
      this.activeRoute.queryParams.subscribe((res) => {
        if (res.networkdetails !== "NA" || res.batterydetails !== "NA") {
          this.showBatteryNetworkValue(res.batterydetails, res.networkdetails);
        }
      });
    }
  }

  getAlarms() {
    this.httpService
      .get(
        RestApi.GetNotificationsforClient +
          "/" +
          this.SiteID +
          "/" +
          NotificationTypes.Alarm
      )
      .subscribe(
        (res: ApiListResponse<NotificationData>) => {
          let allNotification;
          this.notificationListofCabinet = [];
          if (res.IsSuccess && res.Data && res.Data != null) {
            allNotification = res.Data;

            this.notificationListofCabinet = allNotification.filter(
              (ele) => ele.CabinetID === this.UnitID
            );
            //taking only first five
            if (this.notificationListofCabinet.length > 5) {
              this.notificationListofCabinet = this.notificationListofCabinet.slice(
                0,
                5
              );
            }
          } else this.notificationListofCabinet = [];
        },
        (error: any) => {}
      );
  }

  formatDate(date) {
    return moment(date).format("DD-MMM-YYYY");
  }

  formatTime(date) {
    return moment(date).format("hh:mm A");
  }

  getTimeinFormattedUnix(timeInMillisec) {
    return Math.floor(timeInMillisec / 1000).toString();
  }

  showGraphForSensors(duration: string) {
    this.displaySensorsChart(this.deviceList, duration);
  }

  displaySensorsChart(deviceList, duration) {
    let airTempGraphFlag = false;
    deviceList.forEach((ele, index) => {
      if (
        (ele.devicetype === DeviceType.Temperature && !airTempGraphFlag) ||
        ele.devicetype === DeviceType.Water
      ) {
        const {
          fromTemperaturedate,
          toTemperaturedate,
        } = this.temperatureSensorGetDuration.gettemperatureDurationFromToTime(
          duration
        );
        if (fromTemperaturedate && toTemperaturedate) {
          this.RenderChartData(fromTemperaturedate, toTemperaturedate, index);
          airTempGraphFlag = true;
        }
      }
    });
  }

  editCabinetName() {
    this.showEditableField = true;
  }

  saveCabinetName() {
    const cabinetName = this.cabinetName.trim();
    if (cabinetName.length > 0) {
      this.updateCabinetName();
    }
  }

  cancelCabinetName() {
    this.showEditableField = false;
    this.cabinetName = this.chartTitle;
  }

  toDatePicker(date) {
    this.errorMsg = "";
    const diff = new Date(date).getTime() - new Date().getTime();
    if (diff > 1209600000) {
      this.errorMsg = this.translate.data.Refrigeration.twoWeekWarning;
    }
    if (diff < 0) {
      this.errorMsg = this.translate.data.Refrigeration.pastTime;
    }
  }

  getMuteAlarmSettings() {
    this.httpService
      .get(RestApi.GetMuteAlarmConfig + "/" + this.UnitID)
      .subscribe(
        (res: any) => {
          if (res.Data) {
            this.isAlarmMuteEnabled = !res.Data[0].IsAlarmMuteEnabled;
            this.alarmMuteEndTime = new Date(
              res.Data[0].EndTime
            ).toLocaleString();
          } else {
            this.isAlarmMuteEnabled = true;
          }
        },
        (error: any) => {}
      );
  }

  saveMuteAlarmSettings(flag?) {
    const unitIdsList = this.deviceList.map((ele) => ele.unitId);
    let params = {
      UnitIds: unitIdsList,
      IsAlarmMuteEnabled: this.isAlarmMuteEnabled,
      CreatedBy: this.userProfile.userName,
      EndTime: this.toDate ? new Date(this.toDate) : new Date(),
    };
    this.httpService.post(RestApi.SaveMuteAlarmConfig, params).subscribe(
      () => {
        this.getMuteAlarmSettings();
        if (flag === "ok") {
          this.viewdatepicker = false;
        }
        this.closePopup();
      },
      (error: any) => {}
    );
  }

  closePopup() {
    this.closeBtn.toArray().forEach((ele) => {
      ele.nativeElement.click();
    });
  }

  setEditAlarmModalData(data) {
    this.errorMsg = "";
    this.disabledConfig = false;
    this.isTemplateChanged = false;
    this.selectedSensorEditConfig = data;
    this.showMinMax = true;
    this.ondismissSelectTemplateName();
    this.getTemplatesDatafortheCabinet(data.unit_id);
    if (
      (data.description === "Door" || data.description === "door") &&
      data.key === "0"
    ) {
      this.showMinMax = false;
    }

    if (data.key === SensorKey.Water) {
      this.flagDoorandWaterSensor = true;
      //this.getTemplatesDatafortheCabinet(data.unit);
    } else {
      this.flagDoorandWaterSensor = false;
      this.delay =
        +data.alarmdelay
          .replace(this.translate.data.Refrigeration.minutes, "")
          .trim() ?? 0;
      this.maximumSensorReadingValue =
        data.maxSensorValue !== "NA"
          ? this.getSensorValue(data.maxSensorValue)
          : 0;
      this.minimumSensorReadingValue =
        data.minSensorValue !== "NA"
          ? this.getSensorValue(data.minSensorValue)
          : 0;
      this.alarmid = data.alarmid;
    }
  }

  getTemplatesDatafortheCabinet(sensorKey) {
    this.sensorKey = sensorKey;
    const url =
      RestApi.ChangeSensorTemplateInCabinet +
      "/" +
      this.clientID +
      "/" +
      sensorKey;
    this.httpService.get(url).subscribe(
      (res: any) => {
        this.templates = res.Data.otherSensorTemplates;
        this.currentSensorTemplate = res.Data.currentSensorTemplate;
        this.currentSensorTemplateName = res.Data.currentSensorTemplate.Name;
        this.currentSensorType = res.Data.currentSensorTemplate.SensorType;
        this.setCurrentTemplate();
      },
      (error: any) => {}
    );
  }

  setCurrentTemplate() {
    if (this.currentSensorTemplate?.IsSensorTemplateDeleted) {
      this.checkIfAlarmConfigDeleted(this.currentSensorTemplate);
      return;
    }

    const selectedTemplate = this.templates.filter(
      (ele) => ele.Name === this.currentSensorTemplateName
    );

    setTimeout(() => {
      if (this.currentSensorTemplate?.CustomSensorTemplateID === null) {
        this.selectedTemplate = selectedTemplate[0];
        this.checkCO2SensorPresent();
        this.checkPipeSensorPresent();
        this.oldSelectedTemplate = { ...this.selectedTemplate };
      } else {
        this.selectedTemplate = null;
        this.oldSelectedTemplate = null;
        this.checkCO2SensorPresent();
        this.checkPipeSensorPresent();
      }
    }, 0);
  }

  checkCO2SensorPresent() {
    if (this.currentSensorType === "CO2") {
      this.CO2SensorFlag = true;
    }
  }

  checkPipeSensorPresent() {
    if (this.currentSensorType === "Pipe Monitoring") {
      this.pipeSensorFlag = true;
      this.pipethreshold = this.currentSensorTemplate.AlarmMaxTemperature;
      return true;
    } else {
      return false;
    }
  }

  checkIfAlarmConfigDeleted(assignedsensorTemplate) {
    if (assignedsensorTemplate.IsSensorTemplateDeleted) {
      this.selectedTemplate = null;
      this.oldSelectedTemplate = null;
    }
  }

  saveAlarmConfigSettings() {
    this.errorMsg = "";
    if (this.editUnitCofig.form.invalid) {
      this.errorMsg = this.translate.data.Refrigeration.allFieldMandatory;
      return;
    }

    if (
      this.showMinMax &&
      this.minimumSensorReadingValue >= this.maximumSensorReadingValue
    ) {
      this.errorMsg = this.translate.data.Refrigeration.minTempGreaterThanMax;
      return;
    }

    let body = this.setSensorConfigData(this.flagDoorandWaterSensor);
    const url = RestApi.UpdateSensorTemplateInCabinet;
    this.httpService.post(url, body).subscribe(
      () => {
        //this.getTemplatesDatafortheCabinet(this.UnitID);
        this.loadChart4hrsGraphData();
        this.closePopup();
      },
      (error: any) => {}
    );
  }

  checkIfHumiditySensor() {
    if (this.selectedSensorEditConfig) {
      if (this.selectedSensorEditConfig.key === SensorKey.Humidity) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkIfPressureSensor() {
    if (this.selectedSensorEditConfig) {
      if (this.selectedSensorEditConfig.key === SensorKey.Pressure) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkIfCO2Sensor() {
    if (this.selectedSensorEditConfig) {
      if (this.selectedSensorEditConfig.key === "4000") {
        return true;
      } else {
        return false;
      }
    }
  }

  setSensorConfigData(doorandwatersensorFlag) {
    let paramsObj = {};
    paramsObj = {
      ClientID: this.clientID,
      SensorKey: this.sensorKey,
      IsCustomizedParameters: this.isTemplateChanged,
      ModifiedBy: this.userProfile.userName,
      ModifiedByID: this.userProfile.userID,
    };

    if (!this.showMinMax) {
      let obj = {};
      if (this.selectedTemplate) {
        obj = this.selectedTemplate;
      } else {
        obj = this.currentSensorTemplate;
      }

      paramsObj["SensorTemplate"] = {
        ...obj,
        AlarmMinTemperature: 0.0,
        AlarmMaxTemperature: 0.0,
        DoorAlarmDelay: this.delay,
      };
    } else if (this.checkIfHumiditySensor()) {
      let obj = {};
      if (this.selectedTemplate) {
        obj = this.selectedTemplate;
      } else {
        obj = this.currentSensorTemplate;
      }

      paramsObj["SensorTemplate"] = {
        ...obj,
        HumidityMinTemperature: this.minimumSensorReadingValue,
        HumidityMaxTemperature: this.maximumSensorReadingValue,
        HumidityDelay: this.delay,
      };
    } else if (this.checkIfCO2Sensor()) {
      let obj = {};
      if (this.selectedTemplate) {
        obj = this.selectedTemplate;
      } else {
        obj = this.currentSensorTemplate;
      }

      paramsObj["SensorTemplate"] = {
        ...obj,
        MinCO2: this.minimumSensorReadingValue,
        MaxCO2: this.maximumSensorReadingValue,
        CO2Delay: this.delay,
      };
    } else if (this.checkIfPressureSensor()) {
      let obj = {};
      if (this.selectedTemplate) {
        obj = this.selectedTemplate;
      } else {
        obj = this.currentSensorTemplate;
      }

      paramsObj["SensorTemplate"] = {
        ...obj,
        MinPressure: this.minimumSensorReadingValue,
        MaxPressure: this.maximumSensorReadingValue,
        PressureDelay: this.delay,
      };
    } else {
      let obj = {};
      if (this.selectedTemplate) {
        obj = this.selectedTemplate;
      } else {
        obj = this.currentSensorTemplate;
      }

      const [
        maxTempWithType,
        minTempWithType,
      ] = this.convertToCelciusIfTempIsFarenhite(
        this.maximumSensorReadingValue,
        this.minimumSensorReadingValue
      );
      paramsObj["SensorTemplate"] = {
        ...obj,
        AlarmMinTemperature: minTempWithType,
        AlarmMaxTemperature: maxTempWithType,
        AlarmDelay: this.delay,
      };
    }

    return paramsObj;
  }

  convertToCelciusIfTempIsFarenhite(maxTemp, minTemp) {
    if (this.unitOfMesurement.includes("°C")) {
      return [maxTemp, minTemp];
    } else if (this.unitOfMesurement.includes("°F")) {
      return [
        this.farenhiteToCelcius(maxTemp),
        this.farenhiteToCelcius(minTemp),
      ];
    } else {
      return [maxTemp, minTemp];
    }
  }

  farenhiteToCelcius(temp) {
    const celcius = (temp - 32) / 1.8;
    return parseFloat(celcius.toFixed(2));
  }

  getSensorValue(data) {
    let tempFarenhite = data.split(" ").includes("°F");
    let tempCelcius = data.split(" ").includes("°C");
    let humidityValue = data.split(" ").includes("%");
    let pressureValue = data.split(" ").includes("hPa");
    let co2Value = data.split(" ").includes("ppm");
    if (tempFarenhite) {
      return +data.replace("°F", "").trim();
    } else if (tempCelcius) {
      return +data.replace("°C", "").trim();
    } else if (humidityValue) {
      return +data.replace("%", "").trim();
    } else if (pressureValue) {
      return +data.replace("hPa", "").trim();
    } else if (co2Value) {
      return +data.replace("ppm", "").trim();
    }
  }

  checkforDecimal(data) {
    this.delay = data ? Math.floor(data) : null;
    this.isTemplateChanged = true;
  }

  validateMinMaxValues(data, flag) {
    if (!TemperatureUtil.regex.test(data)) {
      if (data && flag === "max") {
        this.maximumSensorReadingValue = TemperatureUtil.removeExtraDecimals(
          this.maximumSensorReadingValue
        );
      } else if (data && flag === "min") {
        this.minimumSensorReadingValue = TemperatureUtil.removeExtraDecimals(
          this.minimumSensorReadingValue
        );
      }
    }
    this.isTemplateChanged = true;
  }

  validateNumber(event) {
    TemperatureUtil.validateNumber(event);
  }

  isFieldInvalid(fieldName: string) {
    const field = this.editUnitCofig.form.controls[fieldName];
    return field && field.touched && field.invalid;
  }

  showDatePicker(event) {
    this.viewdatepicker = !event.target.checked; //reverse condition of showing datepicker on disabled state
    this.toMinDate = new Date();
    if (!this.viewdatepicker) {
      this.saveMuteAlarmSettings();
    }
  }

  getAllAlarmForUnit(fromDate, toDate) {
    const url =
      RestApi.getAllAlarmForUnit +
      `/?unitId=${this.UnitID}&FromDate=${new Date(
        fromDate
      ).toUTCString()}&ToDate=${new Date(toDate).toUTCString()}`;
    this.httpService.get(url).subscribe(
      (res: any) => {
        if (res.Data && res.Data.length > 0) {
          this.plotAlarmData(res.Data);
        }
      },
      (error: any) => {}
    );
  }

  getConfigDetails(data) {
    this.selectedUnitID = data.unit_id;
    //this.deleteAlramConfig(this.alarmid);
  }

  deleteAlramConfig() {
    const url = RestApi.deleteUnitAlarmConfig;
    this.httpService.delete(url, this.selectedUnitID).subscribe(
      (res: any) => {
        this.getTemplatesDatafortheCabinet(this.UnitID);
        this.loadChart4hrsGraphData();
        this.closePopup();
      },
      (error: any) => {}
    );
  }

  closeDatePicker() {
    this.viewdatepicker = false;
    this.isAlarmMuteEnabled = false;
    this.toggleRefrigerationInput.nativeElement.click();
  }

  navigateToCabinetGraphFromEmail() {
    const token = this.auth.getToken();

    if (token) {
      const tokens: any = JSON.parse(atob(token.split(".")[1]));
      this.tokenValid = tokens.exp * 1000 > new Date().getTime();
    }
    if (!this.tokenValid) {
      const ssourl =
        environment.commonAuthUrl +
        "/v1/api/cookie" +
        "?userid=null&redirecturl=" +
        location.origin +
        "/auth-process&appId=penn";
      window.location.href = ssourl;
    }
  }

  onSelectTemplate(event) {
    this.selectedTemplate = event.value;
  }

  onChange(showPopupflag?) {
    if (showPopupflag) {
      $("#changetemplateModal").modal();
    }
  }

  ondismissSelectTemplateName() {
    this.setCurrentTemplate();
  }

  onConfirm() {
    this.isTemplateChanged = false;
    this.updateAlarmConfigFields();
  }

  updateAlarmConfigFields() {
    this.disabledConfig = true;
    this.delay = this.selectedTemplate.AlarmDelay ?? 0;
    let unit = this.unitOfMesurement.includes("°F") ? "°F" : "°C";
    if (this.selectedSensorEditConfig) {
      if (!this.showMinMax) {
        this.delay = this.selectedTemplate.DoorAlarmDelay;
      }
      if (this.checkIfCO2Sensor()) {
        this.maximumSensorReadingValue = this.selectedTemplate.MaxCO2;
        this.delay = this.selectedTemplate.CO2Delay;
        this.minimumSensorReadingValue = this.selectedTemplate.MinCO2;
      } else if (this.checkIfPressureSensor()) {
        this.maximumSensorReadingValue = this.selectedTemplate.MaxPressure;
        this.delay = this.selectedTemplate.PressureDelay;
        this.minimumSensorReadingValue = this.selectedTemplate.MinPressure;
      } else if (this.checkIfHumiditySensor()) {
        this.maximumSensorReadingValue = this.getSensorValue(
          this.convertBasedOnUnit(
            this.selectedTemplate.HumidityMaxTemperature,
            unit
          )
        );
        this.minimumSensorReadingValue = this.getSensorValue(
          this.convertBasedOnUnit(
            this.selectedTemplate.HumidityMinTemperature,
            unit
          )
        );
      } else if (
        this.selectedSensorEditConfig.maxSensorValue
          .split(" ")
          .includes("°C") ||
        this.selectedSensorEditConfig.maxSensorValue.split(" ").includes("°F")
      ) {
        this.maximumSensorReadingValue = this.getSensorValue(
          this.convertBasedOnUnit(
            this.selectedTemplate.AlarmMaxTemperature,
            unit
          )
        );
        this.minimumSensorReadingValue = this.getSensorValue(
          this.convertBasedOnUnit(
            this.selectedTemplate.AlarmMinTemperature,
            unit
          )
        );
      }
    }
  }

  onNoConfirm() {
    this.currentSensorTemplateName = this.oldSelectedTemplate.Name;
    this.setCurrentTemplate();
  }
}
