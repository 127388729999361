import {
  Component,
  Inject,
  Input,
  OnChanges,
  SecurityContext,
  SimpleChanges,
} from "@angular/core";

import { BarChartDefaults } from "./mobileui-co2-humidity-chart.default";
import { ActivatedRoute, Router } from "@angular/router";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { DomSanitizer } from "@angular/platform-browser";
import { CookieService } from "ngx-cookie-service";
import { TranslateService } from "src/app/common/services/translate.service";
import { HttpService } from "src/app/common/services/http.service";
import { RestApi } from "src/app/common/constants/RestAPI";
import { DefaultDeepsObj } from "src/app/common/helperFunction/defaults-deep";
import {
  DeviceProperty,
  DeviceType,
} from "src/app/refrigeration/refrigeration-list/refrigeration-list.constant";
import {
  DoorGraphData,
  DoorGraphDataProperties,
  DoorPlotBand,
} from "src/app/refrigeration/refrigeration-data/refrigeration-data.interface";
import { chartOption } from "src/app/refrigeration/refrigeration-data/refrigiration-chart.defaults";
import moment from "moment";
declare var Highcharts: any;

enum SensorKey {
  Water = "3000",
  CO2 = "4000",
  Pressure = "5000",
  Humidity = "1102",
  Door = "0",
  DtTemperature = "0",
}
@Component({
  selector: "mobileui-co2-withdate-humidity-chart",
  templateUrl: "./mobileui-co2-humidity-chart.component.html",
  styleUrls: ["./mobileui-co2-humidity-chart.component.scss"],
  providers: [BarChartDefaults],
})
export class MobileUiCO2ChartWithDateComponent {
  //@Input() chartdata;
  chart;
  @Input() componentName;
  @Input() seriesData;
  @Input() categoriesData;
  seriesConfig = [];
  chartOptions;
  defaultOptions;
  temperatureGraphcolorsList = [
    "#00C6E2",
    "#E25353",
    "#D6D525",
    "#FA5EE0",
    "#F47721",
  ];
  title = ["Air Temp", "Humidity", "CO2", "Pressure", "Pipe"];
  private defaultDeepObj;
  token;
  todate;
  fromdate;
  siteId;
  unitID;
  chartData = [];
  userData;
  userDataCookie;
  userTokenCookie;
  dateKey;
  chartDoorRef = [];
  flagDoorandWaterSensor;
  deviceList = [];
  doorGraphChartData = [];
  yAxisLabel: string;
  isVisisble: boolean;
  chartTitle: string;
  noRecords = "";
  pipethreshold;

  constructor(
    _defaults: BarChartDefaults,
    private translate: TranslateService,
    private activeRoute: ActivatedRoute,
    private httpService: HttpService,
    private http: HttpClient,
    @Inject(LOCAL_STORAGE) private localstorage: StorageService,
    private domSanitizer: DomSanitizer,
    private cookieService: CookieService,
    private router: Router
  ) {
    this.defaultOptions = _defaults.options;
  }

  ngOnInit() {
    this.userTokenCookie = this.cookieService.get("user_token");
    this.localstorage.set("sso.accessToken", this.userTokenCookie);
    this.siteId = this.activeRoute.snapshot.params["siteID"];
    this.unitID = this.activeRoute.snapshot.params["unitID"];
    this.userDataCookie = this.cookieService.get("user_data");
    this.setDataFromQueryParams();
  }

  setDataFromQueryParams() {
    this.activeRoute.queryParams.subscribe((params) => {
      if (params.fromdate && params.todate) {
        this.fromdate = new Date(params.fromdate).toUTCString();
        this.todate = new Date(params.todate).toUTCString();
        this.RenderChartData(this.fromdate, this.todate);
      }
      if (params.dateKey) {
        this.dateKey = params.dateKey;
        let { FromDate, ToDate } = this.getToDate(this.dateKey);
        this.RenderChartData(FromDate, ToDate);
      }
    });
  }

  RenderChartData(FromDate, ToDate) {
    const url =
      RestApi.get_refrigeration_data +
      "/" +
      this.siteId +
      "/" +
      this.unitID +
      "/" +
      FromDate +
      "/" +
      ToDate;

    this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.userTokenCookie,
          CurrentUserData: this.userDataCookie,
        }),
      })
      .subscribe(
        (res: any) => {
          if (res.IsSuccess && res.Data) {
            this.sortGraphData(res, FromDate, ToDate);
          }
        },
        (error) => {}
      );
  }

  getToDate(duration) {
    let FromDate: string;
    let ToDate: string;
    if (duration === "Default") {
      FromDate = new Date(
        new Date().setHours(new Date().getHours() - 4)
      ).toUTCString();
      ToDate = new Date().toUTCString();
    } else if (duration === "1Day") {
      FromDate = new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toUTCString();
      ToDate = new Date().toUTCString();
    } else if (duration === "1Week") {
      FromDate = new Date(
        new Date().setDate(new Date().getDate() - 7)
      ).toUTCString();
      ToDate = new Date().toUTCString();
    } else if (duration === "1Month") {
      FromDate = new Date(
        new Date().setMonth(new Date().getMonth() - 1)
      ).toUTCString();
      ToDate = new Date().toUTCString();
    } else if (duration === "3Month") {
      FromDate = new Date(
        new Date().setMonth(new Date().getMonth() - 3)
      ).toUTCString();
      ToDate = new Date().toUTCString();
    } else if (duration.indexOf("_") > 0) {
      let fromdate;
      let todate;
      if (duration.indexOf("_") > 0) {
        fromdate = duration.split("_")[0] + " 00:00:00 GMT";
        todate = duration.split("_")[1] + " 23:59:59 GMT";
      }

      FromDate = new Date(fromdate).toUTCString();
      ToDate = new Date(todate).toUTCString();
    }

    return {
      FromDate: FromDate,
      ToDate: ToDate,
    };
  }

  getAllTheDevicePresentOnCabinet(item) {
    item.forEach((ele, index) => {
      this.deviceList.push({
        devicetype: this.getDeviceType(ele),
        sensorKey: ele.key,
        unitId: ele.unit_id,
        description: ele.sensor_description,
      });
    });
  }

  getDeviceType(item): string {
    const unitofmeasurement = item.unit_of_measurement.um_variable;

    if (item.key === SensorKey.Water) {
      this.chartTitle = this.translate.data.Commissioning.water;
      return DeviceType.Water;
    } else if (unitofmeasurement === "On/Off") {
      this.chartTitle = this.translate.data.Commissioning.door;
      return DeviceType.Door;
    } else {
      return DeviceType.Temperature;
    }
  }

  sortGraphData(response, fromdate?, todate?) {
    const graphdata = response.Data.unitGraphValues.objects.sort((a, b) => {
      if (a.timestamp === b.timestamp) {
        return 0;
      } else {
        return a.timestamp < b.timestamp ? -1 : 1;
      }
    });

    let chartData = [];
    let noofSensorPresent = response.Data.sensors.length;
    let sensorList = response.Data.sensors;
    let sensorDescription = response.Data.description;
    let childSensor = response.Data?.child?.sensors;
    if (
      sensorList[0].key === SensorKey.Water ||
      sensorList[0].unit_of_measurement.um_variable === "On/Off"
    ) {
      this.getAllTheDevicePresentOnCabinet(sensorList);
      this.getDoorAndWaterChartData(fromdate, todate);
    } else {
      if (childSensor?.length > 0) {
        this.getAllTheDevicePresentOnCabinet(childSensor);
        this.getDoorAndWaterChartData(fromdate, todate);
      }
      let sensorData = {};
      sensorList.forEach((item) => {
        let id = String(item["unit_id"]) + "-" + String(item["key"]);
        sensorData[id] = {
          key: item["key"],
          description: item["sensor_description"],
          unit_of_measurement: item.unit_of_measurement.um_abbreviated,
        };
      });

      for (let i = 0; i < noofSensorPresent; i++) {
        chartData.push([]);
      }

      if (graphdata.length > 0) {
        this.noRecords = "";
        graphdata.forEach((item) => {
          for (let key in sensorData) {
            if (
              (sensorData[key].unit_of_measurement === "°C" ||
                sensorData[key].unit_of_measurement === "°F") &&
              item.readings[sensorData[key].key]
            ) {
              chartData[0].push({
                x: new Date(item.timestamp * 1000),
                y: Number(item.readings[sensorData[key].key]),
                name: this.getSensorType(
                  sensorData[key].key,
                  sensorDescription
                ),
              });
            } else if (
              sensorData[key].unit_of_measurement === "%" &&
              item.readings[sensorData[key].key]
            ) {
              chartData[1].push({
                x: new Date(item.timestamp * 1000),
                y: Number(item.readings[sensorData[key].key]),
                name: this.getSensorType(sensorData[key].key),
              });
            } else if (
              sensorData[key].unit_of_measurement === "ppm" &&
              item.readings[sensorData[key].key]
            ) {
              chartData[2].push({
                x: new Date(item.timestamp * 1000),
                y: Number(item.readings[sensorData[key].key]),
                name: this.getSensorType(sensorData[key].key),
              });
            } else if (
              sensorData[key].unit_of_measurement === "hPa" &&
              item.readings[sensorData[key].key]
            ) {
              chartData[3].push({
                x: new Date(item.timestamp * 1000),
                y: Number(item.readings[sensorData[key].key]),
                name: this.getSensorType(sensorData[key].key),
              });
            }
          }
        });
        this.chartData = [...chartData];

        this.chartData.forEach((item, index) => {
          const [unit, name] = this.getUnitTypeAndName(item);
          let chartConfiguration = {};
          let chartConfig;
          let chartType;
          if (name === "Pipe") {
            chartType = {
              type: "areaspline",
              selectionMarkerFill: "none",
            };
            chartConfig = { chart: chartType };
          } else {
            chartType = {
              type: "spline",
              selectionMarkerFill: "none",
            };
            chartConfig = { chart: chartType };
          }
          chartConfiguration = [
            {
              ...chartConfig,
              //type: "line",
              showInLegend: false,
              labels: {
                enabled: false,
              },
              legend: {
                layout: "horizontal",
                itemMarginTop: 5,
              },
              exporting: {
                enabled: false,
              },
              tooltip: {
                //valueSuffix: unit,
              },
              name: name,
              yAxis: 0,
              data: item,
              color: this.temperatureGraphcolorsList[index],
            },
          ];

          this.renderChart(index, chartConfiguration);
        });
      } else {
        this.noRecords = "No Data to display";
      }
    }
  }

  getSensorType(key, description?) {
    if (key === "0") {
      if (
        description.toLocaleLowerCase().includes("hot") ||
        description.toLocaleLowerCase().includes("cold") ||
        description.toLocaleLowerCase().includes("pipe") ||
        description.toLocaleLowerCase().includes("moinitoring")
      ) {
        this.pipethreshold = description.toLocaleLowerCase().includes("hot")
          ? 50
          : 20;
        return "Pipe";
      } else {
        return "Air Temp";
      }
    } else if (key === "1102") {
      return "Humidity";
    } else if (key === "3000") {
      return "Water";
    } else if (key === "4000") {
      return "CO2";
    } else if (key === "5000") {
      return "Pressure";
    } else {
      return "Air Temp";
    }
  }

  getUnitTypeAndName(item): string[] {
    let type;
    let unit;
    item.forEach((data) => {
      if (data.name === "Air Temp") {
        type = "°C";
        unit = "Air Temp";
      } else if (data.name === "Pipe") {
        type = "°C";
        unit = "Pipe";
      } else if (data.name === "Humidity") {
        type = "%";
        unit = "Humidity";
      } else if (data.name === "CO2") {
        type = "ppm";
        unit = "CO2";
      } else if (data.name === "Pressure") {
        type = "hPa";
        unit = "Pressure";
        data.y = data.y * 0.1;
      }
    });
    return [type, unit];
  }

  getDoorAndWaterChartData(fromTime, toTime) {
    if (this.deviceList.length > 0) {
      this.deviceList.forEach((ele, index) => {
        if (
          ele.devicetype === DeviceType.Door ||
          ele.devicetype === DeviceType.Water
        ) {
          this.renderDoorChart(
            new Date(fromTime).getTime(),
            new Date(toTime).getTime(),
            ele.sensorKey,
            ele.unitId,
            index
          );
        }
      });
    }
  }

  renderDoorChart(
    fromtime: number,
    totime: number,
    sensorKey: string,
    unitId: string,
    index: number
  ) {
    this.httpService
      .get<any>(
        RestApi.GetDoorGraphData +
          "/" +
          unitId +
          "/" +
          this.getTimeinFormattedUnix(fromtime) +
          "/" +
          this.getTimeinFormattedUnix(totime) +
          "/" +
          sensorKey
      )
      .subscribe((res) => {
        this.doorGraphChartData = [];
        this.generateDoorGraphXaxis(fromtime, totime, index);
        if (res.Data.count > 0) {
          this.plotDoorOpenBand(res.Data.objects, index);
        }
      });
  }

  generateDoorGraphXaxis(fromtime, totime, index) {
    let doorGraphData: DoorGraphData[] = [];
    this.getTimeStamp(fromtime, totime, doorGraphData);
    this.doorGraphChartData = [
      {
        showInLegend: false,
        labels: {
          enabled: false,
        },
        tooltip: {
          valueSuffix: "On/Off",
        },

        data: doorGraphData,
      },
    ];

    let container = document.createElement("div");
    container.setAttribute("id", "door-container" + index);

    document.getElementById("chart-container_door").appendChild(container);

    Highcharts.setOptions(chartOption);
    let chartRef = Highcharts.chart(`door-container${index}`, {
      chart: {
        type: "spline",
        selectionMarkerFill: "none",
        height: 200,
      },
      title: {
        text: this.chartTitle,
      },
      legend: {
        layout: "horizontal",
        align: "left",
        verticalAlign: "top",
        x: 50,
        y: 0,
        floating: true,
        borderWidth: 0,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
      },
      exporting: {
        enabled: false,
        // buttons: {
        //     contextButton: { menuItems: ["printChart", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"] }
        // }
      },
      xAxis: {
        type: "datetime",
        zoomEnabled: true,
        labels: {
          rotation: -45,
        },
      },
      yAxis: [
        {
          title: {
            text: this.flagDoorandWaterSensor
              ? DeviceProperty.Water
              : DeviceProperty.Door,
          },
          labels: {
            showLastLabel: false,
            format: "{value}" + this.yAxisLabel,
          },
        },
        {
          title: {
            text: "Current",
          },
          labels: {
            format: "{value} A",
          },
          opposite: true,
          visible: this.isVisisble,
        },
      ],
      tooltip: {
        enabled: true,
        crosshairs: true,
      },
      credits: {
        enabled: false,
      },
      series: this.doorGraphChartData,
    });
    this.chartDoorRef[index] = chartRef;
  }

  plotDoorOpenBand(doorGraphData?: DoorGraphDataProperties[], index?: number) {
    let doorPlotBand: DoorPlotBand[] = [];
    let self = this;

    doorGraphData.forEach((ele) => {
      doorPlotBand.push({
        color: "#ffc9d0", // Color value
        from: new Date(ele.started + ".000Z"), // Start of the plot band
        to: ele.ended ? new Date(ele.ended + ".000Z") : new Date(), // End of the plot band
        events: {
          mousemove(e) {
            let x = e.layerX;
            let y = e.layerY;
            let endtime = ele.ended
              ? new Date(ele.ended + ".000Z")
              : new Date();

            let duration =
              new Date(endtime).getTime() -
              new Date(ele.started + ".000Z").getTime();

            self.chartDoorRef[index].annotationTooltip
              ? self.chartDoorRef[index].annotationTooltip.destroy()
              : null;
            self.chartDoorRef[index].annotationTooltip = self.chartDoorRef[
              index
            ].renderer
              .label(
                `<span style="z-index:9999999" class="font-weight:600">Open</span> : ${moment(
                  new Date(ele.started + ".000Z")
                ).format("HH:mm")} - ${moment(endtime).format(
                  "HH:mm"
                )}<br/> <span class="font-weight:600">Duration</span> : ${moment
                  .duration(duration)
                  .asMinutes()
                  .toFixed(0)} minutes`,
                x,
                y,
                "callout"
              )
              .css({
                color: "#626262",
                border: "1px solid #00539e",
              })
              .attr({
                fill: "rgba(255, 249, 250, 0.75)",
                padding: 5,
                zIndex: 10,
                r: 5,
              })
              .add();
          },
        },
      });
    });
    this.chartDoorRef[index].xAxis[0].update({
      plotBands: doorPlotBand,
    });
  }

  getTimeinFormattedUnix(timeInMillisec) {
    return Math.floor(timeInMillisec / 1000).toString();
  }

  getTimeStamp(fromtime, totime, doorGraphData): void {
    const days = (totime - fromtime) / (24 * 60 * 60 * 1000);
    const step = this.getDurationStep(days);
    while (fromtime < totime) {
      fromtime = fromtime + step * 60000;
      doorGraphData.push({ x: new Date(fromtime), y: null });
    }
  }

  getDurationStep(days: number): number {
    let step = 15;
    if (days >= 31) {
      step = 150;
    } else if (days >= 21 && days < 31) {
      step = 75;
    } else if (days >= 7 && days < 21) {
      step = 25;
    } else {
      step = 15;
    }
    return step;
  }

  getChartType(type) {
    if (type === "Pipe") {
      return "areaspline";
    } else {
      return "spline";
    }
  }

  setYaxisBasedOnSensorType(type) {
    if (type === "Pipe") {
      return {
        plotLines: [
          {
            id: "PlotLinesLevel1",
            color: "red",
            width: 1,
            value: this.pipethreshold,
            zIndex: 1,
            dashStyle: "longdashdot",
          },
        ],
      };
    }
  }

  setPlotOptionBasedOnSensorType(type) {
    if (type === "Pipe") {
      return {
        animation: true,
        series: {
          connectNulls: true,
          dataGrouping: {
            enabled: false,
          },
          marker: {
            enabled: false,
          },
          zones: [
            {
              value: this.pipethreshold,
              color: "#a8ccec",
            },
            {
              color: "#f0ba1e",
            },
          ],
          //threshold: -12.9,
        },
      };
    } else {
      return {
        animation: true,
        series: {
          connectNulls: true,
          dataGrouping: {
            enabled: false,
          },
          marker: {
            enabled: false,
          },
        },
      };
    }
  }

  renderChart(index, seriesdata) {
    function syncExtremes(e) {
      const thisChart = this.chart;

      if (e.trigger !== "syncExtremes") {
        // Prevent feedback loop
        Highcharts.each(Highcharts.charts, function (chart) {
          if (chart !== thisChart) {
            if (chart.xAxis[0].setExtremes) {
              // It is null while updating
              chart.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
                trigger: "syncExtremes",
              });
            }
          }
        });
      }
    }
    let self = this;
    let categories: any = [];
    this.seriesConfig = [];
    this.defaultDeepObj = new DefaultDeepsObj();
    categories = this.categoriesData;

    let container = document.createElement("div");
    container.setAttribute("id", "container" + index);

    //new_row.setAttribute("id", "root_OpenAlarm_Bar_Chart");
    document.getElementById("chart-container").appendChild(container);

    this.chartOptions = {
      chart: {
        type: this.getChartType(seriesdata[0].name),
      },
      time: {
        useUTC: false,
      },
      events: {
        setExtremes: syncExtremes,
      },
      tooltip: {
        enabled: true,
        crosshairs: true,
        formatter: function () {
          return (
            `<span style="color:${this.color}">●</span> ${this.key}: <b>${this.y}</b><br/>` +
            `             ${moment(new Date(this.x)).format(
              "YYYY-MM-DD HH:mm UTC ZZ"
            )}
                    `
          );
        },
      },
      credits: {
        enabled: false,
      },
      yAxis: this.setYaxisBasedOnSensorType(seriesdata[0].name),
      plotOptions: this.setPlotOptionBasedOnSensorType(seriesdata[0].name),
      title: {
        text: seriesdata[0].name,
        fontSize: "18px",
        textAlign: "left",
        textDecoration: "uppercase",
      },
      series: seriesdata,
    };

    this.defaultDeepObj.defaultsDeepObj(this.chartOptions, this.defaultOptions);

    Highcharts.Point.prototype.highlight = function (event) {
      event = this.series.chart.pointer.normalize(event);
      this.onMouseOver(); // Show the hover marker
      this.series.chart.tooltip.refresh(this); // Show the tooltip
      this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };

    ["mousemove"].forEach(function (eventType) {
      document
        .getElementById("container" + index)
        .addEventListener(eventType, function (e) {
          let chart, point, i, event;

          for (i = 4; i < Highcharts.charts.length; i = i + 1) {
            chart = Highcharts.charts[i];
            // Find coordinates within the chart
            event = chart?.pointer?.normalize(e);
            // Get the hovered point
            point = chart?.series[0]?.searchPoint(event, true);

            if (point) {
              point.highlight(e);
            }
          }
        });
    });

    Highcharts.Pointer.prototype.reset = function () {
      return undefined;
    };

    Highcharts.setOptions({
      lang: {
        thousandsSep: "",
      },
    });

    let p = Highcharts.chart("container" + index, this.chartOptions);
  }
}
