import {
  Component,
  Input,
  Output,
  OnChanges,
  OnInit,
  SimpleChanges,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
  FormArray,
  ValidatorFn,
} from "@angular/forms";
import { HttpService } from "../../common/services/http.service";
import { MenuItem } from "primeng/api";
import { HttpClient } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { TaskgridviewComponent } from "../taskgridview/taskgridview.component";
import { ToastMsg } from "src/app/common/constants/toastmsg.constant";
import { PennService } from "src/app/common/penn.service";
import { SessionVariable } from "src/app/common/class/storageLabel";
import { RestApi } from "src/app/common/constants/RestAPI";
import { MessageService } from "primeng/api";
import { TranslateService } from "src/app/common/services/translate.service";
import { Roles } from "src/app/common/constants/enums/instructionEnums";
import { ApiListResponse } from "src/app/common/models/configuration.model";
import { AddSite } from "src/app/common/models/client.model";
import { Constant } from "src/app/common/constants/constant";

@Component({
  providers: [TaskgridviewComponent],
  selector: "app-p-stepper",
  templateUrl: "./p-stepper.component.html",
  styleUrls: ["./p-stepper.component.scss"],
})
export class PStepperComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() data: any;
  @Output() loaddata: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("closepModuleValid") closepModuleValid;
  siteAdminView = false;
  clientAdminView = true;
  userProfile: any;
  currentDate: any = new Date();
  editSelected: boolean = false;
  TaskData: any;
  step: number = 0;
  roles: any = [];
  days: any = [];
  hours: any = [];
  selectedRole: any;
  modules: any = [];
  selectedModule: any;
  regions: any = [];
  selectedReminders: any;
  selectedNotifications: any;
  sites: any = [];
  selectedSite: any;
  priority: string;
  filteredWeeks: any = [];
  filteredMonths: any = [];
  notifications: any = [];
  selectedType: string;
  items: MenuItem[];
  submitted: boolean;
  submittedStep2: boolean;
  submittedStep3: boolean;
  taskConfirmation: boolean = false;
  maxValueD: number;
  maxValueM: number;
  minDate: any;
  minStartDate: Date;
  step2Form = this.fb.group({
    dateSelectionType: ["ONETIME", Validators.required],
    dynamicForm: new FormArray([]),
  });
  step1Form = this.fb.group({
    taskName: ["", Validators.required],
    description: ["", Validators.required],
    assignTo: ["6", Validators.required],
    module: ["", Validators.required],
    region: [""],
    site: [""],
    priority: ["", Validators.required],
  });
  selectedWeeks: string[];
  selectedMonths: any[];
  selectedNotificationType: string;
  onCompletetion: boolean = false;
  whenOverDue: boolean = false;
  weeks: any[] = [
    { name: "MONDAY", checked: false },
    { name: "TUESDAY", checked: false },
    { name: "WEDNESDAY", checked: false },
    { name: "THURSDAY", checked: false },
    { name: "FRIDAY", checked: false },
    { name: "SATURDAY", checked: false },
    { name: "SUNDAY", checked: false },
  ];
  months: any[] = [
    { name: "JANUARY", checked: false },
    { name: "FEBRUARY", checked: false },
    { name: "MARCH", checked: false },
    { name: "APRIL", checked: false },
    { name: "MAY", checked: false },
    { name: "JUNE", checked: false },
    { name: "JULY", checked: false },
    { name: "AUGUST", checked: false },
    { name: "SEPTEMBER", checked: false },
    { name: "OCTOBER", checked: false },
    { name: "NOVEMBER", checked: false },
    { name: "DECEMBER", checked: false },
  ];
  users: any = [];
  remember: any = [];
  selectedUser: any = [];
  rolesSelected: any = [];
  labelName: string;
  editData: any;
  regionids: any = [];
  siteids: any = [];
  tasksiteareaID: any = [];
  monthDifference: number;
  rolesArr: { id: number; name: string }[];
  roleName: string;
  roleAreaID: number;
  Arearegions: any = [];
  taskSuccess: boolean = false;
  minDateFrom: Date;
  minDueDate: Date;
  clientID: any;
  roleId: Number;
  errorMessage: any;
  minDateOneTime: Date;
  notifyRoles: any = [];
  maxDate: any;
  usersUlr: any;
  regionsArray: any;
  siteID: any;
  showConfirmation: boolean;
  setminStartDate: Date;
  seletedModule: any;
  ModuleText: string;
  popUpModule: any;
  selectedModuleVal: any;
  moduleName: any;
  regionsListInfo: any = [];
  siteListInfo: any = [];
  siteList: AddSite[];
  siteName: string;
  areaList: any;
  areaName: any;

  constructor(
    private pennservice: PennService,
    private TaskgridviewComponent: TaskgridviewComponent,
    private fb: FormBuilder,
    private apiService: HttpService,
    private http: HttpClient,
    private _date: DatePipe,
    private messageService: MessageService,
    private translate: TranslateService
  ) {}
  get f() {
    return this.step2Form.controls;
  }
  get d() {
    return this.f.dynamicForm as FormArray;
  }
  ngOnInit(): void {
    this.userProfile = this.pennservice.getStoredObj(
      SessionVariable.userProfile
    );
    this.roleId = this.userProfile.roleID;
    this.roleName = this.userProfile.roleName;
    this.clientID = this.pennservice.getStoredData("clientID");
    this.siteID = this.userProfile.siteID;
    this.goToStep(0);
    this.rolesArr = [
      { id: 1, name: "Super Admin" },
      { id: 2, name: "Admin Reseller" },
      { id: 3, name: "Client Admin" },
      { id: 4, name: "Regional Manager" },
      { id: 5, name: "Site Manager" },
      { id: 6, name: "Site As User" },
    ];

    this.getLoggedInUser(this.rolesArr);
    this.onSelectionChange();
    this.getAreas();
    this.getSiteData();
    this.items = [
      { label: "Basic Info" },
      { label: "Schedule" },
      { label: "Follow Up" },
    ];
    this.remember = [
      { name: "1 Hour", code: 1 },
      { name: "2 Hours", code: 2 },
      { name: "30 minutes", code: 3 },
    ];

    if (
      this.roleName == "Regional Manager" ||
      this.roleName == "Site Manager"
    ) {
      this.roleAreaID = this.userProfile.areaID;
      this.updateSites(this.roleAreaID);
    }
    if (this.roleName == "Regional Manager") {
      this.step1Form.controls["site"].addValidators(Validators.required);
      this.step1Form.controls["site"].updateValueAndValidity();
    }
    if (
      this.roleName == "Super Admin" ||
      this.roleName == "Admin Reseller" ||
      this.roleName == "Client Admin"
    ) {
      this.step1Form.controls["site"].addValidators(Validators.required);
      this.step1Form.controls["site"].updateValueAndValidity();
      this.step1Form.controls["region"].addValidators(Validators.required);
      this.step1Form.controls["region"].updateValueAndValidity();
    }

    this.updateUsers();

    this.minDateFrom = new Date(this._date.transform(new Date(), "yyyy-MM-dd"));
    this.minStartDate = new Date();
    this.setminStartDate = new Date(
      this._date.transform(new Date(), "yyyy-MM-dd HH:mm")
    );
  }

  getLoggedInUser(rolesArr) {
    rolesArr.forEach((ele) => {
      if (ele.id == 6) {
        this.roles.push(ele);
      }
    });
    rolesArr.forEach((ele) => {
      if (this.roleId == Roles.SuperAdmin) {
        if (ele.id > Roles.AdminReseller && ele.id != 6) {
          this.notifyRoles.push(ele);
        }
      } else {
        if (ele.id >= this.roleId && ele.id != 6) {
          this.notifyRoles.push(ele);
        }
      }
    });
    this.apiService
      .get(RestApi.CommonUrl + "client/modules?clientId=" + this.clientID)
      .subscribe(
        (data: any) => {
          data.forEach((x) => {
            let item = { name: x.moduleName, code: x.moduleId, logo: x.logo };
            this.modules.push(item);
          });
        },
        (error) => {
          console.log(error);
        }
      );

    this.apiService
      .get(RestApi.CommonUrl + "client/areas?clientId=" + this.clientID)
      .subscribe(
        (data: any) => {
          data.forEach((x) => {
            let item = { name: x.areaName, code: x.areaID };
            this.regions.push(item);
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }
  onSubmit() {
    this.submitted = true;
    if (this.step1Form.valid) {
      this.goToStep(1);
    }
  }
  updateUsers() {
    let roles = this.notifyRoles.map((x) => x.id).join(",");
    if (this.roleId == Roles.SuperAdmin) {
      this.usersUlr =
        RestApi.CommonUrl +
        "client/users?clientId=" +
        this.clientID +
        "&roleId=" +
        roles;
    } else if (this.roleId == Roles.ClientAdmin) {
      this.usersUlr =
        RestApi.CommonUrl +
        "client/users?clientId=" +
        this.clientID +
        "&roleId=" +
        roles;
    } else if (this.roleId == Roles.AreaManager) {
      this.usersUlr =
        RestApi.CommonUrl +
        "client/users?clientId=" +
        this.clientID +
        "&roleId=" +
        roles +
        "&areaId=" +
        this.roleAreaID;
    } else if (this.roleId == Roles.SiteManager) {
      this.usersUlr =
        RestApi.CommonUrl +
        "client/users?clientId=" +
        this.clientID +
        "&roleId=" +
        roles +
        "&siteId=" +
        this.siteID;
    }
    this.users = [];
    this.apiService.get(this.usersUlr).subscribe(
      (data: any) => {
        data.forEach((x) => {
          let item = { name: x.userName, code: x.userID };
          this.users.push(item);
        });
      },
      (error) => {
        console.log(error);
        this.errorMessage = error;
      }
    );
  }

  displayStyle = "none";
  addModule(selectedModuel) {
    if (this.roleId == Roles.SiteManager) {
      let url;
      url = RestApi.site_module_list + "/" + this.siteID;
      this.apiService.get(url).subscribe(
        (data: any) => {
          let moduleIndex = data.Data.moduleList.findIndex(
            (module) => module.moduleID === selectedModuel
          );
          console.log(moduleIndex);
          if (moduleIndex == -1) {
            this.modules.forEach((element) => {
              if (element.code == selectedModuel) {
                this.moduleName = element.name;
              }
            });
            this.displayStyle = "block";
            this.ModuleText =
              "The module " +
              this.moduleName +
              " will now be assigned to these stores?";
          } else {
            this.showConfirmation == false;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  updateSites(regions: any) {
    this.step1Form.controls["site"].reset();
    this.sites = [];
    let url;
    if (this.roleId == Roles.AreaManager) {
      url =
        RestApi.CommonUrl +
        "client/clients?clientId=" +
        this.clientID +
        "&areaId=" +
        regions;
    } else if (
      (this.type == "EDIT" && !regions.value) ||
      (this.type == "CLONE" && !regions.value)
    ) {
      url =
        RestApi.CommonUrl +
        "client/clients?clientId=" +
        this.clientID +
        "&areaId=" +
        regions;
    } else {
      if (this.roleId != Roles.SiteManager && regions.value.length >= 1) {
        this.regionsArray = regions.value;
        url =
          RestApi.CommonUrl +
          "client/clients?clientId=" +
          this.clientID +
          "&areaId=" +
          this.regionsArray;
      } else if (
        this.roleId != Roles.SiteManager &&
        regions.value.length == 0
      ) {
        url = null;
        this.sites = [];
      }
    }
    if (url) {
      this.apiService.get(url).subscribe(
        (data: any) => {
          let item;
          data.forEach((x) => {
            item = {
              name: x.siteName,
              code: x.siteId,
              areaid:
                this.roleId != Roles.AreaManager &&
                regions.value &&
                regions.value.length > 1
                  ? x.areaIds[0]
                  : this.roleId == Roles.AreaManager ||
                    this.type == "EDIT" ||
                    this.type == "CLONE"
                  ? regions
                  : regions.itemValue,
            };
            if (
              !this.sites.find(
                (i) => i.name == item.name && i.code == item.code
              )
            ) {
              this.sites.push(item);
            }
          });
          setTimeout(() => {
            if (
              (this.type == "EDIT" && !regions.value) ||
              (this.type == "CLONE" && !regions.value)
            )
              this.step1Form.controls["site"].setValue(this.siteids);
          }, 600);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  UpdateWeekCheckboxes() {
    let startDate = new Date(
      this._date.transform(
        this.d.controls[0].get("wStartDate").value,
        "yyyy-MM-dd"
      )
    );
    let endDate = new Date(
      this._date.transform(
        this.d.controls[0].get("wDueDate").value,
        "yyyy-MM-dd"
      )
    );
    let currentDate = new Date(startDate);
    let days = [];
    while (currentDate <= endDate) {
      days.push(currentDate.getDay() == 0 ? 6 : currentDate.getDay() - 1);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    this.d.controls[0].get("weeksChkbox")["controls"].map((week, i) => {
      if (!days.includes(i)) {
        week.disable();
        week.updateValueAndValidity();
      } else {
        week.enable();
        week.updateValueAndValidity();
      }
    });
    this.selectedWeeks = this.d.controls[0]
      .get("weeksChkbox")
      ["controls"].map((week, i) => {
        return week.value && this.weeks[i].name;
      });
    this.filteredWeeks = this.selectedWeeks.filter(Boolean);
  }

  //Function to disable Months
  getMonthsBetweenDates() {
    let startDate = new Date(this.d.controls[0].get("yStartDate").value);
    let endDate = new Date(this.d.controls[0].get("yDueDate").value);
    let days = [];
    let currentMonth = startDate.getMonth();
    let dueMonth = endDate.getMonth();
    if (currentMonth < dueMonth) {
      for (let i = currentMonth; i <= dueMonth; i++) {
        days.push(i);
      }
    } else {
      for (let i = currentMonth; i <= 11; i++) {
        days.push(i);
      }
      for (let i = 0; i <= dueMonth; i++) {
        days.push(i);
      }
    }
    this.d.controls[0].get("monthsChkbox")["controls"].map((month, i) => {
      if (!days.includes(i)) {
        month.disable();
        month.updateValueAndValidity();
      } else {
        month.enable();
        month.updateValueAndValidity();
      }
    });
    this.getSelectedMonths();
  }
  displaypopUP = "block";
  closepopUp() {
    this.displayStyle = "none";
  }
  resetModule() {
    this.step1Form.controls["module"].reset();
    this.closepModuleValid.nativeElement.click();
  }
  closeModuleValie() {
    this.closepModuleValid.nativeElement.click();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.roleId == Roles.AreaManager) {
      this.updateSites(this.roleAreaID);
    }

    if (this.roleId != Roles.AreaManager) {
      this.sites = [];
    }
    this.step1Form.reset();
    this.step2Form.reset();
    this.submitted = false;
    this.goToStep(0);
    this.editSelected = false;
    this.step1Form.controls["taskName"].enable();
    this.step1Form.controls["description"].enable();
    this.step1Form.controls["assignTo"].enable();
    this.step1Form.controls["module"].enable();
    this.step1Form.controls["priority"].enable();
    this.step1Form.controls["assignTo"].setValue(6);
    if (this.type == "CREATE") {
      this.labelName = "CREATE TASK";
      this.selectedUser = [];
      this.rolesSelected = [];
      this.whenOverDue = false;
      this.onCompletetion = false;
      this.selectedReminders = null;
    }
    if (this.type == "EDIT") {
      this.labelName = "EDIT TASK";
      this.editSelected = true;
      this.step1Form.controls["taskName"].disable();
      this.step1Form.controls["description"].disable();
      this.step1Form.controls["assignTo"].disable();
      this.step1Form.controls["module"].disable();
      this.step1Form.controls["priority"].disable();
    }
    if (this.type == "CLONE") {
      this.labelName = "CLONE TASK";
    }
    if (this.type && this.data) {
      this.apiService
        .get(RestApi.TaskManagementURL + "taskdefinition/" + this.data)
        .subscribe((response: any) => {
          this.editData = response;
          this.regionids = [];
          this.siteids = [];

          this.editData.areas.forEach((ele) => {
            this.regionids.push(ele.areaId);
          });

          this.editData.sites.forEach((ele) => {
            this.siteids.push(ele.siteId);
          });

          this.step1Form.patchValue({
            taskName:
              this.type == "CLONE"
                ? "Clone of " + this.editData.taskname
                : this.editData.taskname,
            description: this.editData.taskdescription,
            module: this.editData.moduleid,
            region: this.regionids,
            site: this.siteids,
            priority: this.editData.taskpriorityid.toString(),
          });
          this.step1Form.controls["assignTo"].setValue(6);
          this.updateSites(this.regionids);
          this.updateUsers();

          switch (this.editData.frequencytype) {
            case "ONETIME": {
              this.selectedType = "ONETIME";
              this.f.dateSelectionType.setValue(this.editData.frequencytype);
              this.onSelectionChange();
              let startDate =
                this.editData.startdate + " " + this.editData.starttime;
              let endDate = this.editData.duedate + " " + this.editData.duetime;
              if (this.type == "CLONE" || "EDIT") {
                if (new Date(startDate) >= new Date()) {
                  this.d.controls[0]
                    .get("dateTime")
                    .setValue(new Date(startDate));
                  this.d.controls[0]
                    .get("dueDateTime")
                    .setValue(new Date(endDate));
                } else {
                  this.d.controls[0].get("dateTime").setValue(new Date());
                  this.d.controls[0].get("dueDateTime").setValue(new Date());
                }
                this.minDueDate = this.d.controls[0].get("dateTime").value;
              }
              break;
            }
            case "DAILY": {
              this.selectedType = "DAILY";
              this.f.dateSelectionType.setValue(this.editData.frequencytype);
              this.onSelectionChange();
              this.d.controls[0]
                .get("dStartDate")
                .setValue(
                  new Date(this.editData.startdate) >= new Date()
                    ? new Date(this.editData.startdate)
                    : new Date()
                );

              this.d.controls[0]
                .get("dDueDate")
                .setValue(
                  new Date(this.editData.duedate) >= new Date()
                    ? new Date(this.editData.duedate)
                    : new Date()
                );
              this.dateChange();
              this.d.controls[0]
                .get("dEvery")
                .setValue(this.editData.frequencyinterval);
              this.d.controls[0]
                .get("dTimeRange")
                .setValue(this.editData.timerangeselected);
              if (
                this.editData.startdate ==
                this._date.transform(new Date(), "yyyy-MM-dd")
              ) {
                if (
                  this.editData.starttime >=
                  this._date.transform(new Date(), "HH:mm")
                ) {
                  this.d.controls[0]
                    .get("dTimeFrom")
                    .setValue(
                      new Date(`2000-01-01 ${this.editData.starttime}`)
                    );
                  this.d.controls[0]
                    .get("dTimeTill")
                    .setValue(new Date(`2000-01-01 ${this.editData.duetime}`));
                }
              } else {
                this.d.controls[0]
                  .get("dTimeFrom")
                  .setValue(new Date(`2000-01-01 ${this.editData.starttime}`));
                this.d.controls[0]
                  .get("dTimeTill")
                  .setValue(new Date(`2000-01-01 ${this.editData.duetime}`));
              }
              this.timeChange();
              this.d.controls[0]
                .get("dEvery1")
                .setValue(this.editData.everyinputduration);
              break;
            }
            case "WEEKLY": {
              this.selectedType = "WEEKLY";
              this.f.dateSelectionType.setValue(this.editData.frequencytype);
              let weekly = this.editData.weekdays;
              this.weeks.forEach((week) => {
                if (weekly.includes(week.name)) {
                  week.checked = true;
                }
              });
              this.onSelectionChange();
              this.d.controls[0]
                .get("wStartDate")
                .setValue(
                  new Date(this.editData.startdate) >= new Date()
                    ? new Date(this.editData.startdate)
                    : new Date()
                );
              this.d.controls[0]
                .get("wDueDate")
                .setValue(
                  new Date(this.editData.duedate) >= new Date()
                    ? new Date(this.editData.duedate)
                    : new Date()
                );
              if (
                this.editData.duedate ==
                this._date.transform(new Date(), "yyyy-MM-dd")
              ) {
                if (
                  this.editData.starttime >=
                  this._date.transform(new Date(), "HH:mm")
                ) {
                  this.d.controls[0]
                    .get("wTimeFrom")
                    .setValue(
                      new Date(`2000-01-01 ${this.editData.starttime}`)
                    );
                  this.d.controls[0]
                    .get("wTimeTill")
                    .setValue(new Date(`2000-01-01 ${this.editData.duetime}`));
                }
              } else {
                this.d.controls[0]
                  .get("wTimeFrom")
                  .setValue(new Date(`2000-01-01 ${this.editData.starttime}`));
                this.d.controls[0]
                  .get("wTimeTill")
                  .setValue(new Date(`2000-01-01 ${this.editData.duetime}`));
              }
              this.d.controls[0]
                .get("wTimeRange")
                .setValue(this.editData.timerangeselected);

              this.timeChange();
              this.d.controls[0]
                .get("wEvery")
                .setValue(this.editData.everyinputduration);
              break;
            }
            case "MONTHLY": {
              this.selectedType = "MONTHLY";
              this.f.dateSelectionType.setValue(this.editData.frequencytype);
              this.onSelectionChange();
              this.d.controls[0]
                .get("mStartDate")
                .setValue(
                  new Date(this.editData.startdate) >= new Date()
                    ? new Date(this.editData.startdate)
                    : new Date()
                );
              this.d.controls[0]
                .get("mDueDate")
                .setValue(new Date(this.editData.duedate));
              this.d.controls[0]
                .get("mTimeRange")
                .setValue(
                  new Date(this.editData.duedate) >= new Date()
                    ? new Date(this.editData.duedate)
                    : new Date()
                );
              if (
                this.editData.duedate ==
                this._date.transform(new Date(), "yyyy-MM-dd")
              ) {
                if (
                  this.editData.starttime >=
                  this._date.transform(new Date(), "HH:mm")
                ) {
                  this.d.controls[0]
                    .get("mTimeFrom")
                    .setValue(
                      new Date(`2000-01-01 ${this.editData.starttime}`)
                    );
                  this.d.controls[0]
                    .get("mTimeTill")
                    .setValue(new Date(`2000-01-01 ${this.editData.duetime}`));
                }
              } else {
                this.d.controls[0]
                  .get("mTimeFrom")
                  .setValue(new Date(`2000-01-01 ${this.editData.starttime}`));
                this.d.controls[0]
                  .get("mTimeTill")
                  .setValue(new Date(`2000-01-01 ${this.editData.duetime}`));
              }

              this.timeChange();
              this.d.controls[0]
                .get("mEvery")
                .setValue(this.editData.everyinputduration);
              this.d.controls[0].get("mDay").setValue(this.editData.dayof);
              this.d.controls[0].get("mMonth").setValue(this.editData.monthof);
              break;
            }
            case "YEARLY": {
              this.selectedType = "YEARLY";
              this.f.dateSelectionType.setValue(this.editData.frequencytype);
              let monthly = this.editData.yearly;
              this.months.forEach((month) => {
                if (monthly.includes(month.name)) {
                  month.checked = true;
                }
              });
              this.onSelectionChange();
              this.d.controls[0]
                .get("yStartDate")
                .setValue(
                  new Date(this.editData.startdate) >= new Date()
                    ? new Date(this.editData.startdate)
                    : new Date()
                );
              this.d.controls[0]
                .get("yDueDate")
                .setValue(
                  new Date(this.editData.duedate) >= new Date()
                    ? new Date(this.editData.duedate)
                    : new Date()
                );
              this.d.controls[0]
                .get("yTimeRange")
                .setValue(this.editData.timerangeselected);
              if (
                this.editData.duedate ==
                this._date.transform(new Date(), "yyyy-MM-dd")
              ) {
                if (
                  this.editData.starttime >=
                  this._date.transform(new Date(), "HH:mm")
                ) {
                  this.d.controls[0]
                    .get("yTimeFrom")
                    .setValue(
                      new Date(`2000-01-01 ${this.editData.starttime}`)
                    );
                  this.d.controls[0]
                    .get("yTimeTill")
                    .setValue(new Date(`2000-01-01 ${this.editData.duetime}`));
                }
              } else {
                this.d.controls[0]
                  .get("yTimeFrom")
                  .setValue(new Date(`2000-01-01 ${this.editData.starttime}`));
                this.d.controls[0]
                  .get("yTimeTill")
                  .setValue(new Date(`2000-01-01 ${this.editData.duetime}`));
              }
              this.timeChange();
              this.d.controls[0]
                .get("yEvery")
                .setValue(this.editData.everyinputduration);
              break;
            }
          }
          this.onCompletetion = this.editData.isnotifyoncompletion;
          this.whenOverDue = this.editData.isnotifyonoverdue;
          this.selectedUser = this.editData.userid;
          this.rolesSelected = this.editData.roleid;
          if (this.editData.reminderbeforedue == "") {
            this.selectedReminders = null;
          } else {
            this.selectedReminders = this.editData.reminderbeforedue;
          }
        });
    }
    if (this.type == "EDIT") {
      this.editSelected = true;
      this.step1Form.controls["taskName"].disable();
      this.step1Form.controls["description"].disable();
      this.step1Form.controls["assignTo"].disable();
      this.step1Form.controls["module"].disable();
      this.step1Form.controls["priority"].disable();
    }
  }
  dateChange() {
    if (
      this.d.controls[0].get("dStartDate").value &&
      this.d.controls[0].get("dDueDate").value
    ) {
      this.maxValueD = this.getDateDifference(
        this.d.controls[0].get("dStartDate").value,
        this.d.controls[0].get("dDueDate").value
      );
      if (this.maxValueD == 0 || this.maxValueD == -1) {
        this.maxValueD = 1;
      }
      this.d.controls[0]
        .get("dEvery")
        .addValidators(Validators.maxLength(this.maxValueD));
      this.d.controls[0].get("dEvery").updateValueAndValidity();
    }
  }
  dateChangeM() {
    if (
      this.d.controls[0].get("mStartDate").value &&
      this.d.controls[0].get("mDueDate").value
    ) {
      this.maxValueM = this.getMonthDifference(
        this.d.controls[0].get("mStartDate").value,
        this.d.controls[0].get("mDueDate").value
      );
      this.d.controls[0]
        .get("mMonth")
        .addValidators(Validators.maxLength(this.maxValueM));
      this.d.controls[0].get("mEvery").updateValueAndValidity();
    }
  }

  timeChange() {
    let diff;
    this.hours = [];
    switch (this.f.dateSelectionType.value) {
      case "DAILY": {
        if (
          this.d.controls[0].get("dTimeFrom").value &&
          this.d.controls[0].get("dTimeTill").value
        ) {
          diff = this.getTimeDifference(
            this._date.transform(
              this.d.controls[0].get("dTimeFrom").value,
              "HH:mm"
            ),
            this._date.transform(
              this.d.controls[0].get("dTimeTill").value,
              "HH:mm"
            )
          );
        }
        break;
      }
      case "WEEKLY": {
        if (
          this.d.controls[0].get("wTimeFrom").value &&
          this.d.controls[0].get("wTimeTill").value
        ) {
          diff = this.getTimeDifference(
            this._date.transform(
              this.d.controls[0].get("wTimeFrom").value,
              "HH:mm"
            ),
            this._date.transform(
              this.d.controls[0].get("wTimeTill").value,
              "HH:mm"
            )
          );
        }
        break;
      }
      case "MONTHLY": {
        if (
          this.d.controls[0].get("mTimeFrom").value &&
          this.d.controls[0].get("mTimeTill").value
        ) {
          diff = this.getTimeDifference(
            this._date.transform(
              this.d.controls[0].get("mTimeFrom").value,
              "HH:mm"
            ),
            this._date.transform(
              this.d.controls[0].get("mTimeTill").value,
              "HH:mm"
            )
          );
        }
        break;
      }
      case "YEARLY": {
        if (
          this.d.controls[0].get("yTimeFrom").value &&
          this.d.controls[0].get("yTimeTill").value
        ) {
          diff = this.getTimeDifference(
            this._date.transform(
              this.d.controls[0].get("yTimeFrom").value,
              "HH:mm"
            ),
            this._date.transform(
              this.d.controls[0].get("yTimeTill").value,
              "HH:mm"
            )
          );
        }
        break;
      }
    }
    for (let i = 1; i <= diff; i++) {
      let hr = {
        name: i + " hour",
        code: i,
      };
      this.hours.push(hr);
    }
  }
  timeRangeChanged() {
    switch (this.f.dateSelectionType.value) {
      case "DAILY": {
        if (this.d.controls[0].get("dTimeRange").value) {
          this.d.controls[0].get("dEvery1").addValidators(Validators.required);
          this.d.controls[0].get("dEvery1").updateValueAndValidity();
        } else {
          this.d.controls[0].get("dEvery1").reset();
          this.d.controls[0]
            .get("dEvery1")
            .removeValidators(Validators.required);
          this.d.controls[0].get("dEvery1").updateValueAndValidity();
        }
        break;
      }
      case "WEEKLY": {
        if (this.d.controls[0].get("wTimeRange").value) {
          this.d.controls[0].get("wEvery").addValidators(Validators.required);
          this.d.controls[0].get("wEvery").updateValueAndValidity();
        } else {
          this.d.controls[0].get("wEvery").reset();
          this.d.controls[0]
            .get("wEvery")
            .removeValidators(Validators.required);
          this.d.controls[0].get("wEvery").updateValueAndValidity();
        }
        break;
      }
      case "MONTHLY": {
        if (this.d.controls[0].get("mTimeRange").value) {
          this.d.controls[0].get("mEvery").addValidators(Validators.required);
          this.d.controls[0].get("mEvery").updateValueAndValidity();
        } else {
          this.d.controls[0].get("mEvery").reset();
          this.d.controls[0]
            .get("mEvery")
            .removeValidators(Validators.required);
          this.d.controls[0].get("mEvery").updateValueAndValidity();
        }
        break;
      }
      case "YEARLY": {
        if (this.d.controls[0].get("yTimeRange").value) {
          this.d.controls[0].get("yEvery").addValidators(Validators.required);
          this.d.controls[0].get("yEvery").updateValueAndValidity();
        } else {
          this.d.controls[0].get("yEvery").reset();
          this.d.controls[0]
            .get("yEvery")
            .removeValidators(Validators.required);
          this.d.controls[0].get("yEvery").updateValueAndValidity();
        }
        break;
      }
    }
  }

  setStartTimeValidation(startdateControl, dueDateControl, timeFrom, timeTill) {
    let startDate = new Date(this.d.controls[0].get(startdateControl).value);
    let systemDate = this._date.transform(new Date(startDate), "yyyy-MM-dd");
    let currentDate = this._date.transform(new Date(), "yyyy-MM-dd");
    let dueDate = new Date(this.d.controls[0].get(dueDateControl).value);
    if (startDate != dueDate) {
      this.d.controls[0].get(dueDateControl).setValue(startDate);
    }
    if (systemDate == currentDate) {
      this.d.controls[0].get(timeTill).setValue(new Date());
    }
    this.minStartDate = new Date(startDate) <= new Date() ? new Date() : null;
    if (this.type == "CREATE") {
      this.d.controls[0].get(timeFrom).setValue(new Date());
      this.d.controls[0].get(timeTill).setValue(new Date());
    }
    if (startdateControl == "dateTime")
      this.d.controls[0].get(dueDateControl).setValue(startDate);
  }
  setEndTime(startTimeControl, endTimeControl: any) {
    this.minDate = new Date(this.d.controls[0].get(startTimeControl).value);
    this.maxDate = new Date(this.d.controls[0].get(endTimeControl).value);
    if (this.minDate > this.maxDate) {
      this.d.controls[0].get(endTimeControl).setValue(this.minDate);
      this.timeChange();
    }
  }
  getDateDifference(startDate: Date, dueDate: Date) {
    startDate = new Date(startDate);
    dueDate = new Date(dueDate);
    return Math.floor(
      (dueDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
    ) <= 7
      ? Math.floor(
          (dueDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
        )
      : 7;
  }
  getTimeDifference(startDate: any, dueDate: any) {
    startDate = new Date(`2000-01-01 ${startDate}`);
    dueDate = new Date(`2000-01-01 ${dueDate}`);
    return dueDate.getHours() - startDate.getHours();
  }
  getMonthDifference(StartDate: Date, DueDate: Date) {
    StartDate = new Date(StartDate);
    DueDate = new Date(DueDate);
    return DueDate.getMonth() - StartDate.getMonth();
  }

  onSelectionChange() {
    this.submittedStep2 = false;
    let value = this.f.dateSelectionType.value;
    for (let i = 0; i <= this.d.length; i++) {
      this.d.removeAt(i);
    }
    if (value == "ONETIME") {
      this.minDateOneTime = new Date();
      const onetimeForm = this.fb.group({
        dateTime: ["", Validators.required],
        dueDateTime: ["", Validators.required],
      });
      this.d.push(onetimeForm);
      this.d.controls[0].get("dateTime").setValue(new Date());
      this.d.controls[0].get("dueDateTime").setValue(new Date());
    }
    if (value == "DAILY") {
      const dailyForm = this.fb.group({
        dStartDate: ["", Validators.required],
        dDueDate: ["", Validators.required],
        dEvery: [1, Validators.required],
        dEvery1: [""],
        dTimeFrom: [new Date()],
        dTimeTill: [new Date()],
        dTimeRange: [false],
      });
      this.d.push(dailyForm);
    }
    if (value == "WEEKLY") {
      const weeklyForm = this.fb.group({
        wStartDate: ["", Validators.required],
        wDueDate: ["", Validators.required],
        wEvery: [""],
        weeksChkbox: new FormArray([], this.minSelectedCheckboxes(1)),
        wTimeFrom: [new Date()],
        wTimeTill: [new Date()],
        wTimeRange: [false],
      });
      this.d.push(weeklyForm);
      this.createweekscheckBoxArray(this.weeks);
    }
    if (value == "MONTHLY") {
      const monthlyForm = this.fb.group({
        mStartDate: ["", Validators.required],
        mDueDate: ["", Validators.required],
        mDay: ["", Validators.required],
        mMonth: ["", Validators.required],
        mEvery: [""],
        mTimeFrom: [new Date()],
        mTimeTill: [new Date()],
        mTimeRange: [false],
      });
      this.d.push(monthlyForm);
    }
    if (value == "YEARLY") {
      const yearlyForm = this.fb.group({
        yStartDate: ["", Validators.required],
        yDueDate: ["", Validators.required],
        yEvery: [""],
        monthsChkbox: new FormArray([], this.minSelectedCheckboxes(1)),
        yTimeFrom: [new Date()],
        yTimeTill: [new Date()],
        yTimeRange: [false],
      });
      this.d.push(yearlyForm);
      this.createmonthlycheckBoxArray(this.months);
    }
  }
  saveForm() {
    this.submittedStep3 = true;
    if (this.onCompletetion && this.selectedUser.length == 0) {
      return;
    }
    if (this.whenOverDue && this.rolesSelected.length == 0) {
      return;
    }

    let saveObj = new StepperSave();
    if (this.type == "EDIT") {
      saveObj.taskdefinitionid = this.data;
    } else {
      saveObj.taskdefinitionid = null;
    }
    if (this.type == "CLONE") {
      saveObj.taskname = this.step1Form.controls["taskName"].value;
    } else {
      saveObj.taskname = this.step1Form.controls["taskName"].value;
    }
    saveObj.taskdescription = this.step1Form.controls["description"].value;
    saveObj.clientid = this.clientID;
    saveObj.moduleid = this.step1Form.controls["module"].value;
    let selectedModule = this.modules.find(
      (ele) => ele.code == saveObj.moduleid
    );
    saveObj.modulename = selectedModule.name;
    saveObj.logo = selectedModule.logo;
    saveObj.taskpriorityid = Number(this.step1Form.controls["priority"].value);
    saveObj.assigntorole = this.step1Form.controls["assignTo"].value;
    saveObj.archivetasks = false;
    if (this.roleId != Roles.SiteManager) {
      if (this.roleId == Roles.AreaManager) {
        let regionsInfo = new areas();
        this.regionsListInfo = [];
        regionsInfo.areaId = this.userProfile.areaID;
        regionsInfo.areaName = this.areaName;
        this.regionsListInfo.push(regionsInfo);
        saveObj.areas = this.regionsListInfo;
        this.selectedSiteInfo();
        saveObj.sites = this.siteListInfo;
      } else {
        this.selectedRegionInfo();
        saveObj.areas = this.regionsListInfo;
        this.selectedSiteInfo();
        saveObj.sites = this.siteListInfo;
      }
    } else {
      let regionsInfo = new areas();
      this.regionsListInfo = [];
      regionsInfo.areaId = this.userProfile.areaID;
      regionsInfo.areaName = this.areaName;
      this.regionsListInfo.push(regionsInfo);
      saveObj.areas = this.regionsListInfo;
      let sitesInfo = new sites();
      this.siteListInfo = [];
      sitesInfo.siteId = this.userProfile.siteID;
      sitesInfo.sitename = this.siteName;
      this.siteListInfo.push(sitesInfo);
      saveObj.sites = this.siteListInfo;
    }
    if (this.f.dateSelectionType.value == "ONETIME") {
      saveObj.frequencytype = this.f.dateSelectionType.value;
      saveObj.starttime = this._date.transform(
        this.d.controls[0].get("dateTime").value,
        "HH:mm"
      );
      saveObj.startdate = this._date.transform(
        this.d.controls[0].get("dateTime").value,
        "yyyy-MM-dd"
      );
      saveObj.duetime = this._date.transform(
        this.d.controls[0].get("dueDateTime").value,
        "HH:mm"
      );
      saveObj.duedate = this._date.transform(
        this.d.controls[0].get("dueDateTime").value,
        "yyyy-MM-dd"
      );
    }
    if (this.f.dateSelectionType.value == "DAILY") {
      (saveObj.frequencytype = this.f.dateSelectionType.value),
        (saveObj.frequencyinterval = this.d.controls[0].get("dEvery").value);

      saveObj.startdate = this._date.transform(
        this.d.controls[0].get("dStartDate").value,
        "yyyy-MM-dd"
      );
      saveObj.duedate = this._date.transform(
        this.d.controls[0].get("dDueDate").value,
        "yyyy-MM-dd"
      );
      if (this.type == "CREATE") {
        saveObj.starttime = this._date.transform(
          this.d.controls[0].get("dTimeFrom").value,
          "HH:mm"
        );
        saveObj.duetime = this._date.transform(
          this.d.controls[0].get("dTimeTill").value,
          "HH:mm"
        );
      } else {
        saveObj.starttime = this._date.transform(
          this.d.controls[0].get("dTimeFrom").value,
          "HH:mm"
        );
        saveObj.duetime = this._date.transform(
          this.d.controls[0].get("dTimeTill").value,
          "HH:mm"
        );
      }

      saveObj.timerangeselected = this.d.controls[0].get("dTimeRange").value;
      if (saveObj.timerangeselected == true) {
        saveObj.everyinputduration = this.d.controls[0].get("dEvery1").value;
      }
    }
    if (this.f.dateSelectionType.value == "WEEKLY") {
      (saveObj.frequencytype = this.f.dateSelectionType.value),
        (saveObj.weekdays = this.filteredWeeks);
      saveObj.startdate = this._date.transform(
        this.d.controls[0].get("wStartDate").value,
        "yyyy-MM-dd"
      );
      saveObj.duedate = this._date.transform(
        this.d.controls[0].get("wDueDate").value,
        "yyyy-MM-dd"
      );
      if (this.type == "CREATE") {
        saveObj.starttime = this._date.transform(
          this.d.controls[0].get("wTimeFrom").value,
          "HH:mm"
        );
        saveObj.duetime = this._date.transform(
          this.d.controls[0].get("wTimeTill").value,
          "HH:mm"
        );
      } else {
        saveObj.starttime = this._date.transform(
          this.d.controls[0].get("wTimeFrom").value,
          "HH:mm"
        );
        saveObj.duetime = this._date.transform(
          this.d.controls[0].get("wTimeTill").value,
          "HH:mm"
        );
      }
      saveObj.timerangeselected = this.d.controls[0].get("wTimeRange").value;
      if (saveObj.timerangeselected == true) {
        saveObj.everyinputduration = this.d.controls[0].get("wEvery").value;
      }
    }
    if (this.f.dateSelectionType.value == "MONTHLY") {
      (saveObj.frequencytype = this.f.dateSelectionType.value),
        (saveObj.dayof = this.d.controls[0].get("mDay").value);
      saveObj.monthof = this.d.controls[0].get("mMonth").value;
      saveObj.startdate = this._date.transform(
        this.d.controls[0].get("mStartDate").value,
        "yyyy-MM-dd"
      );
      saveObj.duedate = this._date.transform(
        this.d.controls[0].get("mDueDate").value,
        "yyyy-MM-dd"
      );
      if (this.type == "CREATE") {
        saveObj.starttime = this._date.transform(
          this.d.controls[0].get("mTimeFrom").value,
          "HH:mm"
        );
        saveObj.duetime = this._date.transform(
          this.d.controls[0].get("mTimeTill").value,
          "HH:mm"
        );
      } else if (this.type == "EDIT") {
        saveObj.starttime = this._date.transform(
          this.d.controls[0].get("mTimeFrom").value,
          "HH:mm"
        );
        saveObj.duetime = this._date.transform(
          this.d.controls[0].get("mTimeTill").value,
          "HH:mm"
        );
      } else {
        saveObj.starttime = this._date.transform(
          this.d.controls[0].get("mTimeFrom").value,
          "HH:mm"
        );
        saveObj.duetime = this._date.transform(
          this.d.controls[0].get("mTimeTill").value,
          "HH:mm"
        );
      }
      saveObj.timerangeselected = this.d.controls[0].get("mTimeRange").value;
      if (saveObj.timerangeselected == true) {
        saveObj.everyinputduration = this.d.controls[0].get("mEvery").value;
      }
    }
    if (this.f.dateSelectionType.value == "YEARLY") {
      (saveObj.frequencytype = this.f.dateSelectionType.value),
        (saveObj.startdate = this._date.transform(
          this.d.controls[0].get("yStartDate").value,
          "yyyy-MM-dd"
        ));
      saveObj.duedate = this._date.transform(
        this.d.controls[0].get("yDueDate").value,
        "yyyy-MM-dd"
      );
      if (this.type == "CREATE") {
        saveObj.starttime = this._date.transform(
          this.d.controls[0].get("yTimeFrom").value,
          "HH:mm"
        );
        saveObj.duetime = this._date.transform(
          this.d.controls[0].get("yTimeTill").value,
          "HH:mm"
        );
      } else {
        saveObj.starttime = this._date.transform(
          this.d.controls[0].get("yTimeFrom").value,
          "HH:mm"
        );
        saveObj.duetime = this._date.transform(
          this.d.controls[0].get("yTimeTill").value,
          "HH:mm"
        );
      }
      saveObj.timerangeselected = this.d.controls[0].get("yTimeRange").value;
      if (saveObj.timerangeselected == true) {
        saveObj.everyinputduration = this.d.controls[0].get("yEvery").value;
      }
      saveObj.yearly = this.filteredMonths;
    }
    saveObj.isnotifyoncompletion = this.onCompletetion;
    saveObj.userid = this.onCompletetion == true ? this.selectedUser : [];
    saveObj.isnotifyonoverdue = this.whenOverDue;
    saveObj.roleid = this.whenOverDue == true ? this.rolesSelected : [];
    saveObj.reminderbeforedue = this.selectedReminders;
    saveObj.isactive = true;
    if (this.type != "EDIT") {
      this.createTaskDefinition(saveObj);
    } else {
      this.updateTaskDefinition(saveObj);
    }
  }
  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map((control) => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => (next ? prev + next : prev), 0);

      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }
  createweekscheckBoxArray(chkboxInputs) {
    let weeksChkboxArray = this.d.controls[0].get("weeksChkbox") as FormArray;
    const arr = chkboxInputs.forEach((input) => {
      weeksChkboxArray.push(new FormControl(input.checked || false));
    });
  }
  createmonthlycheckBoxArray(chkboxInputs) {
    let monthsChkboxArray = this.d.controls[0].get("monthsChkbox") as FormArray;
    const arr = chkboxInputs.forEach((input) => {
      monthsChkboxArray.push(new FormControl(input.checked || false));
    });
  }
  getSelectedWeeks() {
    this.selectedWeeks = this.d.controls[0]
      .get("weeksChkbox")
      ["controls"].map((week, i) => {
        return week.value && this.weeks[i].name;
      });
    this.filteredWeeks = this.selectedWeeks.filter(Boolean);
  }
  getSelectedMonths() {
    this.selectedMonths = this.d.controls[0]
      .get("monthsChkbox")
      ["controls"].map((month, i) => {
        return month.value && this.months[i].name;
      });
    this.filteredMonths = this.selectedMonths.filter(Boolean);
  }

  updateTaskDefinition(inputdata: any) {
    this.apiService
      .post(RestApi.TaskManagementURL + "saveTaskDefinition", inputdata)
      .subscribe(
        (response) => {
          if (response.taskdefinitionid) {
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: this.translate.data.Popuop_Msg.taskUpdated,
            });
            this.loaddata.emit(true);
            this.step1Form.reset();
            this.step2Form.reset();
            this.sites = [];
            this.selectedUser = [];
            this.rolesSelected = [];
            this.tasksiteareaID = [];
            this.whenOverDue = false;
            this.onCompletetion = false;
            this.submittedStep3 = false;
            this.selectedReminders = null;
            this.selectedNotificationType = "option1";
            this.goToStep(0);
          } else {
          }
        },
        (error) => {
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary:
              error.error.showError == true ? error.error.message : error.error,
          });
        }
      );
  }
  createTaskDefinition(inputdata: any) {
    this.apiService
      .post(RestApi.TaskManagementURL + "saveTaskDefinition", inputdata)
      .subscribe(
        (response) => {
          if (response.taskdefinitionid) {
            this.loaddata.emit(true);
            this.messageService.add({
              severity: ToastMsg.severity.success,
              summary: this.translate.data.Popuop_Msg.taskConfirmation,
            });
            this.step1Form.reset();
            this.step2Form.reset();
            this.sites = [];
            this.submittedStep3 = false;
            this.selectedUser = [];
            this.rolesSelected = [];
            this.tasksiteareaID = [];
            this.whenOverDue = false;
            this.onCompletetion = false;
            this.selectedReminders = null;
            this.selectedNotificationType = "option1";
            this.goToStep(0);
          }
        },
        (error) => {
          this.loaddata.emit(false);
          this.messageService.add({
            severity: ToastMsg.severity.error,
            summary:
              error.error.showError == true
                ? error.error.message
                : error.error.error,
          });
        }
      );
  }
  goToStep(s: number) {
    this.step = s;
  }
  submitSecondStep() {
    this.submittedStep2 = true;
    this.validateAllFormFields(this.step2Form);
    if (this.step2Form.valid) this.goToStep(2);
  }
  validateAllFormFields(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.validateAllFormFields(control);
      }
    });
  }

  // To get seleted region info
  selectedRegionInfo() {
    this.regionsListInfo = [];
    this.step1Form.controls["region"].value.forEach((element) => {
      let areaInfo = new areas();
      areaInfo.areaId = element;
      this.regions.forEach((element) => {
        if (element.code == areaInfo.areaId) {
          areaInfo.areaName = element.name;
        }
      });
      this.regionsListInfo.push(areaInfo);
    });
  }

  selectedSiteInfo() {
    this.siteListInfo = [];
    this.step1Form.controls["site"].value.forEach((element) => {
      let siteInfo = new sites();
      siteInfo.siteId = element;

      this.sites.forEach((element) => {
        if (element.code == siteInfo.siteId) {
          siteInfo.sitename = element.name;
        }
      });
      this.siteListInfo.push(siteInfo);
    });
  }

  getSiteData() {
    this.apiService
      .get<any>(RestApi.client_site_list + "/" + this.clientID)
      .subscribe((res: ApiListResponse<AddSite>) => {
        let SiteIDs: number[] = [];
        if (res.IsSuccess) {
          let siteListing = res.Data || [];
          if (this.userProfile.roleName == Constant.Roles.siteManager) {
            this.siteList = siteListing.filter((ele) => {
              return ele.SiteID == this.userProfile.siteID;
            });
          }
        }
        if (this.siteList)
          this.siteList.map((x) => {
            if (x.SiteID == this.userProfile.siteID) {
              this.siteName = x.SiteName;
            }
          });
      });
  }
  getAreas() {
    this.apiService
      .get(RestApi.area_list + "/" + this.clientID)
      .subscribe((res: any) => {
        this.areaList = res.Data;
        this.areaList.forEach((element) => {
          if (element.AreaID == this.roleAreaID) {
            this.areaName = element.AreaName;
          }
        });
      });
  }
}

export class StepperSave {
  taskdefinitionid: number;
  taskname: string;
  taskdescription: string;
  createdon: Date;
  createdby: string;
  modifiedon: Date;
  modifiedby: string;
  createdbyid: any;
  modifiedbyid: any;
  clientid: number;
  taskpriorityid: number;
  moduleid: string;
  logo: string;
  modulename: string;
  isactive: boolean;
  assigntorole: string;
  archivetasks: boolean;
  SiteModuleId: string;
  isnotifyoncompletion: boolean;
  userid: any = [];
  isnotifyonoverdue: boolean;
  roleid: any = [];
  reminderbeforedue: string;
  frequencytype: string;
  startdate: any;
  duedate: any;
  dayof: number;
  monthof: number;
  weekdays: any;
  yearly: string;
  EveryNoOfDays: number;
  timerangeselected: boolean;
  starttime: any;
  duetime: any;
  everyinputduration: number;
  frequencyinterval: number;
  areas: areas[];
  sites: sites[];
}

export class areas {
  areaId: number;
  areaName: string;
}

export class sites {
  siteId: number;
  sitename: string;
}
