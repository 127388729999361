<div class="box mtop-32">
  <div>
    <h6 class="commissioning-header col-sm-12 text-center">
      {{ "sensorDetails" | translate: "Commissioning" }}
    </h6>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div>
        <div class="col-md-12">
          <div class="value-header">
            {{ "locationName" | translate: "Notification" }}
            <span class="mandatory" *ngIf="!replaceSensorKey">*</span>
          </div>
          <div>
            <div class="cabinet-menu">
              <p-dropdown
                class="dropdown"
                name="reasons"
                [options]="this.sensorDetails.cabinets"
                [(ngModel)]="selectedCabinet"
                optionLabel="CabinetName"
                [placeholder]="
                  replaceSensorKey
                    ? cabinetName
                    : ('selectLocation' | translate: 'Commissioning')
                "
                [editable]="true"
                [disabled]="replaceSensorKey"
              ></p-dropdown>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="value">
        {{ this.sensorDetails.cabinets.length > 0 ? this.sensorDetails.cabinets.CabinetName" : "notAvailable" | translate: "Commissioning" }}
      </div> -->
      <div class="col-md-12">
        <div class="value-header">
          {{ "sensorId" | translate: "Commissioning" }}
        </div>
        <div class="value">
          {{
            this.sensorDetails.sensorDeviceModel.SensorKey
              ? this.sensorDetails.sensorDeviceModel.SensorKey
              : ("notAvailable" | translate: "Commissioning")
          }}
        </div>
      </div>
      <div class="col-md-12">
        <div class="value-header">
          {{ "template" | translate: "Commissioning" }}
          <span class="mandatory" *ngIf="!replaceSensorKey">*</span>
        </div>
        <div class="cabinet-menu">
          <p-dropdown
            class="dropdown"
            name="template"
            [options]="this.sensorDetails.sensorTemplates"
            [(ngModel)]="selectedTemplate"
            optionLabel="Name"
            [placeholder]="
              replaceSensorKey
                ? sensorTemplateName
                : ('selectTemplate' | translate: 'Commissioning')
            "
            [disabled]="replaceSensorKey"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-6">
          <div class="value-header">
            {{ "batteryLevel" | translate: "Refrigeration" }}
          </div>
          <div class="value text-css">
            <div
              class="battery"
              [ngClass]="{
                'green-bg':
                  getBatteryStatusClass(
                    this.sensorDetails.sensorDeviceModel.BatteryLevel
                  ) === 'battery_full',
                'orange-bg':
                  getBatteryStatusClass(
                    this.sensorDetails.sensorDeviceModel.BatteryLevel
                  ) === 'battery_medium',
                'red-bg':
                  getBatteryStatusClass(
                    this.sensorDetails.sensorDeviceModel.BatteryLevel
                  ) === 'battery_low',
                'not-available-text':
                  getBatteryStatusClass(
                    this.sensorDetails.sensorDeviceModel.BatteryLevel
                  ) === ''
              }"
            >
              <span
                class="flex"
                [ngClass]="{
                  'green-text':
                    getBatteryStatusClass(
                      this.sensorDetails.sensorDeviceModel.BatteryLevel
                    ) === 'battery_full',
                  'orange-text':
                    getBatteryStatusClass(
                      this.sensorDetails.sensorDeviceModel.BatteryLevel
                    ) === 'battery_medium',
                  'red-text':
                    getBatteryStatusClass(
                      this.sensorDetails.sensorDeviceModel.BatteryLevel
                    ) === 'battery_low',
                  'not-available-text':
                    getBatteryStatusClass(
                      this.sensorDetails.sensorDeviceModel.BatteryLevel
                    ) === ''
                }"
              >
                <svg
                  *ngIf="this.sensorDetails.sensorDeviceModel.BatteryLevel"
                  style="height: 25px; width: 25px"
                >
                  <use
                    [attr.xlink:href]="
                      '../../../assets/icons-svg/sprite-svg-icons.svg#' +
                      getBatteryStatusClass(
                        this.sensorDetails.sensorDeviceModel.BatteryLevel
                      )
                    "
                  ></use>
                </svg>
                <span
                  *ngIf="this.sensorDetails.sensorDeviceModel.BatteryLevel"
                  class="spacing_batterySingnalInfo"
                >
                  {{ this.sensorDetails.sensorDeviceModel.BatteryLevel + " %" }}
                </span>
                <span
                  *ngIf="!this.sensorDetails.sensorDeviceModel.BatteryLevel"
                >
                  {{ "notAvailable" | translate: "Commissioning" }}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="value-header">
            {{ "signalStrength" | translate: "Commissioning" }}
          </div>
          <div class="value text-css">
            <div
              class="signal"
              [ngClass]="{
                'green-bg':
                  getColorClass(
                    this.sensorDetails.sensorDeviceModel.SignalStrength
                  ) === 'green_signal',
                'orange-bg':
                  getColorClass(
                    this.sensorDetails.sensorDeviceModel.SignalStrength
                  ) === 'orange_signal',
                'red-bg':
                  getColorClass(
                    this.sensorDetails.sensorDeviceModel.SignalStrength
                  ) === 'red_signal',
                'not-available-text':
                  getColorClass(
                    this.sensorDetails.sensorDeviceModel.SignalStrength
                  ) === ''
              }"
            >
              <span
                class="sensorcard-grid_info flex"
                [ngClass]="{
                  'green-text':
                    getColorClass(
                      this.sensorDetails.sensorDeviceModel.SignalStrength
                    ) === 'green_signal',
                  'orange-text':
                    getColorClass(
                      this.sensorDetails.sensorDeviceModel.SignalStrength
                    ) === 'orange_signal',
                  'red-text':
                    getColorClass(
                      this.sensorDetails.sensorDeviceModel.SignalStrength
                    ) === 'red_signal',
                  'not-available-text':
                    getColorClass(
                      this.sensorDetails.sensorDeviceModel.SignalStrength
                    ) === ''
                }"
              >
                <svg
                  *ngIf="this.sensorDetails.sensorDeviceModel.SignalStrength"
                  style="height: 25px; width: 25px"
                  [ngClass]="
                    getColorClass(
                      this.sensorDetails.sensorDeviceModel.SignalStrength
                    )
                  "
                >
                  <use
                    [attr.xlink:href]="
                      '../../../assets/icons-svg/sprite-svg-icons.svg#' +
                      getColorClass(
                        this.sensorDetails.sensorDeviceModel.SignalStrength
                      )
                    "
                  ></use>
                </svg>
                <span
                  *ngIf="this.sensorDetails.sensorDeviceModel.SignalStrength"
                  class="spacing_batterySingnalInfo"
                >
                  {{
                    this.sensorDetails.sensorDeviceModel.SignalStrength + " dBm"
                  }}
                </span>
                <span
                  *ngIf="!this.sensorDetails.sensorDeviceModel.SignalStrength"
                >
                  {{ "notAvailable" | translate: "Commissioning" }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row"
        *ngIf="
          selectedSensorType === sensorType.Temperature ||
          selectedSensorType === sensorType.TemperatureHumidity ||
          selectedSensorType === sensorType.CO2
        "
      >
        <div class="col-md-6">
          <div class="value-header">
            {{ "airTemperature" | translate: "Refrigeration" }}
          </div>
          <div class="value text-css">
            <span
              class="green-text spacing-temperatureInfo"
              *ngIf="
                this.sensorDetails.sensorDeviceModel.Temperature ||
                this.sensorDetails.sensorDeviceModel.Temperature === 0
              "
            >
              {{
                convertTemperature(
                  this.sensorDetails.sensorDeviceModel.Temperature
                ) +
                  " °" +
                  (this.TemperatureType === "F" ? "F" : "C")
              }}
            </span>
            <span
              class="not-available-text"
              *ngIf="
                !this.sensorDetails.sensorDeviceModel.Temperature &&
                this.sensorDetails.sensorDeviceModel.Temperature !== 0
              "
            >
              {{ "notAvailable" | translate: "Commissioning" }}
            </span>
          </div>
        </div>
        <div
          *ngIf="
            selectedSensorType === sensorType.TemperatureHumidity ||
            selectedSensorType === sensorType.CO2
          "
          class="col-md-6"
        >
          <div>
            <div class="value-header">
              {{ "humidity" | translate: "Refrigeration" }}
            </div>
            <div>
              <span
                *ngIf="
                  this.sensorDetails.sensorDeviceModel.Humidity ||
                  this.sensorDetails.sensorDeviceModel.Humidity === 0
                "
                class="value text-css spacing-temperatureInfo green-text spacing-temperatureInfo"
              >
                {{ this.sensorDetails.sensorDeviceModel.Humidity + " % RH" }}
              </span>
              <span
                *ngIf="
                  !this.sensorDetails.sensorDeviceModel.Humidity &&
                  this.sensorDetails.sensorDeviceModel.Humidity !== 0
                "
                class="not-available"
              >
                {{ "notAvailable" | translate: "Commissioning" }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="selectedSensorType === sensorType.CO2">
        <div class="col-md-6">
          <div class="value-header">CO2</div>
          <div class="value text-css green-text spacing-temperatureInfo">
            <span
              class="green-text"
              *ngIf="
                this.sensorDetails.sensorDeviceModel.CO2 ||
                this.sensorDetails.sensorDeviceModel.CO2 === 0
              "
            >
              {{ this.sensorDetails.sensorDeviceModel.CO2 }} ppm
            </span>
            <span
              class="not-available-text"
              *ngIf="
                !this.sensorDetails.sensorDeviceModel.CO2 &&
                this.sensorDetails.sensorDeviceModel.CO2 !== 0
              "
            >
              {{ "notAvailable" | translate: "Commissioning" }}
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div>
            <div class="value-header">
              {{ "Pressure" | translate: "Refrigeration" }}
            </div>
            <div>
              <span
                *ngIf="
                  this.sensorDetails.sensorDeviceModel.Pressure ||
                  this.sensorDetails.sensorDeviceModel.Pressure === 0
                "
                class="value text-css spacing-temperatureInfo green-text"
              >
                {{ this.sensorDetails.sensorDeviceModel.Pressure + " hPa" }}
              </span>
              <span
                *ngIf="
                  !this.sensorDetails.sensorDeviceModel.Pressure &&
                  this.sensorDetails.sensorDeviceModel.Pressure !== 0
                "
                class="not-available"
              >
                {{ "notAvailable" | translate: "Commissioning" }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="selectedSensorType === sensorType.Door">
        <div class="col-md-12">
          <div>
            <div class="value-header">
              {{ "door" | translate: "Commissioning" }}
            </div>
            <div class="value text-css">
              <span
                [ngClass]="{
                  'red-text': this.sensorDetails.sensorDeviceModel.Door === 1,
                  'green-text': this.sensorDetails.sensorDeviceModel.Door === 0
                }"
              >
                {{
                  this.sensorDetails.sensorDeviceModel.Door === 1
                    ? ("open" | translate: "Refrigeration")
                    : ("closed" | translate: "Commissioning")
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="selectedSensorType === sensorType.Water">
        <div class="col-md-12">
          <div>
            <div class="value-header">
              {{ "water" | translate: "Commissioning" }}
            </div>
            <div class="value text-css">
              <span
                [ngClass]="{
                  'red-text':
                    this.sensorDetails.sensorDeviceModel.WaterDetection === 1,
                  'green-text':
                    this.sensorDetails.sensorDeviceModel.WaterDetection === 0
                }"
              >
                {{
                  this.sensorDetails.sensorDeviceModel.WaterDetection === 1
                    ? ("detected" | translate: "Commissioning")
                    : ("notdetected" | translate: "Commissioning")
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-12 text-center mtop-32">
      <button
        *ngIf="!replaceSensorKey"
        type="button"
        class="btn btn-primary"
        (click)="navigateToSensorGatewayList()"
        [disabled]="validateOk()"
      >
        {{ "ok" | translate: "Common" }}
      </button>
      <button
        *ngIf="replaceSensorKey"
        type="button"
        class="btn btn-primary"
        (click)="replaceSensor()"
      >
        {{ "replace" | translate: "Commissioning" }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        routerLink="/commissioning/listsensorgateway"
      >
        {{ "cancel" | translate: "Common" }}
      </button>
    </div>
  </div>
</div>
<p-toast position="bottom-left"></p-toast>
